import React from 'react'
import { createRoot } from 'react-dom/client'
import includes from 'lodash/includes'
import dayjs from 'dayjs'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import App from 'components/App'

import httpClient from 'httpClient'
import applyDefaultInterceptors from 'httpClient/applyDefaultInterceptors'

import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from 'lib/const'

import reportWebVitals from './reportWebVitals'

const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx')

applyDefaultInterceptors(httpClient)

const usersLocale = navigator.language.split('-')[0]
const supportedUserLocale = includes(SUPPORTED_LANGUAGES, usersLocale)
const locale = supportedUserLocale ? usersLocale : DEFAULT_LANGUAGE
dayjs.locale(locale)

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
