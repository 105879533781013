import { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import sanitize from 'sanitize-filename'

import axios from 'axios'

import { apiErrorMessage } from 'lib/utils'

import Heading from 'components/common/Heading'

import SuccessMessage from './SuccessMessage'
import CertificateUpload from './CertificateUpload'

const Step3 = () => {
  const location = useLocation()
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { token } = queryString.parse(location.search)

  const handleCertificateUpload = useCallback(async (file) => {
    const fileName = sanitize(file.name)

    setApiError(null)
    try {
      setLoading(true)
      const {
        data: { user },
      } = await axios.get(`${process.env.REACT_APP_API_URL}profile`, {
        headers: { token },
      })
      const guid = user?.data?.attributes?.guid
      const {
        data: { signedUrl },
      } = await axios.post(
        `${process.env.REACT_APP_API_URL}lab_orders/${guid}/create_presigned_url`,
        { guid, file_name: fileName },
        { headers: { token } },
      )

      await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      await axios.patch(
        `${process.env.REACT_APP_API_URL}lab_orders/${guid}/photograph_uploaded`,
        { guid, file_name: fileName },
        { headers: { token } },
      )

      await axios.patch(
        `${process.env.REACT_APP_API_URL}update_new_user`,
        { tn_business: true },
        { headers: { token } },
      )
      setSuccess(true)
    } catch (error) {
      setApiError(apiErrorMessage(error))
      setLoading(false)
    }
  }, [])

  const handleNoTennesseeUser = useCallback(() => setSuccess(true))

  return (
    <div className="div-full-screen white-smoke">
      <div className="div-block-181">
        <Heading title="Welcome to" withBrandName />
        {success ? (
          <SuccessMessage />
        ) : (
          <CertificateUpload
            onCertificateUpload={handleCertificateUpload}
            onNoTennesseeUser={handleNoTennesseeUser}
            loading={loading}
            success={success}
            apiError={apiError}
          />
        )}
      </div>
    </div>
  )
}

export default Step3
