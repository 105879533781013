import React, { useState } from 'react'
import { ReactComponent as SortIcon } from 'images/icons/order.svg'
import useSort from '../../hooks/useSort'
import styles from './index.module.css'

const SortImages = ({ handleImage, images }) => {
  console.log(images)
  const [showOrder, setShowOrder] = useState(false)
  const { sortAscendingByName, sortDescendingByName, sortNewestToOldest, sortOldestToNewest } =
    useSort()
  const [order, setOrder] = useState('')

  const handleSortAscending = () => {
    const newImages = [...images]
    const newPhotos = sortAscendingByName(newImages)
    setOrder('asc')
    handleImage(newPhotos)
    setShowOrder(false)
  }

  const handleSortDescending = () => {
    const newImages = [...images]
    const newPhotos = sortDescendingByName(newImages)
    setOrder('desc')
    handleImage(newPhotos)
    setShowOrder(false)
  }

  const handleSortNewestOldest = () => {
    const newImages = [...images]
    const newPhotos = sortNewestToOldest(newImages)
    setOrder('nto')
    handleImage(newPhotos)
    setShowOrder(false)
  }

  const handleSortOldestNewest = () => {
    const newImages = [...images]
    const newPhotos = sortOldestToNewest(newImages)
    setOrder('otn')
    handleImage(newPhotos)
    setShowOrder(false)
  }

  return (
    <div data-hover="" data-delay="0" className={`${styles.dropdown}`}>
      <div
        className="div-block-169-copy w-dropdown-toggle"
        onClick={() => setShowOrder(!showOrder)}
        role="button"
      >
        <div className="icon small w-embed">
          <SortIcon />
        </div>
      </div>
      {showOrder && (
        <nav className={styles['dropdown-list']}>
          <div className="form-block-filter w-form">
            <form id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-3">
              <label className="radio-button-field w-radio">
                <div
                  className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input ${
                    order === 'asc' && 'w--redirected-checked'
                  }`}
                />
                <input
                  type="radio"
                  data-name="Radio"
                  id="radio"
                  name="radio"
                  value="Radio1"
                  onClick={handleSortAscending}
                />
                <span className="w-form-label">Ascending (A-Z)</span>
              </label>
              <label className="radio-button-field w-radio">
                <div
                  className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input ${
                    order === 'desc' && 'w--redirected-checked'
                  }`}
                />
                <input
                  type="radio"
                  data-name="Radio 2"
                  id="radio-2"
                  name="radio"
                  value="Radio2"
                  onClick={handleSortDescending}
                />
                <span className="w-form-label">Descending (Z-A)</span>
              </label>
              <label className="radio-button-field w-radio">
                <div
                  className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input ${
                    order === 'nto' && 'w--redirected-checked'
                  }`}
                />
                <input
                  type="radio"
                  data-name="Radio 3"
                  id="radio-3"
                  name="radio"
                  value="Radio3"
                  onClick={handleSortNewestOldest}
                />
                <span className="w-form-label">Newest to Oldest</span>
              </label>
              <label className="radio-button-field w-radio">
                <div
                  className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input ${
                    order === 'otn' && 'w--redirected-checked'
                  }`}
                />
                <input
                  type="radio"
                  data-name="Radio 4"
                  id="radio-4"
                  name="radio"
                  value="Radio4"
                  onClick={handleSortOldestNewest}
                />
                <span className="w-form-label">Oldest to Newest</span>
              </label>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </nav>
      )}
    </div>
  )
}

export default SortImages
