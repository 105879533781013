import { useHover } from 'hooks'

import { ReactComponent as DeleteIcon } from 'images/icons/delete.svg'
import { ReactComponent as AttachmentIcon } from 'images/icons/attachment.svg'
import { DEFAULT_ANIMATION } from 'lib/const'

const ImagesPreview = ({ image }) => {
  const [hoverRef, isHovered] = useHover()
  const { preview, path } = image

  return (
    <div
      data-w-id="ce6ff094-8035-f4fa-0c72-26e08c9f8e04"
      className="picture-element-print-order"
      ref={hoverRef}
    >
      <img src={preview} loading="lazy" alt={`Uploaded ${path}`} className="img-print-order" />
      <div className="info-container-print-picture">
        <div className="info-print-order-product-container">
          <div className="icon small right-margin x2 w-embed" style={{ flexShrink: 0 }}>
            <AttachmentIcon />
          </div>
          <div style={{ lineBreak: 'anywhere', textAlign: 'center' }}>{path}</div>
        </div>
      </div>
      <div className="w-layout-grid grid-button-picture-print-order">
        <div
          className="print-delete-button"
          style={{ opacity: isHovered ? 1 : 0, transition: DEFAULT_ANIMATION }}
        >
          <div
            role="button"
            onKeyPress={() => {}}
            onClick={() => {}}
            className="icon small w-embed"
          >
            <DeleteIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImagesPreview
