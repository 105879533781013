import { useCallback, useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import cn from 'classnames'
import values from 'lodash/values'

import httpClient from 'httpClient'
import { apiErrorMessage } from 'lib/utils'
import { ReactComponent as ErrorIcon } from 'images/icons/error.svg'
import { ReactComponent as CheckIcon } from 'images/icons/check-rounded.svg'

import ErrorMessage from 'components/common/ErrorMessage'

const ReferredByForm = ({ referralCode, refetchUser }) => {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm()
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [validCode, setValidCode] = useState(false)
  const newCode = watch('code')

  useEffect(() => {
    if (!newCode) return
    ;(async () => {
      try {
        await httpClient.post('check_referral_code', { code: newCode })
        setValidCode(true)
      } catch (error) {
        setValidCode(false)
      }
    })()
  }, [newCode])

  const handleUpdate = useCallback(async ({ code }) => {
    try {
      setLoading(true)
      await httpClient.patch('update_new_user', { code })
      await refetchUser()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setApiError(apiErrorMessage(error))
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(handleUpdate)} className="w-layout-grid grid-form auto _1fr-right">
      <div
        id="w-node-_3eb8618e-5b71-88ed-5312-95c092571618-04e80a14"
        className="input-container paper-type"
      >
        <div className="label-container">
          <div className="label-subcontainer">
            <div className="sub-label-form">Were you reffered by another photographer?</div>
          </div>
        </div>
        <div className="form-block-input w-form">
          <div
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="form-input select-field"
          >
            <div className="select-field-wrapper">
              <Controller
                name="code"
                defaultValue={referralCode ?? ''}
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      {...field}
                      type="text"
                      className="text-field-2 font-size-14 active w-input"
                      maxLength="256"
                      name="field-4"
                      data-name="Field 4"
                      placeholder={referralCode ?? 'Enter Refferal Code'}
                      id="data-jscolor"
                    />
                    {newCode && (
                      <div className="eye-2" style={{ opacity: 1, height: '40px' }}>
                        <div className="icon w-embed">
                          {validCode ? (
                            <CheckIcon color="#73d13d" />
                          ) : (
                            <ErrorIcon color="#ff4d4f" />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        id="w-node-_3eb8618e-5b71-88ed-5312-95c092571627-04e80a14"
        disabled={isSubmitting || loading || !validCode}
        className={cn('button-2 secondary w-inline-block', {
          disabled: !validCode || loading || isSubmitting,
        })}
      >
        <div>Submit</div>
      </button>
      {(values(errors)[0] || apiError) && (
        <div className="form-item-2">
          <ErrorMessage errors={values(errors)?.[0]?.message || apiError} />
        </div>
      )}
    </form>
  )
}

export default ReferredByForm
