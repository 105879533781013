import { useState, useCallback } from 'react'
import { ReactComponent as LockIcon } from 'images/icons/lock.svg'
import { ReactComponent as UpdateIcon } from 'images/icons/update.svg'

import PhotographerPasswordCopy from './PhotographerPasswordCopy'
import PhotographerResetPassword from './PhotographerResetPassword'

const PhotographerManagePassword = () => {
  const [showResetPassword, setShowResetPassword] = useState(false)

  const handleToogleResetPassword = useCallback(() => setShowResetPassword((prev) => !prev), [])

  return (
    <div className="client-account-block2">
      <div className="accordion-item-block no-margin">
        <div className="sub-heading-container both-edges">
          <div className="div-block-278">
            <div className="icon right-margin w-embed">
              <LockIcon />
            </div>
            <h2 className="heading-title-2">Manage Password</h2>
          </div>
        </div>
      </div>

      <div className="w-layout-grid grid-form horizontal">
        <PhotographerPasswordCopy />
        <div
          id="w-node-_917112c9-ea04-d2b9-17a9-ea814d498553-41e80a1e"
          className="button-form-container"
        >
          <button
            type="button"
            data-w-id="917112c9-ea04-d2b9-17a9-ea814d498554"
            className="button-2 secondary w-inline-block"
            onClick={handleToogleResetPassword}
            onKeyPress={handleToogleResetPassword}
          >
            <div className="icon small right-margin w-embed">
              <UpdateIcon />
            </div>
            <div>Reset Password</div>
          </button>
        </div>
      </div>
      {showResetPassword && <PhotographerResetPassword />}
    </div>
  )
}

export default PhotographerManagePassword
