/* eslint-disable no-param-reassign */
import humps from 'humps'
import { TOKEN } from 'lib/const'
import { getAuthToken } from 'lib/utils'

export default (client) => {
  client.interceptors.request.use((config) => {
    const { data, headers } = config
    config.headers = {
      ...headers,
      [TOKEN]: getAuthToken(),
    }
    config.data = humps.decamelizeKeys(data) ?? {}
    return config
  })

  client.interceptors.response.use(
    async (response) => {
      const { data } = response
      response.data = humps.camelizeKeys(data)
      return response.data
    },
    (error) => Promise.reject(error),
  )
}
