import { useState, useEffect } from 'react'
import axios from 'axios'
import sanitize from 'sanitize-filename'
import httpClient from 'httpClient'

import ImagesUpload from 'components/PrintOrder/PrintOrderCreate/ImagesUpload'
import NoImages from 'components/PrintOrder/PrintOrderCreate/NoImages'
import Loading from 'components/common/Loading'
import ImagePreview from 'components/PrintOrder/PrintOrderCreate/ImagePreview'
import OrderInit from 'components/PrintOrder/PrintOrderCreate/OrderInit'
import SelectProducts from 'components/PrintOrder/PrintOrderCreate/SelectProducts'
import { apiErrorMessage } from 'lib/utils'

const PrintOrderCreate = () => {
  const [currentOrder, setCurrentOrder] = useState(null)
  const [uploadsPreview, setUploadPreview] = useState([])
  const [loading, setLoading] = useState(false)

  const [, setApiError] = useState(false)

  const handleCreateOrder = async (orderName) => {
    try {
      setLoading('Creating order')
      setApiError(null)
      const order = await httpClient.post('lab_orders', {
        orderName,
      })
      setCurrentOrder(order)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setApiError(apiErrorMessage(err))
    }
  }

  const handleUploadPhoto = async (file) => {
    const photoFileName = sanitize(file.name)
    const labOrderGuid = currentOrder.data.attributes.id

    try {
      setLoading('Uploading')
      setApiError(null)
      const { signedUrl } = await httpClient.post(
        `lab_orders/${currentOrder.data.attributes.id}/create_presigned_url`,
        {
          labOrderGuid,
          photoFileName,
        },
      )

      await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      const { status, id } = await httpClient.patch(
        `lab_orders/${currentOrder.data.attributes.id}/photograph_uploaded`,
        {
          labOrderGuid,
          photoFileName,
        },
      )
      if (status === 'ok') {
        setUploadPreview((prev) => [
          {
            ...file,
            id,
            preview: URL.createObjectURL(file),
          },
          ...prev,
        ])
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setApiError(apiErrorMessage(err))
    }
  }

  // TODO: Error notification
  // TODO: Success notification

  useEffect(
    () => () => {
      uploadsPreview.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [],
  )

  return (
    <div className="sub-grid-container-print-order">
      <div className="print-order-sidebar-container">
        <div className="print-order-sidebar grid absolute">
          <div className="accordion-item-block">
            <OrderInit onCreate={handleCreateOrder} order={currentOrder} loading={loading} />
            {currentOrder && (
              <>
                <div className="line-horizontal" />
                <ImagesUpload onUpload={handleUploadPhoto} />
                {!!uploadsPreview.length && <SelectProducts id={currentOrder.data.id} />}
              </>
            )}
          </div>
        </div>
      </div>
      {loading && <Loading message={loading} />}
      {!loading && uploadsPreview.length ? (
        <div className="img-container-print-order">
          <div className="w-layout-grid grid-images-print-order">
            {uploadsPreview.map((preview, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ImagePreview key={`preview-${index}`} image={preview} />
            ))}
          </div>
        </div>
      ) : (
        <NoImages />
      )}
    </div>
  )
}

export default PrintOrderCreate
