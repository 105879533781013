import PhotographerOrdersSummaryHeader from './PhotographerOrdersSummaryHeader'
import PhotographerOrdersSummaryTable from './PhotographerOrdersSummaryTable'

const PhotographerOrdersSummary = () => (
  <div className="accordion-item-block no-margin">
    <PhotographerOrdersSummaryHeader />
    <div>
      <PhotographerOrdersSummaryTable />
    </div>
  </div>
)

export default PhotographerOrdersSummary
