import PhotographersDatabaseRow from './PhotographersDatabaseRow'

const PhotographersDatabaseTable = ({ users, searchTerm }) => {
  let result = users

  if (searchTerm) {
    result = users?.filter(
      (user) =>
        user.attributes.guid.includes(searchTerm) ||
        user.attributes.firstName?.includes(searchTerm) ||
        user.attributes.lastName?.includes(searchTerm) ||
        user.attributes.businessName?.includes(searchTerm) ||
        user.attributes.businessUrl?.includes(searchTerm) ||
        user.attributes.email?.includes(searchTerm) ||
        user.attributes.phone?.includes(searchTerm) ||
        user.attributes.referralCode?.includes(searchTerm),
    )
  }

  return (
    <div className="w-layout-grid grid-order-summary-container">
      {result?.map((user) => (
        <PhotographersDatabaseRow user={user.attributes} key={user.id} />
      ))}
    </div>
  )
}

export default PhotographersDatabaseTable
