import { useState, useCallback, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import queryString from 'query-string'
import values from 'lodash/values'
import cn from 'classnames'

import { ReactComponent as VisibilityIcon } from 'images/icons/visibility.svg'
import { ReactComponent as ErrorIcon } from 'images/icons/error.svg'
import { ReactComponent as CheckIcon } from 'images/icons/check-rounded.svg'

import httpClient from 'httpClient'
import { useToggle } from 'hooks'
import { apiErrorMessage, isEmail } from 'lib/utils'
import { PROFILE_RULES, SIGN_UP_STEPS } from 'lib/const'

import Heading from 'components/common/Heading'
import ErrorMessage from 'components/common/ErrorMessage'

const Step1 = ({ onNextStep }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm()
  const location = useLocation()
  const navigate = useNavigate()
  const [displayPassword, setDisplayPassword] = useState(false)
  const [displayPasswordConfirmation, setDisplayPasswordConfirmation] = useState(false)
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [validEmail, setValidEmail] = useState(null)
  const [agreeTC, toggleTC] = useToggle()
  const email = watch('email')

  useEffect(() => {
    if (!isEmail(email)) return
    ;(async () => {
      try {
        await httpClient.post('check_for_email', { email })
        setValidEmail(false)
      } catch (error) {
        setValidEmail(true)
      }
    })()
  }, [email])

  const handleOnSubmit = useCallback(
    async (data) => {
      if (!validEmail) return
      setLoading(true)
      setApiError(null)
      try {
        const { token } = await httpClient.post('users', data)

        const search = queryString.stringify({ token })
        navigate({ pathname: location.pathname, search }, { replace: true })

        onNextStep(SIGN_UP_STEPS.referral)
        setLoading(false)
      } catch (error) {
        setApiError(apiErrorMessage(error))
        setLoading(false)
      }
    },
    [validEmail],
  )

  return (
    <div className="div-full-screen white-smoke">
      <div className="div-block-181">
        <Heading title="Welcome to" withBrandName />
        <div className="form-block-3 w-form">
          <form
            id="email-form"
            name="email-form"
            onSubmit={handleSubmit(handleOnSubmit)}
            className="form-9"
          >
            <div className="form-item-2 horizontal">
              <Controller
                rules={PROFILE_RULES.firstName}
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="text-input__field-wrapper">
                    <div className="text-input__field-wrapper">
                      <input
                        {...field}
                        type="text"
                        className="text-input w-input"
                        placeholder="First Name"
                        id="firstName"
                        maxLength="256"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                )}
              />

              <Controller
                rules={PROFILE_RULES.lastName}
                name="lastName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="text-input__field-wrapper">
                    <div className="text-input__field-wrapper">
                      <input
                        {...field}
                        type="text"
                        className="text-input w-input"
                        placeholder="Last Name"
                        id="lastName"
                        maxLength="256"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="form-item-2 horizontal">
              <Controller
                rules={PROFILE_RULES.email}
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="text-input__field-wrapper">
                    <div className="text-input__field-wrapper">
                      <input
                        {...field}
                        type="email"
                        className="text-input w-input"
                        placeholder="Email"
                        id="email"
                        maxLength="256"
                        disabled={isSubmitting}
                        style={{ padding: '0px 2.5rem 0 1rem' }}
                      />
                      {isEmail(email) && (
                        <div className="eye-2" style={{ opacity: 1 }}>
                          <div className="icon w-embed">
                            {validEmail ? (
                              <CheckIcon color="#73d13d" />
                            ) : (
                              <ErrorIcon color="#ff4d4f" />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              />
              <Controller
                rules={PROFILE_RULES.phoneNumber}
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="text-input__field-wrapper">
                    <div className="text-input__field-wrapper">
                      <input
                        {...field}
                        type="text"
                        className="text-input w-input"
                        placeholder="Phone Number"
                        id="phone"
                        maxLength="256"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="form-item-2">
              <Controller
                rules={PROFILE_RULES.website}
                name="website"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="text-input__field-wrapper">
                    <div className="text-input__field-wrapper">
                      <input
                        {...field}
                        type="text"
                        className="text-input w-input"
                        placeholder="Website or Social Media URL"
                        id="website"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                )}
              />
            </div>
            <Controller
              rules={PROFILE_RULES.password}
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="form-item-2">
                  <div className="text-input__field-wrapper">
                    <input
                      {...field}
                      type={displayPassword ? 'text' : 'password'}
                      className="text-input-2 w-input"
                      placeholder="New Password"
                      id="Password"
                    />
                    <div
                      className="eye-2"
                      onClick={() => setDisplayPassword((prev) => !prev)}
                      role="button"
                    >
                      <div className="icon w-embed">
                        <VisibilityIcon />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
            <Controller
              rules={{
                required: { value: true, message: 'Confirm new password is required' },
                validate: (value) =>
                  value === watch('password') || "Confirmation password doesn't match new password",
              }}
              name="passwordConfirmation"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="form-item-2">
                  <div className="text-input__field-wrapper">
                    <input
                      {...field}
                      type={displayPasswordConfirmation ? 'text' : 'password'}
                      className="text-input-2 w-input"
                      placeholder="Confirm New Password"
                      id="passwordConfirmation"
                      disabled={isSubmitting}
                    />
                    <div
                      className="eye-2"
                      onClick={() => setDisplayPasswordConfirmation((prev) => !prev)}
                      role="button"
                    >
                      <div className="icon w-embed">
                        <VisibilityIcon />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
            <div className="form-item-3 centered">
              <label className="w-checkbox checkbox-wrap">
                <div
                  className={cn(
                    'w-checkbox-input w-checkbox-input--inputType-custom checkbox-6 small',
                    {
                      'w--redirected-checked': agreeTC,
                    },
                  )}
                />
                <input
                  type="checkbox"
                  id="toggle-tc"
                  name="checkbox-2"
                  data-name="Checkbox 2"
                  onClick={toggleTC}
                  style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                />
                <span className="checkbox-label-5 small w-form-label">
                  I Agree to terms and conditions.
                </span>
              </label>
              <div className="text-block-34 margin-top">
                By entering your email, you consent to
                <br />
                receive email updates from MUSEA.
              </div>
            </div>
            <button
              type="submit"
              disabled={isSubmitting || loading || !validEmail || !agreeTC}
              className={cn('main-button w-button button-2', {
                disabled: loading || isSubmitting || !validEmail || !agreeTC,
              })}
            >
              {loading || isSubmitting ? 'Please wait...' : 'Next'}
            </button>
          </form>
          {(values(errors) || apiError) && (
            <div className="form-item-2">
              <ErrorMessage errors={values(errors)?.[0]?.message || apiError} />
            </div>
          )}
        </div>

        <div className="div-block-184 more-margin">
          <div className="form-item-3">
            <div className="text-block-34">
              {'Already a member? '}
              <Link to="/login" className="link-3">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step1
