import { Link } from 'react-router-dom'
import { isArray } from 'lodash'

const ErrorCode = ({ errors, code }) => (
  <div className="img-container-print-order">
    <div className="loading-container">
      <h2 className="heading-title-2">{code}</h2>
      <div className="divider-16px" />
      {isArray(errors) ? (
        // eslint-disable-next-line react/no-array-index-key
        errors.map((error, index) => <div key={`error-${index}`}>{error}</div>)
      ) : (
        <div>{errors}</div>
      )}
      <div className="divider-16px" />
      <div className="divider-16px" />
      <Link
        className="button-2  w-inline-block shadow w-inline-block"
        to="/"
        style={{ width: 'fit-content' }}
      >
        Go Home
      </Link>
    </div>
  </div>
)

export default ErrorCode
