import { NavLink } from 'react-router-dom'
import { DEFAULT_ANIMATION } from 'lib/const'

const NavItem = ({ isExpanded, icon: Icon, to, label, className, children }) => (
  <NavLink
    id="w-node-_4ed3bba0-20f4-ecdd-52a3-fa7ad124ba06-d124b9fc"
    to={to ?? '/'}
    aria-current="page"
    className={`sidebar-button w-inline-block ${className}`}
    activeclassname="w--current"
  >
    <div className="img-order-summary-container">
      <div className="icon w-embed">
        <Icon />
      </div>
      {children}
    </div>
    <div
      className="div-block"
      style={{
        transform: isExpanded
          ? 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
          : 'translate3d(-20px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
        transformStyle: 'preserve-3d',
        transition: DEFAULT_ANIMATION,
        width: isExpanded ? 'auto' : '0',
        opacity: isExpanded ? '1' : '0',
      }}
    >
      <div>{label}</div>
    </div>
  </NavLink>
)

export default NavItem
