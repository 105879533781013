import { useCallback } from 'react'
import cn from 'classnames'

const InputCheckbox = ({ children, label, value, onClick, checked, urgency }) => {
  const handleOnClick = useCallback(() => {
    onClick(value)
  }, [value])

  return (
    <div className={cn('div-block-45', { urgency })} role="button">
      <label className="w-checkbox checkbox-field">
        <div
          className={cn('w-checkbox-input w-checkbox-input--inputType-custom checkbox', {
            'w--redirected-checked': checked,
          })}
        />
        <input
          type="checkbox"
          id="checkbox-7"
          name="checkbox-7"
          style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
          onClick={handleOnClick}
        />
        <span className={cn('w-form-label', { urgency })}>{label}</span>
      </label>
      {children}
    </div>
  )
}

export default InputCheckbox
