import './styles.css'

const IntegrationInstalling = () => (
  <div className="body-full">
    <div className="div-block-57">
      <div className="info-div">
        <div>
          <h4 className="heading-3">You are about to install Musea</h4>
        </div>
        <div className="install-logos-div">
          <div className="logo-install">
            <div className="logo-wrap">
              <img
                src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d311bbda9eb_descarga.png"
                width="100"
                alt=""
                className="image-80"
              />
            </div>
            <div className="div-block-58">
              <div>Pixieset</div>
            </div>
          </div>
          <div id="w-node-_82d4b72e-5343-5914-b47b-81f072b1d2ea-c3bda993" className="lottie-wrap">
            <div
              data-w-id="232487c7-8068-f6c8-c563-43729ef3cc0d"
              data-animation-type="lottie"
              data-src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d2418bda9e9_38215-arrow.json"
              data-loop="1"
              data-direction="1"
              data-autoplay="1"
              data-is-ix2-target="0"
              data-renderer="svg"
              data-default-duration="1.1333333333333333"
              data-duration="0"
              className="lottie-animation"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
                width="500"
                height="500"
                preserveAspectRatio="xMidYMid meet"
                style={{ width: '100%', height: '100%', transform: 'translate3d(0px, 0px, 0px)' }}
              >
                <defs>
                  <clipPath id="__lottie_element_2">
                    <rect width="500" height="500" x="0" y="0" />
                  </clipPath>
                </defs>
                <g clipPath="url(#__lottie_element_2)">
                  <g
                    transform="matrix(1,0,0,1,117.40400695800781,230.42271423339844)"
                    opacity="1"
                    style={{ display: 'block' }}
                  >
                    <g opacity="1" transform="matrix(1,0,0,1,132.5959930419922,101.95500183105469)">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillOpacity="0"
                        stroke="rgb(37,141,236)"
                        strokeOpacity="1"
                        strokeWidth="30"
                        d=" M57.59600067138672,-26.954999923706055 C57.59600067138672,-26.954999923706055 -0.0010000000474974513,26.954999923706055 -0.0010000000474974513,26.954999923706055 C-0.0010000000474974513,26.954999923706055 -57.59700012207031,-26.954999923706055 -57.59700012207031,-26.954999923706055"
                      />
                    </g>
                  </g>
                  <g
                    transform="matrix(1,0,0,1,177.40399169921875,221.2072296142578)"
                    opacity="0.8"
                    style={{ display: 'block' }}
                  >
                    <g opacity="1" transform="matrix(1,0,0,1,72.59600067138672,41.95500183105469)">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillOpacity="0"
                        stroke="rgb(37,141,236)"
                        strokeOpacity="1"
                        strokeWidth="30"
                        d=" M57.59600067138672,-26.954999923706055 C57.59600067138672,-26.954999923706055 -0.0010000000474974513,26.954999923706055 -0.0010000000474974513,26.954999923706055 C-0.0010000000474974513,26.954999923706055 -57.59700012207031,-26.954999923706055 -57.59700012207031,-26.954999923706055"
                      />
                    </g>
                  </g>
                  <g
                    transform="matrix(1,0,0,1,177.40399169921875,152.31878662109375)"
                    opacity="0.5"
                    style={{ display: 'block' }}
                  >
                    <g opacity="1" transform="matrix(1,0,0,1,72.59600067138672,41.95500183105469)">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillOpacity="0"
                        stroke="rgb(37,141,236)"
                        strokeOpacity="1"
                        strokeWidth="30"
                        d=" M57.59600067138672,-26.95599937438965 C57.59600067138672,-26.95599937438965 -0.0010000000474974513,26.95599937438965 -0.0010000000474974513,26.95599937438965 C-0.0010000000474974513,26.95599937438965 -57.59700012207031,-26.95599937438965 -57.59700012207031,-26.95599937438965"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="logo-install">
            <div className="logo-wrap">
              <img
                src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d68b6bda9d6_Main_logo_Musea_Beta_3_desaturated.png"
                srcSet="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d68b6bda9d6_Main_logo_Musea_Beta_3_desaturated-p-500.png 500w, https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d68b6bda9d6_Main_logo_Musea_Beta_3_desaturated-p-800.png 800w, https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d68b6bda9d6_Main_logo_Musea_Beta_3_desaturated-p-1080.png 1080w, https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d68b6bda9d6_Main_logo_Musea_Beta_3_desaturated.png 1422w"
                width="100"
                sizes="(max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                alt=""
                className="image-80-copy"
              />
            </div>
            <div className="div-block-58">
              <div>Musea</div>
            </div>
          </div>
        </div>
        <div className="div-block-59">
          <h4 className="heading-4">Musea will be able to:</h4>
          <div className="divider" />
          <div className="div-details-install">
            <div>View Pixieset Account Data</div>
            <a href="/" className="link-details">
              View&nbsp;Details
            </a>
          </div>
          <div className="div-details-install">
            <div>Manage Price Lists</div>
            <a href="/" className="link-details">
              View&nbsp;Details
            </a>
          </div>
          <div className="div-details-install">
            <div>Manage Orders</div>
            <a href="/" className="link-details">
              View&nbsp;Details
            </a>
          </div>
        </div>
        <div className="w-layout-grid grid-7">
          <a href="/" className="main-button-3 transparent w-inline-block">
            <div>Cancel</div>
          </a>
          <a
            id="w-node-e36e38dd-fa67-7ec0-03e0-6e95481a907b-c3bda993"
            href="/"
            className="main-button-3 w-inline-block"
          >
            <div>Install App</div>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default IntegrationInstalling
