import LineItem from './LineItem'

const OrderSummary = ({ lineItems }) => (
  <div className="_3rd-block-container-order">
    <div className="heading-container">
      <div className="grid-order-icons">
        <h2 className="heading-title right-margin">Order Summary</h2>
      </div>
    </div>
    <div className="container-rightside-order-summary">
      <div className="w-layout-grid grid-element-order-summary head client">
        <div id="w-node-b5d27e99-ef88-6e89-7677-2535cf1f25a5-fde80a15" className="heady">
          <div>Image</div>
        </div>
        <div className="heady print-details">
          <div>Print Details</div>
        </div>
        <div className="heady">
          <div>Details</div>
        </div>
        <div className="heady">
          <div>Notes</div>
        </div>
        <div id="w-node-_4ff616b2-037f-87d4-e48c-76a9c4e5c1fc-fde80a15" className="heady">
          <div>Qty</div>
        </div>
        <div className="heady">
          <div>Total</div>
        </div>
      </div>

      {lineItems.map((lineItem) => (
        <LineItem key={`lineitem-${lineItem.id}`} lineItem={lineItem.attributes} />
      ))}
    </div>
  </div>
)

export default OrderSummary
