import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router'

const ProductSelectionCopy = () => {
  const location = useLocation()

  const logOrder = useCallback(async () => {
    console.log('location: ', location)
  }, [location])

  useEffect(() => {
    logOrder()
  }, [location])

  return (
    <div className="w-layout-grid grid-print-order">
      <div className="sidebar-product-selection white">
        <div className="controls-block-container">
          <div className="controls-block-grid top">
            <div data-tippy-content="Back to Home" className="div-block-169-copy ghost">
              <div className="icon small w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M218.76367,103.7002,138.75684,30.96436a15.93657,15.93657,0,0,0-21.52637.00146L37.2373,103.69971A16.03108,16.03108,0,0,0,32,115.53857l0,92.09522a16.47275,16.47275,0,0,0,4.01066,10.96174A15.91729,15.91729,0,0,0,48.002,223.999H95.96484a8,8,0,0,0,8-8V167.9917a8,8,0,0,1,8-8h32a8,8,0,0,1,8,8V215.999a8,8,0,0,0,8,8h48.05731a15.40625,15.40625,0,0,0,7.53406-1.85584A16.08415,16.08415,0,0,0,224,207.999v-92.46A16.03567,16.03567,0,0,0,218.76367,103.7002Z" />
                </svg>
              </div>
            </div>
            <div data-tippy-content="Reset All" className="div-block-169-copy ghost">
              <div className="icon small w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <polyline
                    points="79.833 99.716 31.833 99.716 31.833 51.716"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <path
                    d="M190.2254,65.7746a88,88,0,0,0-124.4508,0L31.83348,99.71573"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <polyline
                    points="176.167 156.284 224.167 156.284 224.167 204.284"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <path
                    d="M65.7746,190.2254a88,88,0,0,0,124.4508,0l33.94112-33.94113"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                </svg>
              </div>
            </div>
            <a href="/product-selection" className="button-2 w-inline-block">
              <div className="icon right-margin w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M128,23.99805a104,104,0,1,0,104,104A104.12041,104.12041,0,0,0,128,23.99805Zm40,112H136v32a8,8,0,1,1-16,0v-32H88a8,8,0,0,1,0-16h32v-32a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
                </svg>
              </div>
              <div>Upload More</div>
            </a>
          </div>
        </div>
        <div className="print-order-sidebar grid">
          <div className="accordion-item-block">
            <div className="w-layout-grid grid-form">
              <div
                id="w-node-ef53a1b5-32fb-b097-4006-9c6a2441165c-e99303ca"
                className="input-container paper-type"
              >
                <div className="label-container">
                  <div className="div-block-150">
                    <div className="sub-label-form">choose product type</div>
                    <div className="icon mini margin-left opacity70 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="form-block-input w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                    className="form-input select-field"
                  >
                    <div className="select-field-wrapper">
                      <select
                        id="select-product"
                        name="field-3"
                        data-name="Field 3"
                        className="form-select-field w-select"
                      >
                        <option value="Multi-Opening">Multi-Opening</option>
                      </select>
                      <div className="div-block-63">
                        <img
                          src="images/chevron-down-12.svg"
                          loading="lazy"
                          alt=""
                          className="img-icon"
                        />
                      </div>
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper-dynamic-multi">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form _1fr">
                  <div
                    id="w-node-cb859e8a-d0d5-3cd8-8d53-8d5d48f8fd12-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Choose multi-opening product type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div data-hover="false" data-delay="0" className="dropdown-5 w-dropdown">
                          <div className="dropdown-toggle-4 w-dropdown-toggle">
                            <div id="title-tag">Choose One</div>
                            <div className="div-block-63">
                              <img
                                src="images/chevron-down-12.svg"
                                loading="lazy"
                                alt=""
                                className="img-icon"
                              />
                            </div>
                          </div>
                          <nav className="dropdown-list-6 w-dropdown-list">
                            <div className="div-block-328">
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_9ba91436-3fcf-2a37-38e3-957c4957edb1-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/three-5x7---12x23-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/three-5x7---12x23-mat-p-500.png 500w, images/three-5x7---12x23-mat-p-800.png 800w, images/three-5x7---12x23-mat-p-1080.png 1080w, images/three-5x7---12x23-mat-p-1600.png 1600w, images/three-5x7---12x23-mat-p-2000.png 2000w, images/three-5x7---12x23-mat-p-2600.png 2600w, images/three-5x7---12x23-mat-p-3200.png 3200w, images/three-5x7---12x23-mat.png 6900w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Three 5x5s - 10x23 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="event"
                                    id="three5by5"
                                    name="event"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="three5by5"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_19f79e18-2aca-575c-ccae-61d436ec736b-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/three-8x8---13x32-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/three-8x8---13x32-mat-p-500.png 500w, images/three-8x8---13x32-mat-p-800.png 800w, images/three-8x8---13x32-mat-p-1080.png 1080w, images/three-8x8---13x32-mat-p-3200.png 3200w, images/three-8x8---13x32-mat.png 9600w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Three 8x8s -13x32 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_2980a710-7174-cb05-5d31-964142ebb732-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/three-4x6---11x20-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/three-4x6---11x20-mat-p-500.png 500w, images/three-4x6---11x20-mat-p-800.png 800w, images/three-4x6---11x20-mat-p-1080.png 1080w, images/three-4x6---11x20-mat-p-1600.png 1600w, images/three-4x6---11x20-mat-p-2000.png 2000w, images/three-4x6---11x20-mat-p-2600.png 2600w, images/three-4x6---11x20-mat-p-3200.png 3200w, images/three-4x6---11x20-mat.png 6000w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Three 4x6s - 11x20 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_6ac2cf6a-7ca7-de35-e233-edd6a3250f24-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/three-5x7---12x23-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/three-5x7---12x23-mat-p-500.png 500w, images/three-5x7---12x23-mat-p-800.png 800w, images/three-5x7---12x23-mat-p-1080.png 1080w, images/three-5x7---12x23-mat-p-1600.png 1600w, images/three-5x7---12x23-mat-p-2000.png 2000w, images/three-5x7---12x23-mat-p-2600.png 2600w, images/three-5x7---12x23-mat-p-3200.png 3200w, images/three-5x7---12x23-mat.png 6900w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Three 5x7s - 12x23 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_3871876f-6f02-45cd-7946-d8f8909d6ad2-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/three-6x9---14x26-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/three-6x9---14x26-mat-p-500.png 500w, images/three-6x9---14x26-mat-p-3200.png 3200w, images/three-6x9---14x26-mat.png 7800w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Three 6x9s - 14x26 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_9b92be9f-a6c0-58e8-c33c-6a8e7986bbe8-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/two-4x6--6x9---11x25-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/two-4x6--6x9---11x25-mat-p-500.png 500w, images/two-4x6--6x9---11x25-mat-p-800.png 800w, images/two-4x6--6x9---11x25-mat-p-1080.png 1080w, images/two-4x6--6x9---11x25-mat-p-1600.png 1600w, images/two-4x6--6x9---11x25-mat-p-2000.png 2000w, images/two-4x6--6x9---11x25-mat-p-2600.png 2600w, images/two-4x6--6x9---11x25-mat-p-3200.png 3200w, images/two-4x6--6x9---11x25-mat.png 7500w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Two V 4x6&#x27;s &amp; H 6x9 - 11x25 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_3e3cb261-ec4e-2eb0-a06d-c7a164e4c74e-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/two-5x7--7x10---12x28-mat-1.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/two-5x7--7x10---12x28-mat-1-p-500.png 500w, images/two-5x7--7x10---12x28-mat-1-p-800.png 800w, images/two-5x7--7x10---12x28-mat-1-p-1080.png 1080w, images/two-5x7--7x10---12x28-mat-1.png 8400w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Two V 5x7s &amp; H 7x10 - 12x28 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_92673a71-1ed6-09b0-c9e7-d89bf1e20b33-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/four-5x5---16x16-mat-1.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/four-5x5---16x16-mat-p-500.png 500w, images/four-5x5---16x16-mat-p-800.png 800w, images/four-5x5---16x16-mat-p-1080.png 1080w, images/four-5x5---16x16-mat-p-1600.png 1600w, images/four-5x5---16x16-mat-p-2000.png 2000w, images/four-5x5---16x16-mat-p-2600.png 2600w, images/four-5x5---16x16-mat-p-3200.png 3200w, images/four-5x5---16x16-mat-1.png 4800w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Four 5x5s - 16x16 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_5a9db7c4-0fc7-383b-d911-b1457006d7ad-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/four-8x8---22x22-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/four-8x8---22x22-mat-p-500.png 500w, images/four-8x8---22x22-mat-p-800.png 800w, images/four-8x8---22x22-mat.png 6600w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Four 8x8s - 22x22 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_52a63724-ea4b-167e-eb11-737d1fb21c39-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/four-5x7---12x29-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/four-5x7---12x29-mat-p-500.png 500w, images/four-5x7---12x29-mat-p-800.png 800w, images/four-5x7---12x29-mat-p-1080.png 1080w, images/four-5x7---12x29-mat-p-1600.png 1600w, images/four-5x7---12x29-mat.png 8700w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Four 5x7s - 12x29 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_40a8c441-8faa-d5c1-6d27-73085d7beeda-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/three-5x5--three-5x7---20x24-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/three-5x5--three-5x7---20x24-mat-p-500.png 500w, images/three-5x5--three-5x7---20x24-mat-p-800.png 800w, images/three-5x5--three-5x7---20x24-mat-p-3200.png 3200w, images/three-5x5--three-5x7---20x24-mat.png 7200w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Three 5x5s &amp; three 5x7s - 20x24 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-dc746769-3643-8f24-ff7f-b62fdb71616a-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/six-4x6--18x20-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/six-4x6--18x20-mat-p-500.png 500w, images/six-4x6--18x20-mat-p-800.png 800w, images/six-4x6--18x20-mat-p-1080.png 1080w, images/six-4x6--18x20-mat-p-1600.png 1600w, images/six-4x6--18x20-mat.png 6000w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Six 4x6s - 18x20 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-a4d9e2c9-5c33-38a9-d386-6b5a4fd55876-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/nine-5x5---23x23-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/nine-5x5---23x23-mat-p-500.png 500w, images/nine-5x5---23x23-mat-p-800.png 800w, images/nine-5x5---23x23-mat.png 6900w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Nine 5x5s - 23x23 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                              <div className="thumbnail-multiopening-card">
                                <label className="radio-button-field-3 w-radio">
                                  <div className="div-block-335">
                                    <div
                                      id="w-node-_52747d80-36b2-7770-59e9-20055429b5ee-e99303ca"
                                      className="div-block-336"
                                    >
                                      <img
                                        src="images/nine-5x7--24x30-mat.png"
                                        loading="lazy"
                                        sizes="100vw"
                                        srcSet="images/nine-5x7--24x30-mat-p-500.png 500w, images/nine-5x7--24x30-mat-p-800.png 800w, images/nine-5x7--24x30-mat-p-3200.png 3200w, images/nine-5x7--24x30-mat.png 7200w"
                                        alt=""
                                        className="image-thumb-multi-opening"
                                      />
                                    </div>
                                    <div>
                                      <div className="multi-opening-label">
                                        Nine 5x7s - 24x30 Mat/Frame
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-4 w-radio-input" />
                                  <input
                                    type="radio"
                                    data-name="Radio 9"
                                    id="radio-9"
                                    name="radio-9"
                                    value="Radio 9"
                                    style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
                                  />
                                  <span
                                    className="radio-button-label-2 w-form-label"
                                    htmlFor="radio-9"
                                  >
                                    Radio 9
                                  </span>
                                </label>
                              </div>
                            </div>
                          </nav>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper-dynamic-multi">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form _1fr">
                  <div
                    id="w-node-f83a8489-cac2-c445-8008-b8bdc9fbf92d-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Choose Finishing service</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            data-name="Field 3"
                            name="field-3"
                            id="finishing-service"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="matting">Matting</option>
                            <option value="framing">Framing</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-album-options" className="wrapper-dynamic-album">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form _1fr">
                  <div
                    id="w-node-_62e988bb-e2e1-739f-0992-748cd377d365-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Choose album type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="album-type"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="artisan">Artisan Album</option>
                            <option value="matted">Matted Album</option>
                            <option value="museum">Museum Case</option>
                            <option value="folio">Double Folio</option>
                            <option value="slipcases">Matted Slipcase</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-canvas" className="wrapper-dynamic-canvas">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form">
                  <div className="input-container">
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Quantity</div>
                      </div>
                    </div>
                    <div className="form-block-input qty w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <input
                            type="number"
                            className="text-field-2 qty w-input"
                            maxLength="256"
                            name="field-4"
                            data-name="Field 4"
                            placeholder="01"
                            id="data-jscolor"
                          />
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-c6c2207a-d352-622d-1d16-1f430770a9c4-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">canvas size</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-size">Choose Size</option>
                            <option value="4x6 ($7.00)">4x6 ($7.00)</option>
                            <option value="5x5 ($7.00)">5x5 ($7.00)</option>
                            <option value="5x7 ($9.00)">5x7 ($9.00)</option>
                            <option value="6x9 ($14.00)">6x9 ($14.00)</option>
                            <option value="8x8 ($16.00)">8x8 ($16.00)</option>
                            <option value="7x10 ($19.00)">7x10 ($19.00)</option>
                            <option value="8x10 ($19.00)">8x10 ($19.00)</option>
                            <option value="8x12 ($25.00)">8x12 ($25.00)</option>
                            <option value="10x10 ($25.00)">10x10 ($25.00)</option>
                            <option value="12x12 ($30.00)">12x12 ($30.00)</option>
                          </select>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-layout-grid grid-form _1fr">
                  <div
                    id="w-node-c6c2207a-d352-622d-1d16-1f430770a9d7-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">wrap style</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-style">Choose Style</option>
                            <option value="Gallery Wrap">Gallery Wrap</option>
                            <option value="Mirror Wrap">Mirror Wrap</option>
                            <option value="Black Wrap">Black Wrap</option>
                            <option value="White Wrap">White Wrap</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-c6c2207a-d352-622d-1d16-1f430770a9e9-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">frame</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="selector"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="No Frame">No Frame</option>
                            <option value="Oil and Wax Maple">Oil and Wax Maple</option>
                            <option value="White Maple">White Maple</option>
                            <option value="Light Grey Maple">Light Grey Maple</option>
                            <option value="Medium Grey Maple">Medium Grey Maple</option>
                            <option value="Satin Black Maple">Satin Black Maple</option>
                            <option value="Whitewash Ash">Whitewash Ash</option>
                            <option value="Rising White Ash">Rising White Ash</option>
                            <option value="Dark Grey Ash">Dark Grey Ash</option>
                            <option value="Satin Black Ash">Satin Black Ash</option>
                            <option value="Oil and Wax Cherry">Oil and Wax Cherry</option>
                            <option value="Aged Cherry">Aged Cherry</option>
                            <option value="Antique Cherry">Antique Cherry</option>
                            <option value="Black Cherry">Black Cherry</option>
                            <option value="Oil and Wax Walnut">Oil and Wax Walnut</option>
                            <option value="Ebony Walnut">Ebony Walnut</option>
                            <option value="Special Dark Walnut">Special Dark Walnut</option>
                            <option value="Satin Black Walnut">Satin Black Walnut</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-prints" className="wrapper-dynamic-prints">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form">
                  <div className="input-container">
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Quantity</div>
                      </div>
                    </div>
                    <div className="form-block-input qty w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <input
                            type="number"
                            className="text-field-2 qty w-input"
                            maxLength="256"
                            name="field-4"
                            data-name="Field 4"
                            placeholder="01"
                            id="data-jscolor"
                          />
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_4a120b44-a597-ab3a-8792-fefbeb26d9d2-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">paPer Type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="my-field-3"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="Lustre">Lustre</option>
                            <option value="Satin">Satin</option>
                            <option value="Photo Matte">Photo Matte</option>
                            <option value="Ultra Smooth">Ultra Smooth</option>
                            <option value="Platine">Platine</option>
                            <option value="Canson Rag">Canson Rag</option>
                            <option value="Museum Etching">Museum Etching</option>
                            <option value="Deckled with border">Deckled with border</option>
                            <option value="Deckled no border">Deckled no border</option>
                            <option value="Circle Deckled with border">
                              Circle Deckled with border
                            </option>
                            <option value="Circle Deckled no border">
                              Circle Deckled no border
                            </option>
                            <option value="Canvas Gallery Wrap">Canvas Gallery Wrap</option>
                            <option value="Canvas Mirror Wrap">Canvas Mirror Wrap</option>
                            <option value="Canvas White Wrap">Canvas White Wrap</option>
                            <option value="Canvas Black Wrap">Canvas Black Wrap</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-layout-grid grid-form">
                  <div className="input-container">
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Print Size (IN)</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-size">Choose Size</option>
                            <option value="4x6 ($7.00)">4x6 ($7.00)</option>
                            <option value="5x5 ($7.00)">5x5 ($7.00)</option>
                            <option value="5x7 ($9.00)">5x7 ($9.00)</option>
                            <option value="6x9 ($14.00)">6x9 ($14.00)</option>
                            <option value="8x8 ($16.00)">8x8 ($16.00)</option>
                            <option value="7x10 ($19.00)">7x10 ($19.00)</option>
                            <option value="8x10 ($19.00)">8x10 ($19.00)</option>
                            <option value="8x12 ($25.00)">8x12 ($25.00)</option>
                            <option value="10x10 ($25.00)">10x10 ($25.00)</option>
                            <option value="12x12 ($30.00)">12x12 ($30.00)</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-c5b29070-bb85-0593-0462-7bdb85104048-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Finishing Service</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="choose-finishing"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="framing">Framing</option>
                            <option value="matting">Matting</option>
                            <option value="mounting">Mounting</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-artisan" className="wrapper-dynamic-artisan">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form auto">
                  <div
                    id="w-node-_2ed638fe-e365-110a-96ec-db31c35edcd4-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">choose fabric</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Cover Fabric">Cover Fabric</option>
                            <option value="Interior Fabric">Interior Fabric</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-b6e31bf8-dee5-52a1-839a-453d75f0e727-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">album size</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="10x10">10x10</option>
                            <option value="10x12 Vertical">10x12 Vertical</option>
                            <option value="10x12 Horizontal">10x12 Horizontal</option>
                            <option value="8x16">8x16</option>
                            <option value="12x12">12x12</option>
                            <option value="11x14 Vertical">11x14 Vertical</option>
                            <option value="11x14 Horizontal">11x14 Horizontal</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div id="show-album-options" className="wrapper-dynamic-album">
                    <div className="accordion-item-block no-margin">
                      <div className="w-layout-grid grid-form _1fr">
                        <div
                          id="w-node-a7e0266c-b0bf-b61a-c33a-be862e5253a6-e99303ca"
                          className="input-container"
                        >
                          <div className="label-container">
                            <div className="label-subcontainer">
                              <div className="sub-label-form">cover fabric</div>
                            </div>
                          </div>
                          <div className="form-block-input w-form">
                            <form
                              id="email-form"
                              name="email-form"
                              data-name="Email Form"
                              method="get"
                              className="form-input select-field"
                            >
                              <div className="select-field-wrapper">
                                <select
                                  id="size"
                                  name="field-3"
                                  data-name="Field 3"
                                  className="form-select-field w-select"
                                >
                                  <option value="choose-one">Choose One</option>
                                  <option value="artisan-album">Artisan Album</option>
                                  <option value="Matted-Album">Matted Album</option>
                                  <option value="Museum Case">Museum Case</option>
                                  <option value="Double Folio">Double Folio</option>
                                  <option value="Matted Slipcase">Matted Slipcase</option>
                                </select>
                                <div className="div-block-63">
                                  <img
                                    src="images/chevron-down-12.svg"
                                    loading="lazy"
                                    alt=""
                                    className="img-icon"
                                  />
                                </div>
                              </div>
                            </form>
                            <div className="w-form-done">
                              <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                              <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_26db900e-4c0e-aa21-d9c0-56214b83f5b8-e99303ca"
                    className="w-layout-grid grid-form _1fr"
                  >
                    <div
                      id="w-node-c959bd32-c7fd-3a5b-cfc5-c1a430af7e03-e99303ca"
                      className="input-container large"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">Interior fabric</div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <select
                              id="size"
                              name="field-3"
                              data-name="Field 3"
                              className="form-select-field w-select"
                            >
                              <option value="choose-one">Choose One</option>
                              <option value="Willowbrook">Willowbrook</option>
                              <option value="Bronze">Bronze</option>
                              <option value="Cadet Grey">Cadet Grey</option>
                              <option value="Bisque">Bisque</option>
                              <option value="Cape Cod Sand">Cape Cod Sand</option>
                              <option value="Spice">Spice</option>
                              <option value="Dark Amber">Dark Amber</option>
                              <option value="Eggplant">Eggplant</option>
                              <option value="Fawn">Fawn</option>
                              <option value="Goldenrod">Goldenrod</option>
                              <option value="Heathered Sand">Heathered Sand</option>
                              <option value="Heathered Slate">Heathered Slate</option>
                              <option value="Heathered Walnut">Heathered Walnut</option>
                              <option value="Honey">Honey</option>
                              <option value="Hibiscus">Hibiscus</option>
                              <option value="White Marigold">White Marigold</option>
                              <option value="Symphony Blue">Symphony Blue</option>
                              <option value="Pastel Pink">Pastel Pink</option>
                              <option value="Cobalt">Cobalt</option>
                              <option value="Copper Fox">Copper Fox</option>
                              <option value="Agave">Agave</option>
                              <option value="Blackout">Blackout</option>
                              <option value="Light Grey">Light Grey</option>
                              <option value="Oat">Oat</option>
                              <option value="Stone">Stone</option>
                            </select>
                            <div className="div-block-63">
                              <img
                                src="images/chevron-down-12.svg"
                                loading="lazy"
                                alt=""
                                className="img-icon"
                              />
                            </div>
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_4d90e65e-f806-bcca-4734-8498042f9baa-e99303ca"
                      className="input-container quantity _2"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">number of spreads</div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <input
                              type="number"
                              className="text-field-2 w-input"
                              maxLength="256"
                              name="field-8"
                              data-name="Field 8"
                              placeholder="10"
                              id="field-8"
                              required=""
                            />
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_42287c33-fa92-b5ea-ddf1-5e4c159d4b2e-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>Debossing or Foil Stamping</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Blind Debossing">Blind Debossing</option>
                            <option value="Gold Foil Stamping">Gold Foil Stamping</option>
                            <option value="Silver Foil Stamping">Silver Foil Stamping</option>
                            <option value="None">None</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_60bb72c6-0278-4171-52cb-db5668b67033-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>Debossing / Foil Stamping text</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <input
                            type="text"
                            className="text-field-2 w-input"
                            maxLength="256"
                            name="field-7"
                            data-name="Field 7"
                            placeholder="Example Text"
                            id="field-7"
                            required=""
                          />
                        </div>
                      </form>
                      <div className="success-message-3 w-form-done" />
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-e2fd29aa-fb75-6911-54d7-3a5271293f95-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>Clamshell Upgrade</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Traditional Clamshell">Traditional Clamshell</option>
                            <option value="Split Case">Split Case</option>
                            <option value="None">None</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-matted" className="wrapper-dynamic-matted">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form">
                  <div className="accordion-item-block no-margin">
                    <div className="w-layout-grid grid-form _1fr">
                      <div
                        id="w-node-f3ebb4e4-b805-c56b-bc66-34cc273326e1-e99303ca"
                        className="input-container"
                      >
                        <div className="label-container">
                          <div className="label-subcontainer">
                            <div className="sub-label-form">cover fabric</div>
                          </div>
                        </div>
                        <div className="form-block-input w-form">
                          <form
                            id="email-form"
                            name="email-form"
                            data-name="Email Form"
                            method="get"
                            className="form-input select-field"
                          >
                            <div className="select-field-wrapper">
                              <select
                                id="size"
                                name="field-3"
                                data-name="Field 3"
                                className="form-select-field w-select"
                              >
                                <option value="choose-one">Choose One</option>
                                <option value="artisan-album">Artisan Album</option>
                                <option value="Matted-Album">Matted Album</option>
                                <option value="Museum Case">Museum Case</option>
                                <option value="Double Folio">Double Folio</option>
                                <option value="Matted Slipcase">Matted Slipcase</option>
                              </select>
                              <div className="div-block-63">
                                <img
                                  src="images/chevron-down-12.svg"
                                  loading="lazy"
                                  alt=""
                                  className="img-icon"
                                />
                              </div>
                            </div>
                          </form>
                          <div className="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                          </div>
                          <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-f3ebb4e4-b805-c56b-bc66-34cc27332715-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>Select paper type</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Museum Etching">Museum Etching</option>
                            <option value="Canson Rag">Canson Rag</option>
                            <option value="Ultra Smooth">Ultra Smooth</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-f3ebb4e4-b805-c56b-bc66-34cc27332728-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>select cover fabric</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div className="input-container quantity _2">
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>number of Images</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="14">14</option>
                            <option value="16">16</option>
                            <option value="18">18</option>
                            <option value="20">20</option>
                            <option value="22">22</option>
                            <option value="24">24</option>
                            <option value="26">26</option>
                            <option value="28">28</option>
                            <option value="30">30</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-af9fc50f-52a6-55b5-4b55-cab01fc7f08b-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Interior fabric</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="artisan-album">Artisan Album</option>
                            <option value="Matted-Album">Matted Album</option>
                            <option value="Museum Case">Museum Case</option>
                            <option value="Double Folio">Double Folio</option>
                            <option value="Matted Slipcase">Matted Slipcase</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_51d090be-18a3-4c71-2b9b-9e81fc535741-e99303ca"
                    className="w-layout-grid grid-form"
                  >
                    <div
                      id="w-node-a570c5b4-7952-526b-ce1f-a9f6f3181753-e99303ca"
                      className="input-container"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">
                            <strong>Debossing or Foil Stamping</strong>
                          </div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <select
                              id="size"
                              name="field-3"
                              data-name="Field 3"
                              className="form-select-field w-select"
                            >
                              <option value="choose-one">Choose One</option>
                              <option value="Blind Debossing">Blind Debossing</option>
                              <option value="Gold Foil Stamping">Gold Foil Stamping</option>
                              <option value="Silver Foil Stamping">Silver Foil Stamping</option>
                              <option value="None">None</option>
                            </select>
                            <div className="div-block-63">
                              <img
                                src="images/chevron-down-12.svg"
                                loading="lazy"
                                alt=""
                                className="img-icon"
                              />
                            </div>
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_17b3f633-a8d8-80fb-7dc0-64b953d18920-e99303ca"
                      className="input-container"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">
                            <strong>Debossing / Foil Stamping text</strong>
                          </div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <input
                              type="text"
                              className="text-field-2 w-input"
                              maxLength="256"
                              name="field-7"
                              data-name="Field 7"
                              placeholder="Example Text"
                              id="field-7"
                              required=""
                            />
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_0b7b9a83-10ab-7295-142b-a0828165a30a-e99303ca"
                      className="input-container"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">
                            <strong>Clamshell Upgrade</strong>
                          </div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <select
                              id="size"
                              name="field-3"
                              data-name="Field 3"
                              className="form-select-field w-select"
                            >
                              <option value="choose-one">Choose One</option>
                              <option value="Traditional Clamshell">Traditional Clamshell</option>
                              <option value="Split Case">Split Case</option>
                              <option value="None">None</option>
                            </select>
                            <div className="div-block-63">
                              <img
                                src="images/chevron-down-12.svg"
                                loading="lazy"
                                alt=""
                                className="img-icon"
                              />
                            </div>
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_1b62311c-ab97-4336-db5e-0319d7910d70-e99303ca"
                    className="line-horizontal margins"
                  />
                </div>
              </div>
            </div>
            <div id="show-frame" className="wrapper-dynamic-framing">
              <div id="frame" className="frame">
                <div className="w-layout-grid grid-dynamic _1fr">
                  <div
                    id="w-node-_8b379125-90a6-7d04-edd2-bcdf9dde0fcf-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Choose framing type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="choose-frame"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="standard">Standard Framing</option>
                            <option value="premium">Premium Framing</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-slipcases" className="wrapper-dynamic-slipcases">
              <div id="frame" className="frame">
                <div className="w-layout-grid grid-dynamic _1fr">
                  <div
                    id="w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce103c-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Matted Slipcase Fabric</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Willowbrook">Willowbrook</option>
                            <option value="Bronze">Bronze</option>
                            <option value="Cadet Grey">Cadet Grey</option>
                            <option value="Bisque">Bisque</option>
                            <option value="Cape Cod Sand">Cape Cod Sand</option>
                            <option value="Spice">Spice</option>
                            <option value="Dark Amber">Dark Amber</option>
                            <option value="Eggplant">Eggplant</option>
                            <option value="Fawn">Fawn</option>
                            <option value="Goldenrod">Goldenrod</option>
                            <option value="Heathered Sand">Heathered Sand</option>
                            <option value="Heathered Slate">Heathered Slate</option>
                            <option value="Heathered Walnut">Heathered Walnut</option>
                            <option value="Honey">Honey</option>
                            <option value="Hibiscus">Hibiscus</option>
                            <option value="White Marigold">White Marigold</option>
                            <option value="Symphony Blue">Symphony Blue</option>
                            <option value="Pastel Pink">Pastel Pink</option>
                            <option value="Cobalt">Cobalt</option>
                            <option value="Copper Fox">Copper Fox</option>
                            <option value="Agave">Agave</option>
                            <option value="Blackout">Blackout</option>
                            <option value="Light Grey">Light Grey</option>
                            <option value="Oat">Oat</option>
                            <option value="Stone">Stone</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce104e-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">select paper type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                          <select
                            data-name="Field 3"
                            name="field-3"
                            id="field"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="Museum Etching">Museum Etching</option>
                            <option value="Canson Rag">Canson Rag</option>
                            <option value="Platine">Platine</option>
                            <option value="Ultra Smooth">Ultra Smooth</option>
                          </select>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce1060-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Four-Flap Color Option</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            data-name="Field 3"
                            name="field-3"
                            id="field"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="sage">Sage</option>
                            <option value="Smoke">Smoke</option>
                            <option value="Walnut">Walnut</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce1095-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Debossing or Foil Stamping </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Blind Debossing">Blind Debossing</option>
                            <option value="Gold Foil Stamping">Gold Foil Stamping</option>
                            <option value="Silver Foil Stamping">Silver Foil Stamping</option>
                            <option value="None">None</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce10a8-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>Debossing / Foil Stamping text</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <input
                            type="text"
                            className="text-field-2 w-input"
                            maxLength="256"
                            name="field-7"
                            data-name="Field 7"
                            placeholder="Example Text"
                            id="field-7"
                            required=""
                          />
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_19ce3b49-21b8-4735-a0ff-a830d4a47a5a-e99303ca"
                    className="line-horizontal margins"
                  />
                </div>
              </div>
            </div>
            <div id="show-folios" className="wrapper-dynamic-folios">
              <div id="frame" className="frame">
                <div className="w-layout-grid grid-dynamic _1fr">
                  <div
                    id="w-node-_2358bf69-568a-a491-9b2b-73afd61ce624-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Double Folio Orientation</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            data-name="Field 3"
                            name="field-3"
                            id="field"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="horizontal">Horizontal</option>
                            <option value="vertical">Vertical</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_2358bf69-568a-a491-9b2b-73afd61ce638-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Double Folio Fabric </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Willowbrook">Willowbrook</option>
                            <option value="Bronze">Bronze</option>
                            <option value="Cadet Grey">Cadet Grey</option>
                            <option value="Bisque">Bisque</option>
                            <option value="Cape Cod Sand">Cape Cod Sand</option>
                            <option value="Spice">Spice</option>
                            <option value="Dark Amber">Dark Amber</option>
                            <option value="Eggplant">Eggplant</option>
                            <option value="Fawn">Fawn</option>
                            <option value="Goldenrod">Goldenrod</option>
                            <option value="Heathered Sand">Heathered Sand</option>
                            <option value="Heathered Slate">Heathered Slate</option>
                            <option value="Heathered Walnut">Heathered Walnut</option>
                            <option value="Honey">Honey</option>
                            <option value="Hibiscus">Hibiscus</option>
                            <option value="White Marigold">White Marigold</option>
                            <option value="Symphony Blue">Symphony Blue</option>
                            <option value="Pastel Pink">Pastel Pink</option>
                            <option value="Cobalt">Cobalt</option>
                            <option value="Copper Fox">Copper Fox</option>
                            <option value="Agave">Agave</option>
                            <option value="Blackout">Blackout</option>
                            <option value="Light Grey">Light Grey</option>
                            <option value="Oat">Oat</option>
                            <option value="Stone">Stone</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_2358bf69-568a-a491-9b2b-73afd61ce64b-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Choose paper type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            data-name="Field 3"
                            name="field-3"
                            id="field"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="Museum Etching">Museum Etching</option>
                            <option value="Canson Rag">Canson Rag</option>
                            <option value="Platine">Platine</option>
                            <option value="Ultra Smooth">Ultra Smooth</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_2358bf69-568a-a491-9b2b-73afd61ce65e-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Slipcase Option</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            data-name="Field 3"
                            name="field-3"
                            id="field"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_2358bf69-568a-a491-9b2b-73afd61ce671-e99303ca"
                    className="input-container quantity _2"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Slipcase Fabric</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Willowbrook">Willowbrook</option>
                            <option value="Bronze">Bronze</option>
                            <option value="Cadet Grey">Cadet Grey</option>
                            <option value="Bisque">Bisque</option>
                            <option value="Cape Cod Sand">Cape Cod Sand</option>
                            <option value="Spice">Spice</option>
                            <option value="Dark Amber">Dark Amber</option>
                            <option value="Eggplant">Eggplant</option>
                            <option value="Fawn">Fawn</option>
                            <option value="Goldenrod">Goldenrod</option>
                            <option value="Heathered Sand">Heathered Sand</option>
                            <option value="Heathered Slate">Heathered Slate</option>
                            <option value="Heathered Walnut">Heathered Walnut</option>
                            <option value="Honey">Honey</option>
                            <option value="Hibiscus">Hibiscus</option>
                            <option value="White Marigold">White Marigold</option>
                            <option value="Symphony Blue">Symphony Blue</option>
                            <option value="Pastel Pink">Pastel Pink</option>
                            <option value="Cobalt">Cobalt</option>
                            <option value="Copper Fox">Copper Fox</option>
                            <option value="Agave">Agave</option>
                            <option value="Blackout">Blackout</option>
                            <option value="Light Grey">Light Grey</option>
                            <option value="Oat">Oat</option>
                            <option value="Stone">Stone</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_2358bf69-568a-a491-9b2b-73afd61ce680-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>Debossing or Foil Stamping</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Blind Debossing">Blind Debossing</option>
                            <option value="Gold Foil Stamping">Gold Foil Stamping</option>
                            <option value="Silver Foil Stamping">Silver Foil Stamping</option>
                            <option value="None">None</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_2358bf69-568a-a491-9b2b-73afd61ce693-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>Debossing / Foil Stamping text</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <input
                            type="text"
                            className="text-field-2 w-input"
                            maxLength="256"
                            name="field-7"
                            data-name="Field 7"
                            placeholder="Example Text"
                            id="field-7"
                            required=""
                          />
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_053ead28-a5cf-ed41-e683-ee987435afc1-e99303ca"
                    className="line-horizontal margins"
                  />
                </div>
              </div>
            </div>
            <div id="show-museum" className="wrapper-dynamic-museum">
              <div id="frame" className="frame">
                <div className="w-layout-grid grid-dynamic _1fr">
                  <div
                    id="w-node-_00e9807a-0460-a2c4-bc42-90793162c716-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">
                          Choose <strong>Museum Case Size</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            data-name="Field 3"
                            name="field-3"
                            id="field"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="8x10">8x10</option>
                            <option value="11x14">11x14</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_1fd0f7f7-c3e9-c5ed-ee4e-ad9a54b0cfbb-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">
                          <strong>Select Paper Type</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            data-name="Field 3"
                            name="field-3"
                            id="field"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="Museum Etching">Museum Etching</option>
                            <option value="Canson Rag">Canson Rag</option>
                            <option value="Platine">Platine</option>
                            <option value="Ultra Smooth">Ultra Smooth</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_57a6f914-91af-3cf9-32fd-e96a03a2ca3f-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">
                          <strong>Select Cover Fabric</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Willowbrook">Willowbrook</option>
                            <option value="Bronze">Bronze</option>
                            <option value="Cadet Grey">Cadet Grey</option>
                            <option value="Bisque">Bisque</option>
                            <option value="Cape Cod Sand">Cape Cod Sand</option>
                            <option value="Spice">Spice</option>
                            <option value="Dark Amber">Dark Amber</option>
                            <option value="Eggplant">Eggplant</option>
                            <option value="Fawn">Fawn</option>
                            <option value="Goldenrod">Goldenrod</option>
                            <option value="Heathered Sand">Heathered Sand</option>
                            <option value="Heathered Slate">Heathered Slate</option>
                            <option value="Heathered Walnut">Heathered Walnut</option>
                            <option value="Honey">Honey</option>
                            <option value="Hibiscus">Hibiscus</option>
                            <option value="White Marigold">White Marigold</option>
                            <option value="Symphony Blue">Symphony Blue</option>
                            <option value="Pastel Pink">Pastel Pink</option>
                            <option value="Cobalt">Cobalt</option>
                            <option value="Copper Fox">Copper Fox</option>
                            <option value="Agave">Agave</option>
                            <option value="Blackout">Blackout</option>
                            <option value="Light Grey">Light Grey</option>
                            <option value="Oat">Oat</option>
                            <option value="Stone">Stone</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-e6a33486-2795-8eef-1afb-9b91b5756637-e99303ca"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">
                          <strong>Select Tray Fabric</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Willowbrook">Willowbrook</option>
                            <option value="Bronze">Bronze</option>
                            <option value="Cadet Grey">Cadet Grey</option>
                            <option value="Bisque">Bisque</option>
                            <option value="Cape Cod Sand">Cape Cod Sand</option>
                            <option value="Spice">Spice</option>
                            <option value="Dark Amber">Dark Amber</option>
                            <option value="Eggplant">Eggplant</option>
                            <option value="Fawn">Fawn</option>
                            <option value="Goldenrod">Goldenrod</option>
                            <option value="Heathered Sand">Heathered Sand</option>
                            <option value="Heathered Slate">Heathered Slate</option>
                            <option value="Heathered Walnut">Heathered Walnut</option>
                            <option value="Honey">Honey</option>
                            <option value="Hibiscus">Hibiscus</option>
                            <option value="White Marigold">White Marigold</option>
                            <option value="Symphony Blue">Symphony Blue</option>
                            <option value="Pastel Pink">Pastel Pink</option>
                            <option value="Cobalt">Cobalt</option>
                            <option value="Copper Fox">Copper Fox</option>
                            <option value="Agave">Agave</option>
                            <option value="Blackout">Blackout</option>
                            <option value="Light Grey">Light Grey</option>
                            <option value="Oat">Oat</option>
                            <option value="Stone">Stone</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-d92e5993-b8ab-037c-86aa-a1ec3065fbe2-e99303ca"
                    className="input-container quantity _2"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">number of Matted Prints</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                          </select>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_0f95db77-8f13-6910-458a-2681060f957e-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>Debossing or Foil Stamping</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Blind Debossing">Blind Debossing</option>
                            <option value="Gold Foil Stamping">Gold Foil Stamping</option>
                            <option value="Silver Foil Stamping">Silver Foil Stamping</option>
                            <option value="None">None</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_3bab8644-c6dd-c3d8-e3d3-c81843fbfff8-e99303ca"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <strong>Debossing / Foil Stamping text</strong>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <input
                            type="text"
                            className="text-field-2 w-input"
                            maxLength="256"
                            name="field-7"
                            data-name="Field 7"
                            placeholder="Example Text"
                            id="field-7"
                            required=""
                          />
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-matting" className="wrapper-dynamic-matting">
              <div className="w-layout-grid grid-dynamic">
                <div className="input-container">
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">format</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="4 Ply">4 Ply</option>
                          <option value="8 Ply">8 Ply</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_79897ac1-1d3e-128c-1fa7-d5c7764520c4-e99303ca"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Mat size</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="11x14 ($14.00)">11x14 ($14.00)</option>
                          <option value="11x13 ($14.00)">11x13 ($14.00)</option>
                          <option value="12x15 ($20.00)">12x15 ($20.00)</option>
                          <option value="13x13 ($20.00)">13x13 ($20.00)</option>
                          <option value="14x14 ($20.00)">14x14 ($20.00)</option>
                          <option value="11x15 ($20.00)">11x15 ($20.00)</option>
                          <option value="14x17 ($25.00)">14x17 ($25.00)</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-mounting" className="wrapper-dynamic-mounting">
              <div className="w-layout-grid grid-dynamic">
                <div className="input-container">
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Mount size</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="7x10 mount ($9.00)">7x10 mount ($9.00)</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-eaf3fe18-d18d-b6ac-8d17-cfe23919bc4d-e99303ca"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Mount color</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Black $(0.00)">Black $(0.00)</option>
                          <option value="White $(0.00)">White $(0.00)</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-standard" className="standard-frame">
              <div className="w-layout-grid grid-dynamic _1fr">
                <div
                  id="w-node-_0f289548-9dd2-1946-e3e5-62552baefdd4-e99303ca"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Adelaide">Adelaide</option>
                          <option value="Albany">Albany</option>
                          <option value="Athens">Athens</option>
                          <option value="Aurora">Aurora</option>
                          <option value="Bristol">Bristol</option>
                          <option value="Charleston">Charleston</option>
                          <option value="Charlotte">Charlotte</option>
                          <option value="Chelsea">Chelsea</option>
                          <option value="Columbia">Columbia</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_0f289548-9dd2-1946-e3e5-62552baefde6-e99303ca"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame Mat</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="No Mat $(125.00)">No Mat $(125.00)</option>
                          <option value='2" Mat $(125.00)'>2&quot; Mat $(125.00)</option>
                          <option value='4" mat $(125.00)'>4&quot; mat $(125.00)</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_0f289548-9dd2-1946-e3e5-62552baefdf8-e99303ca"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">acrylic</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-premium" className="premium-frame">
              <div className="w-layout-grid grid-dynamic _1fr">
                <div
                  id="w-node-_912a507f-5e0c-80e6-e86c-639726716a16-e99303ca"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame wood</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="frame-wood"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="maple">Maple 3/4&quot; PROFILE</option>
                          <option value='Maple 1.5" PROFILE'>Maple 1.5&quot; PROFILE</option>
                          <option value='Ash 3/4" PROFILE'>Ash 3/4&quot; PROFILE</option>
                          <option value='Ash 1.5" PROFILE'>Ash 1.5&quot; PROFILE</option>
                          <option value='Cherry 3/4" PROFILE'>Cherry 3/4&quot; PROFILE</option>
                          <option value='Cherry 1.5" PROFILE'>Cherry 1.5&quot; PROFILE</option>
                          <option value='Walnut 3/4" PROFILE'>Walnut 3/4&quot; PROFILE</option>
                          <option value='Walnut 1.5" PROFILE'>Walnut 1.5&quot; PROFILE</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_912a507f-5e0c-80e6-e86c-639726716a28-e99303ca"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame Mat</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="No Mat $(125.00)">No Mat $(125.00)</option>
                          <option value='2" Mat $(125.00)'>2&quot; Mat $(125.00)</option>
                          <option value='4" mat $(125.00)'>4&quot; mat $(125.00)</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_912a507f-5e0c-80e6-e86c-639726716a3a-e99303ca"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">acrylic</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-c1c7bde4-8fac-4ab8-4dc4-37f5d4782818-e99303ca"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame stain</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Rising White">Rising White</option>
                          <option value="Whitewash">Whitewash</option>
                          <option value="Satin Black">Satin Black</option>
                          <option value="Dark Grey">Dark Grey</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="line-horizontal" />
            <div data-ix="fade-up-1" className="accordion js-accordion">
              <div className="accordion__item js-accordion-item">
                <div className="accordion-header js-accordion-header">
                  <div className="accordion-item-2">
                    <div className="icon small x2 right-margin w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M128,23.99805a104,104,0,1,0,104,104A104.12041,104.12041,0,0,0,128,23.99805Zm40,112H136v32a8,8,0,1,1-16,0v-32H88a8,8,0,0,1,0-16h32v-32a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
                      </svg>
                    </div>
                    <div>Add Notes</div>
                  </div>
                </div>
                <div data-ix="hide-on-load" className="accordion-body js-accordion-body">
                  <div className="accordion-item-content">
                    <div className="input-container add-notes">
                      <div className="label-container">
                        <div className="div-block-150">
                          <div className="sub-label-form">Editing notes for:</div>
                          <div data-hover="false" data-delay="0" className="dropdown-6 w-dropdown">
                            <div className="dropdown-toggle-3 w-dropdown-toggle">
                              <div className="sub-label-form all-photos">current photo:</div>
                              <div className="icon-3 w-icon-dropdown-toggle" />
                            </div>
                            <nav className="dropdown-list-3 w-dropdown-list">
                              <div className="sub-label-form unselected">all photos</div>
                            </nav>
                          </div>
                          <div className="icon mini margin-left opacity70 w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="input-wrapper"
                        >
                          <textarea
                            placeholder="Example Text"
                            maxLength="5000"
                            id="field-6"
                            name="field-6"
                            data-name="field"
                            className="text-field-2 area w-input"
                          />
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-controls-product-selection-sidebar">
          <div className="controls-block-grid">
            <div id="w-node-ef53a1b5-32fb-b097-4006-9c6a244116ab-5b83c88b">Subtotal:</div>
            <div className="text-block-32">$ 142.00</div>
          </div>
          <a href="/product-selection" className="container-cart-button w-inline-block">
            <div className="div-block-186">
              <div className="icon right-margin w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M223.87109,65.43115l-12.15722,66.86231A23.98711,23.98711,0,0,1,188.10156,152H72.13135l4.36377,24H184a24.01161,24.01161,0,1,1-22.624,16H102.624a24.00309,24.00309,0,1,1-40.85986-7.57666L34.05078,32H16a8,8,0,0,1,0-16H34.05078A15.9918,15.9918,0,0,1,49.793,29.13818L54.67676,56H216a8.00076,8.00076,0,0,1,7.87109,9.43115Z" />
                </svg>
              </div>
              <div>Add To Cart</div>
            </div>
            <div className="div-block-187">
              <div className="div-block-188">
                <div>3</div>
              </div>
            </div>
          </a>
          <a href="/product-selection" className="button-2 w-inline-block">
            <div className="icon right-margin w-embed">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                <rect width="256" height="256" fill="none" />
                <path d="M247.31055,124.75061c-.35157-.79-8.81934-19.57617-27.65332-38.41113C194.57324,61.25256,162.87793,47.99182,128,47.99182S61.42676,61.25256,36.34277,86.33948c-18.834,18.835-27.30175,37.62109-27.65332,38.41113a8.00282,8.00282,0,0,0,0,6.49805c.35157.791,8.82032,19.57226,27.6543,38.40429C61.42773,194.734,93.12207,207.99182,128,207.99182S194.57227,194.734,219.65625,169.653c18.834-18.832,27.30273-37.61328,27.6543-38.40429A8.00282,8.00282,0,0,0,247.31055,124.75061ZM128,92.00061a36,36,0,1,1-36,36A36.04061,36.04061,0,0,1,128,92.00061Z" />
              </svg>
            </div>
            <div>Review Order</div>
          </a>
        </div>
      </div>
      <div id="show-albums" className="product-selection-picture-wrapper">
        <div className="product-selection-picture-container">
          <div className="product-selection-main-picture-container-copy no-padding">
            <div data-w-id="078df048-e7d3-edf6-2f41-28c3bd6180cb" className="div-block-346">
              <img
                src="images/three-5x7---12x23-mat.png"
                loading="lazy"
                sizes="(max-width: 479px) 67vw, 55vw"
                srcSet="images/three-5x7---12x23-mat-p-500.png 500w, images/three-5x7---12x23-mat-p-800.png 800w, images/three-5x7---12x23-mat-p-1080.png 1080w, images/three-5x7---12x23-mat-p-1600.png 1600w, images/three-5x7---12x23-mat-p-2000.png 2000w, images/three-5x7---12x23-mat-p-2600.png 2600w, images/three-5x7---12x23-mat-p-3200.png 3200w, images/three-5x7---12x23-mat.png 6900w"
                alt=""
                className="image-93"
              />
              <div className="div-block-347">
                <img
                  src="images/71vtBVOn2L.jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 19vw, 16vw"
                  srcSet="images/71vtBVOn2L-p-500.jpeg 500w, images/71vtBVOn2L-p-1080.jpeg 1080w, images/71vtBVOn2L.jpg 1200w"
                  alt=""
                  className="image-92"
                />
                <img
                  src="images/89e889e6df64e44fe71ccd3de433b378.jpeg"
                  loading="lazy"
                  sizes="(max-width: 479px) 19vw, 16vw"
                  srcSet="images/89e889e6df64e44fe71ccd3de433b378-p-500.jpeg 500w, images/89e889e6df64e44fe71ccd3de433b378-p-800.jpeg 800w, images/89e889e6df64e44fe71ccd3de433b378-p-1080.jpeg 1080w, images/89e889e6df64e44fe71ccd3de433b378.jpeg 1470w"
                  alt=""
                  className="image-92"
                />
                <img
                  src="images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 19vw, 16vw"
                  srcSet="images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-800.jpeg 800w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-1080.jpeg 1080w, images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg 1514w"
                  alt=""
                  className="image-92"
                />
              </div>
              <div style={{ opacity: 0 }} className="div-block-348">
                <div className="icon small x2 w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                    <rect width="256" height="256" fill="none"></rect>
                    <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-controls-product-selection">
            <div className="bottom-controls-product-selection-grid">
              <div
                data-tippy-content="Currently Selected"
                className="picture-element-product-selection-2 drag-drop tippy"
              >
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/71vtBVOn2L.jpg"
                    loading="lazy"
                    sizes="90px"
                    srcSet="images/71vtBVOn2L-p-500.jpeg 500w, images/71vtBVOn2L-p-1080.jpeg 1080w, images/71vtBVOn2L.jpg 1200w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f37514d-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f375151-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                >
                  <div className="check-symbol-2">
                    <div className="icon small x2 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline
                          points="216 72.005 104 184 48 128.005"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-tippy-content="Currently Selected"
                className="picture-element-product-selection-2 selected drag-drop"
              >
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/89e889e6df64e44fe71ccd3de433b378.jpeg"
                    loading="lazy"
                    sizes="90px"
                    srcSet="images/89e889e6df64e44fe71ccd3de433b378-p-500.jpeg 500w, images/89e889e6df64e44fe71ccd3de433b378-p-800.jpeg 800w, images/89e889e6df64e44fe71ccd3de433b378-p-1080.jpeg 1080w, images/89e889e6df64e44fe71ccd3de433b378.jpeg 1470w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f375157-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f37515b-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                >
                  <div className="check-symbol-2">
                    <div className="icon small x2 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline
                          points="216 72.005 104 184 48 128.005"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-tippy-content="Currently Selected"
                className="picture-element-product-selection-2 drag-drop"
              >
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg"
                    loading="lazy"
                    sizes="90px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-800.jpeg 800w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-1080.jpeg 1080w, images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg 1514w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f375161-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f375165-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                >
                  <div className="check-symbol-2">
                    <div className="icon small x2 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline
                          points="216 72.005 104 184 48 128.005"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-tippy-content="Currently Selected"
                className="picture-element-product-selection-2 drag-drop"
              >
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="90px"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f37516b-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f37516f-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div
                data-tippy-content="Currently Selected"
                className="picture-element-product-selection-2 drag-drop"
              >
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f375175-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f375179-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div
                data-tippy-content="Currently Selected"
                className="picture-element-product-selection-2 drag-drop"
              >
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f37517f-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f375183-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2 drag-drop">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f3751d1-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-c82de2b5-2a1f-6668-772a-5d534f3751d5-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2 drag-drop">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_78ebe911-9cad-f776-73bf-1570c0bc4a49-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_78ebe911-9cad-f776-73bf-1570c0bc4a4d-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2 drag-drop">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_38731e7b-19b0-6e84-7ad9-1f75dddae12f-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_38731e7b-19b0-6e84-7ad9-1f75dddae133-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2 drag-drop">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_78f99f55-6403-ef56-0e9f-c0c38a055465-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_78f99f55-6403-ef56-0e9f-c0c38a055469-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2 drag-drop">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_15892d09-5bf7-4a43-3e66-ef10e4c7cbdc-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_15892d09-5bf7-4a43-3e66-ef10e4c7cbe0-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2 drag-drop">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_8b0e473f-3e0b-79d6-4ce3-12c2ce9b00d1-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_8b0e473f-3e0b-79d6-4ce3-12c2ce9b00d5-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
            </div>
            <div className="side-controls-product-selection">
              <div className="w-layout-grid grid-44">
                <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                  <div className="div-block-169-copy-2 w-dropdown-toggle">
                    <div className="icon small w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline
                          points="144 167.994 184 207.994 223.999 168"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="184"
                          y1="111.99347"
                          x2="184"
                          y2="207.99347"
                          fill="none"
                          stroke="#currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="48"
                          y1="127.99353"
                          x2="119.99902"
                          y2="127.99353"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="48"
                          y1="63.99353"
                          x2="183.99902"
                          y2="63.99353"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="48"
                          y1="191.99353"
                          x2="104"
                          y2="191.99353"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                  </div>
                  <nav className="dropdown-list production w-dropdown-list">
                    <div className="form-block-filter w-form">
                      <form
                        id="email-form-3"
                        name="email-form-3"
                        data-name="Email Form 3"
                        method="get"
                        className="form-3"
                      >
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input"></div>
                          <input
                            type="radio"
                            data-name="Radio 10"
                            id="radio-10"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-10">
                            Ascending (A-Z)
                          </span>
                        </label>
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input"></div>
                          <input
                            type="radio"
                            data-name="Radio 10"
                            id="radio-10"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-10">
                            Descending (Z-A)
                          </span>
                        </label>
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input"></div>
                          <input
                            type="radio"
                            data-name="Radio 10"
                            id="radio-10"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-10">
                            Newest to Oldest
                          </span>
                        </label>
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input"></div>
                          <input
                            type="radio"
                            data-name="Radio 11"
                            id="radio-11"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-11">
                            Oldest to Newest
                          </span>
                        </label>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="rotate-button-2">
                  <div className="icon small w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M43.99951,143.27273V52a8,8,0,0,1,8-8h152a8,8,0,0,1,8,8V204a8,8,0,0,1-8,8h-68.3641"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <polyline
                        points="128 152 64 216 32 184"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="show-album" className="photo-album-container grid">
          <div className="div-block-324">
            <div className="div-block-325">
              <div>
                <div className="sub-heading-container-2 both-edges no-margin">
                  <div className="icon right-margin w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M128,124V92a20,20,0,0,0-40,0v56"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <path
                        d="M168,124V92a20,20,0,0,0-40,0v32"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <path
                        d="M88,148V116H68a20,20,0,0,0-20,20v16a80,80,0,0,0,160,0V108a20,20,0,0,0-40,0v16"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <h2 className="heading-title-2-2-copy">Drag to reorder your album</h2>
                </div>
              </div>
              <div className="div-block-326">
                <div className="w-layout-grid grid-23 main _2 _100 grey">
                  <div className="green">
                    <div className="icon small x2 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M236.74414,187.96585l-87.96875-152a24.012,24.012,0,0,0-41.54687,0v.00782L19.25977,187.96585a23.9982,23.9982,0,0,0,20.76562,36.02344H215.97852a23.9982,23.9982,0,0,0,20.76562-36.02344Zm-116.75-83.96875a8,8,0,1,1,16,0v40a8,8,0,1,1-16,0ZM128,192a12,12,0,1,1,12-12A12.00059,12.00059,0,0,1,128,192Z" />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <span className="green">24/24</span> pictures uploaded
                  </div>
                </div>
                <a href="/product-selection-copy" className="button-2 w-inline-block">
                  <div>Save Album Order</div>
                </a>
              </div>
            </div>
            <div className="div-block-327">
              <div id="left" className="dragula-container">
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-7.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/Stephen-Shore-Uncommon-Places-7-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-7.jpg 960w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div
                  id="w-node-d950a422-2aa7-0312-ed0a-5562cf071495-e99303ca"
                  className="c-card__one"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/stephen-shore-4.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/stephen-shore-4-p-500.jpeg 500w, images/stephen-shore-4-p-1080.jpeg 1080w, images/stephen-shore-4.jpg 1600w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/89e889e6df64e44fe71ccd3de433b378.jpeg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/89e889e6df64e44fe71ccd3de433b378-p-500.jpeg 500w, images/89e889e6df64e44fe71ccd3de433b378-p-800.jpeg 800w, images/89e889e6df64e44fe71ccd3de433b378-p-1080.jpeg 1080w, images/89e889e6df64e44fe71ccd3de433b378.jpeg 1470w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/71vtBVOn2L.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/71vtBVOn2L-p-500.jpeg 500w, images/71vtBVOn2L-p-1080.jpeg 1080w, images/71vtBVOn2L.jpg 1200w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/7-1.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/7-1-p-500.jpeg 500w, images/7-1-p-1080.jpeg 1080w, images/7-1-p-1600.jpeg 1600w, images/7-1.jpg 1607w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/89e889e6df64e44fe71ccd3de433b378.jpeg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/89e889e6df64e44fe71ccd3de433b378-p-500.jpeg 500w, images/89e889e6df64e44fe71ccd3de433b378-p-800.jpeg 800w, images/89e889e6df64e44fe71ccd3de433b378-p-1080.jpeg 1080w, images/89e889e6df64e44fe71ccd3de433b378.jpeg 1470w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/89e889e6df64e44fe71ccd3de433b378.jpeg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/89e889e6df64e44fe71ccd3de433b378-p-500.jpeg 500w, images/89e889e6df64e44fe71ccd3de433b378-p-800.jpeg 800w, images/89e889e6df64e44fe71ccd3de433b378-p-1080.jpeg 1080w, images/89e889e6df64e44fe71ccd3de433b378.jpeg 1470w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
                <div className="c-card__one">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="img-dragable"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-controls-product-selection no-margin white">
            <div className="bottom-controls-product-selection-grid padding-top-5px">
              <div className="picture-element-product-selection-2 selected">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/71vtBVOn2L.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 90px, 100vw"
                    srcSet="images/71vtBVOn2L-p-500.jpeg 500w, images/71vtBVOn2L-p-1080.jpeg 1080w, images/71vtBVOn2L.jpg 1200w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946854-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946858-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                >
                  <div className="cart-symbol-2">
                    <div className="icon small x2 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M96,216a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,96,216Zm88-16a16,16,0,1,0,16,16A16.00016,16.00016,0,0,0,184,200ZM228.10059,67.18262A7.99866,7.99866,0,0,0,221.71387,64H48.32031L40.207,35.604A16.06984,16.06984,0,0,0,24.82227,24H8A8,8,0,0,0,8,40H24.82227l9.73046,34.05469c.02637.09863.05372.19677.084.29443l26.35547,92.24365A24.10087,24.10087,0,0,0,84.06836,184h95.86328a24.101,24.101,0,0,0,23.07617-17.40674l26.39844-92.39551A8.00023,8.00023,0,0,0,228.10059,67.18262Z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="picture-element-product-selection-2">
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94685c-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                >
                  <div className="cart-symbol-2">
                    <div className="icon small x2 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M96,216a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,96,216Zm88-16a16,16,0,1,0,16,16A16.00016,16.00016,0,0,0,184,200ZM228.10059,67.18262A7.99866,7.99866,0,0,0,221.71387,64H48.32031L40.207,35.604A16.06984,16.06984,0,0,0,24.82227,24H8A8,8,0,0,0,8,40H24.82227l9.73046,34.05469c.02637.09863.05372.19677.084.29443l26.35547,92.24365A24.10087,24.10087,0,0,0,84.06836,184h95.86328a24.101,24.101,0,0,0,23.07617-17.40674l26.39844-92.39551A8.00023,8.00023,0,0,0,228.10059,67.18262Z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/89e889e6df64e44fe71ccd3de433b378.jpeg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 90px, 100vw"
                    srcSet="images/89e889e6df64e44fe71ccd3de433b378-p-500.jpeg 500w, images/89e889e6df64e44fe71ccd3de433b378-p-800.jpeg 800w, images/89e889e6df64e44fe71ccd3de433b378-p-1080.jpeg 1080w, images/89e889e6df64e44fe71ccd3de433b378.jpeg 1470w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946861-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 90px, 100vw"
                    srcSet="images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-800.jpeg 800w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-1080.jpeg 1080w, images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg 1514w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946868-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94686c-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 90px, 100vw"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946870-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946874-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946878-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94687c-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946880-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946884-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946888-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94688c-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946890-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946894-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946898-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94689c-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468a0-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468a4-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468a8-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468ac-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468b0-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468b4-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468b8-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468bc-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468c0-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468c4-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468c8-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468cc-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a
                  href="/product-selection-copy"
                  className="lightbox-link w-inline-block w-lightbox"
                >
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468d0-e99303ca"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468d4-e99303ca"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
            </div>
            <div className="side-controls-product-selection white-gradient">
              <div className="w-layout-grid grid-44">
                <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                  <div className="div-block-169-copy-2 w-dropdown-toggle">
                    <div className="icon small w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline
                          points="144 167.994 184 207.994 223.999 168"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="184"
                          y1="111.99347"
                          x2="184"
                          y2="207.99347"
                          fill="none"
                          stroke="#currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="48"
                          y1="127.99353"
                          x2="119.99902"
                          y2="127.99353"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="48"
                          y1="63.99353"
                          x2="183.99902"
                          y2="63.99353"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="48"
                          y1="191.99353"
                          x2="104"
                          y2="191.99353"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                  </div>
                  <nav className="dropdown-list production w-dropdown-list">
                    <div className="form-block-filter w-form">
                      <form
                        id="email-form-3"
                        name="email-form-3"
                        data-name="Email Form 3"
                        method="get"
                        className="form-3"
                      >
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input"></div>
                          <input
                            type="radio"
                            data-name="Radio 5"
                            id="radio-5"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-5">
                            Ascending (A-Z)
                          </span>
                        </label>
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input"></div>
                          <input
                            type="radio"
                            data-name="Radio 5"
                            id="radio-5"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-5">
                            Descending (Z-A)
                          </span>
                        </label>
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input"></div>
                          <input
                            type="radio"
                            data-name="Radio 6"
                            id="radio-6"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-6">
                            Newest to Oldest
                          </span>
                        </label>
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input"></div>
                          <input
                            type="radio"
                            data-name="Radio 7"
                            id="radio-7"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-7">
                            Oldest to Newest
                          </span>
                        </label>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="rotate-button-2">
                  <div className="icon small w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M43.99951,143.27273V52a8,8,0,0,1,8-8h152a8,8,0,0,1,8,8V204a8,8,0,0,1-8,8h-68.3641"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <polyline
                        points="128 152 64 216 32 184"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSelectionCopy
