import { ReactComponent as AlertIcon } from 'images/icons/alert.svg'
import MuseaLogo from 'images/Green_Musea_Logo.svg'

const AccountPendingPage = () => (
  <div className="container-full-screen">
    <a href="/" className="brand-2 w-nav-brand">
      <img src={MuseaLogo} loading="lazy" alt="MuseaLab" className="image-2-copy" />
      <div className="text-block-copy">
        <span className="text-span-2">MUSEA</span> Lab
      </div>
    </a>
    <div className="divider _24px" />
    <div className="divider _24px" />

    <div className="white-block">
      <div className="icon big w-embed">
        <AlertIcon />
      </div>
      <div className="divider" />
      <h2 className="heading-title">
        Your account is
        <br />
        currently under review.
      </h2>
      <div className="divider _24px" />
      <div>You cannot place orders with Musea until the team approves your account.</div>
    </div>

    <div className="div-block-293">
      <div>
        If it&apos;s been over 48 hours,
        <br />
        please reach out to{' '}
        <a href="mailto:lab@mymusea.com" className="link-4">
          lab@mymusea.com
        </a>{' '}
        with any questions.
      </div>
      <div className="divider _24px" />
      <a href="/" className="button-2 secondary no-width w-inline-block">
        <div>Go Back Home</div>
      </a>
    </div>
  </div>
)

export default AccountPendingPage
