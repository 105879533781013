import cn from 'classnames'

const Tab = ({ children, onClick, isActive }) => (
  <div
    className={cn('tab-photographers w-inline-block w-tab-link', {
      'w--current': isActive,
    })}
    id="w-tabs-0-data-w-tab-1"
    role="tab"
    aria-selected={isActive}
    tabIndex={isActive ? -1 : 0}
    onClick={onClick}
  >
    {children}
  </div>
)

export default Tab
