import { useCallback, useState } from 'react'
import sanitize from 'sanitize-filename'
import { useDropzone } from 'react-dropzone'

import httpClient from 'httpClient'
import { ReactComponent as AlertIcon } from 'images/icons/alert.svg'
import { ReactComponent as CheckRoundedIcon } from 'images/icons/check-rounded.svg'
import { ReactComponent as DownloadIcon } from 'images/icons/download.svg'
import { apiErrorMessage, getFileNameFromUrl } from 'lib/utils'

import ErrorMessage from 'components/common/ErrorMessage'

const BlanketCertificateResale = ({ user, refetchUser }) => {
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleCertificateUpload = useCallback(async (file) => {
    const fileName = sanitize(file.name)

    setApiError(null)
    try {
      setLoading(true)
      const { signedUrl } = await httpClient.post(
        `${process.env.REACT_APP_API_URL}lab_orders/${user?.guid}/create_presigned_url`,
        { guid: user?.guid, file_name: fileName },
      )

      await httpClient.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      await httpClient.patch(
        `${process.env.REACT_APP_API_URL}lab_orders/${user?.guid}/photograph_uploaded`,
        { guid: user?.guid, file_name: fileName },
      )
      await refetchUser()
    } catch (error) {
      setApiError(apiErrorMessage(error))
      setLoading(false)
    }
  }, [])

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => handleCertificateUpload(file))
  }, [])

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: '.pdf',
    maxFiles: 1,
    onDrop,
  })

  return (
    <div
      id="w-node-_186b4661-3441-ebed-799a-637652a70b1f-0ce80a13"
      className="w-layout-grid grid-info-order"
    >
      <div className="div-block-108-copy">
        <div className="text-details-container vertical">
          <div className="text-bold">Blanket Certificate Of Resale</div>
        </div>
      </div>
      <div className="w-layout-grid grid-23 main _2 grey">
        <div className="icon smaller w-embed">
          <AlertIcon />
        </div>
        <div className="sub-label-form one-line grey">Only if your business is in Tennessee</div>
      </div>

      <div
        role="button"
        {...getRootProps()}
        onClick={open}
        onKeyPress={open}
        id="w-node-_2d27da4f-5783-351f-ea8e-feab8ac7cc4d-0ce80a13"
        className="button-2 secondary w-inline-block"
      >
        <input {...getInputProps()} />
        <div>{loading ? 'Uploading...' : 'Upload'}</div>
      </div>
      {apiError && <ErrorMessage errors={apiError} />}
      {user?.blanketCertificateOfResaleLink && (
        <div className="div-center-element _100">
          <div className="w-layout-grid grid-23 main _2 _12px">
            <div className="check-state">
              <div className="icon small x2 w-embed">
                <CheckRoundedIcon />
              </div>
            </div>
            <div>{getFileNameFromUrl(user?.blanketCertificateOfResaleLink)}</div>
          </div>
          <div className="mini-button-gray">
            <a
              href={user?.blanketCertificateOfResaleLink}
              className="icon small x2 w-embed"
              style={{ color: '#ffffff' }}
            >
              <DownloadIcon />
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default BlanketCertificateResale
