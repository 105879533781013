/* eslint-disable jsx-a11y/control-has-associated-label */
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { useOutsideClickHandler, useToggle } from 'hooks'
import { USER_STATUS } from 'lib/const'
import { capitalizeFirstLetter } from 'lib/utils'

import { ReactComponent as AccountIcon } from 'images/icons/NavDrawer/account.svg'
import { ReactComponent as WorldIcon } from 'images/icons/world.svg'
import { ReactComponent as CheckRoundedIcon } from 'images/icons/check-rounded.svg'
import { ReactComponent as DownloadIcon } from 'images/icons/dowload-alternative.svg'
import { ReactComponent as LoginIcon } from 'images/icons/login.svg'
import { ReactComponent as UpdateIcon } from 'images/icons/update.svg'
import { ReactComponent as ChevronDownIcon } from 'images/icons/chevron-down.svg'

// photographers

const PhotographersDatabaseRow = ({ user }) => {
  const ref = useRef()
  const [showMoreOptions, toggleShowMoreOptions] = useToggle(false)

  useOutsideClickHandler(ref, () => showMoreOptions && toggleShowMoreOptions(false))

  return (
    <div className="w-layout-grid grid-table-heady">
      <div className="content name">
        <div>
          <div className="w-layout-grid grid-23">
            <div className="text-table name">{`${user?.firstName} ${user?.lastName}`}</div>
            <Link to="/photographer-profile" className="link-block-7 w-inline-block">
              <div className="icon small x2 w-embed">
                <AccountIcon />
              </div>
            </Link>
            <Link to="/" className="link-block-7 w-inline-block">
              <div className="icon small x2 w-embed">
                <WorldIcon />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="text-table">{user?.email}</div>
      </div>
      <div className="content">
        <div className="text-table-top-container">
          <div className="text-table">{user?.businessName ?? '-'}</div>
        </div>
      </div>
      <div className="content">
        <div className="text-table-top-container">
          <div className="text-table">?</div>
        </div>
      </div>
      <div className="content">
        <div className="text-table">?</div>
      </div>
      <div id="w-node-_79f08531-6afd-7b9d-2f32-6719dcb29f77-67e80a11" className="content">
        <div className="status-admin-photographer">
          <div className="icon small x2 right-margin less w-embed">
            <CheckRoundedIcon />
          </div>
          <div>Approved</div>
        </div>
      </div>
      <div id="w-node-_79f08531-6afd-7b9d-2f32-6719dcb29f7c-67e80a11" className="content">
        <div className="form-block-container-admin w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="form-7"
            style={{ position: 'relative' }}
          >
            <select
              id="field-5"
              name="field-5"
              data-name="Field 5"
              className="select-field-2 w-select"
            >
              {Object.keys(USER_STATUS).map((status) => (
                <option key={USER_STATUS[status]} value={USER_STATUS[status]}>
                  {capitalizeFirstLetter(USER_STATUS[status])}
                </option>
              ))}
            </select>
            <div className="div-block-63">
              <ChevronDownIcon className="img-icon" />
            </div>
          </form>
        </div>
      </div>
      <div id="w-node-_79f08531-6afd-7b9d-2f32-6719dcb29f7f-67e80a11" className="empty-div">
        <div data-delay="0" data-hover="false" className="overflow-menu__wrap-copy-copy w-dropdown">
          <div
            className="overflow-menu__toggle-copy frost-blur _2 w-dropdown-toggle"
            id="w-dropdown-toggle-0"
            aria-controls="w-dropdown-list-0"
            aria-haspopup="menu"
            aria-expanded="false"
            role="button"
            tabIndex="0"
            onClick={toggleShowMoreOptions}
          />
          <nav
            className={cn('overflow-menu-copy frost-blur-copy w-dropdown-list', {
              'w--open': showMoreOptions,
            })}
            id="w-dropdown-list-0"
            aria-labelledby="w-dropdown-toggle-0"
            ref={ref}
          >
            <Link
              data-w-id="d4a7e4ab-8a74-ee12-fc96-4fe7eaa21b20"
              to="/"
              className="overflow-menu__link w-inline-block"
              tabIndex="0"
            >
              <div className="icon small right-margin w-embed">
                <UpdateIcon />
              </div>
              <div>Update User</div>
            </Link>
            <Link
              data-w-id="79f08531-6afd-7b9d-2f32-6719dcb29f83"
              to="/"
              className="overflow-menu__link w-inline-block"
              tabIndex="0"
            >
              <div className="icon small right-margin w-embed">
                <LoginIcon />
              </div>
              <div>Login as User</div>
            </Link>
            {user?.blanketCertificateOfResaleLink && (
              <a
                href={user?.blanketCertificateOfResaleLink}
                className="overflow-menu__link w-inline-block"
                tabIndex="0"
              >
                <div className="icon small right-margin w-embed">
                  <DownloadIcon />
                </div>
                <div>Download Certificate</div>
              </a>
            )}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default PhotographersDatabaseRow
