
import chromeImage from '../../images/chrome.svg'
import productSpreadsheetImage from '../../images/file-2.svg'
import shopifyImage from '../../images/shopify-plus22-logo--black.png'
import shootproofImage from '../../images/descarga22.png'
import pixiesetImage from '../../images/Cirila2-Р-majuskla.svg.png'
import copyImage from '../../images/copy.svg'
import dollarSignImage from '../../images/dollar-sign.svg'
import trashImage from '../../images/trash-2.svg'
import toolImage from '../../images/tool.svg'

const ExternalStores = () => (
  <div className="main-wrapper scroll">
    <div className="w-layout-grid navbar-wrapper">
      <div className="w-layout-grid top-grid space-between">
        <div id="w-node-_1b06cc8c-1099-711e-2d5b-42f0252b2714-adbda994">
          <h3 className="heading-3">External Stores</h3>
        </div>
        <div
          id="w-node-_919c5dd5-b55e-3905-4218-c7d326eab2c8-adbda994"
          className="w-layout-grid grid-25-copy"
        >
          <a
            data-w-id="919c5dd5-b55e-3905-4218-c7d326eab2c9"
            href="/external-stores-list"
            className="upgrade-button w-inline-block"
          >
            <img
              src={chromeImage}
              loading="lazy"
              alt="Chrome Extension"
              className="imagen-icon mobile-margin"
            />
            <div className="text-block-30">Chrome Extension</div>
          </a>
          <a
            data-w-id="919c5dd5-b55e-3905-4218-c7d326eab2cd"
            href="/external-stores-list"
            className="upgrade-button w-inline-block"
          >
            <img
              src={productSpreadsheetImage}
              loading="lazy"
              alt="Products Spreadsheet"
              className="imagen-icon mobile-margin"
            />
            <div className="text-block-30">Products Spreadsheet</div>
          </a>
        </div>
      </div>
    </div>
    <div className="div-block-74">
      <div className="div-block-91-copy">
        <div
          href="/external-stores-list"
          data-w-id="b64327de-8039-2f52-4cd5-b11e404ba458"
          className="_2nd-button main"
        >
          <div className="icon-3 small right-margin w-embed">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
              <rect width="256" height="256" fill="none" />
              <polyline
                points="112 204 111.992 144.008 52 144"
                fill="none"
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              />
              <line
                x1="39.9714"
                y1="216.0286"
                x2="111.9714"
                y2="144.0286"
                fill="none"
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              />
              <path
                d="M160,192h48a8,8,0,0,0,8-8V48a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8V96"
                fill="none"
                stroke="currentcolor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              />
            </svg>
          </div>
          <div>Add External Store</div>
          <div style={{ display: 'none', opacity: 0 }} className="modal-wrapper-2">
            <div
              className="modal-body-2 select"
              style={{
                transform:
                  'translate3d(0, 0, 0) scale3d(0.8, 0.8, 1) rotateX(0deg) rotateY(-20deg) rotateZ(0deg) skew(0deg, 0deg)',
                transformStyle: 'preserve-3d',
                opacity: 0,
              }}
            >
              <h2 className="text-block-copy">Add External Store</h2>
              <div className="divider _60px" />
              <div className="div-block-93">
                <div className="form-block-3 w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                    className="form-4"
                    aria-label="Email Form"
                  >
                    <div className="w-layout-grid grid-24">
                      <label className="w-checkbox checkbox-field-3">
                        <div className="w-layout-grid grid-25">
                          <div className="text-block-35">Shopify</div>
                          <img
                            src={shopifyImage}
                            loading="lazy"
                            id="w-node-b0d59639-ea0e-7cbf-7864-433076216406-adbda994"
                            alt="Shopify"
                            className="image-87"
                          />
                        </div>
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-5" />
                        <input
                          type="checkbox"
                          id="checkbox-3"
                          name="checkbox-3"
                          data-name="Checkbox 3"
                          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                        />
                        <span className="checkbox-label-3 w-form-label" htmlFor="checkbox-3">
                          Shopify
                        </span>
                      </label>
                      <label className="w-checkbox checkbox-field-3">
                        <div className="w-layout-grid grid-25">
                          <div className="text-block-35">Shootproof</div>
                          <img
                            src={shootproofImage}
                            loading="lazy"
                            id="w-node-_43d9d116-0eca-1bbb-dedd-a49179921fe1-adbda994"
                            alt=""
                            className="image-87"
                          />
                        </div>
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-5" />
                        <input
                          type="checkbox"
                          id="checkbox-3"
                          name="checkbox-3"
                          data-name="Checkbox 3"
                          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                        />
                        <span className="checkbox-label-3 w-form-label" htmlFor="checkbox-3">
                          Shopify
                        </span>
                      </label>
                      <label className="w-checkbox checkbox-field-3">
                        <div className="w-layout-grid grid-25">
                          <div className="text-block-35">Pixieset</div>
                          <img
                            src={pixiesetImage}
                            loading="lazy"
                            id="w-node-a7bc52f5-6ec1-cffd-a951-0e4df6de2bf4-adbda994"
                            alt=""
                            className="image-87"
                          />
                        </div>
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-5" />
                        <input
                          type="checkbox"
                          id="checkbox-3"
                          name="checkbox-3"
                          data-name="Checkbox 3"
                          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                        />
                        <span className="checkbox-label-3 w-form-label" htmlFor="checkbox-3">
                          Shopify
                        </span>
                      </label>
                    </div>
                    <div className="divider" />
                    <input
                      type="submit"
                      value="Add Now"
                      data-wait="Please wait..."
                      className="main-button w-button"
                    />
                  </form>
                  <div
                    className="success-message w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form success"
                  >
                    <div className="div-block-62">
                      <div
                        className="lottie-animation-5"
                        data-w-id="f29ddaa8-f4dd-9b04-ea66-d13e9cc00380"
                        data-animation-type="lottie"
                        data-src="https://uploads-ssl.webflow.com/5fd3748c5ecfce1b1556e982/5fd3748c5ecfce7c3256ea29_lottieflow-success-07-000000-easey%20(1).json"
                        data-loop="1"
                        data-direction="1"
                        data-autoplay="1"
                        data-is-ix2-target="0"
                        data-renderer="svg"
                        data-default-duration="1.9791666666666667"
                        data-duration="0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 665 665"
                          width="665"
                          height="665"
                          preserveAspectRatio="xMidYMid meet"
                          style={{
                            width: '100%',
                            height: '100%',
                            transform: 'translate3d(0px, 0px, 0px)',
                          }}
                        >
                          <defs>
                            <clipPath id="__lottie_element_2">
                              <rect width="665" height="665" x="0" y="0" />
                            </clipPath>
                          </defs>
                          <g clipPath="url(#__lottie_element_2)">
                            <g
                              transform="matrix(1,0,0,1,332.5,332.5)"
                              opacity="1"
                              style={{ display: 'block' }}
                            >
                              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  fillOpacity="0"
                                  stroke="rgb(0,0,0)"
                                  strokeOpacity="1"
                                  strokeWidth="0"
                                  d=" M0,-327.5 C180.8730010986328,-327.5 327.5,-180.8730010986328 327.5,0 C327.5,180.8730010986328 180.8730010986328,327.5 0,327.5 C-180.8730010986328,327.5 -327.5,180.8730010986328 -327.5,0 C-327.5,-180.8730010986328 -180.8730010986328,-327.5 0,-327.5z"
                                />
                              </g>
                            </g>
                            <g
                              transform="matrix(1,0,0,1,332.5,332.5)"
                              opacity="1"
                              style={{ display: 'none' }}
                            >
                              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  fillOpacity="0"
                                  stroke="rgb(0,0,0)"
                                  strokeOpacity="1"
                                  strokeWidth="55"
                                  d=" M301.0489501953125,-266.9389953613281 C301.0489501953125,-266.9389953613281 -57.76935958862305,164.95846557617188 -57.76935958862305,164.95846557617188 C-57.76935958862305,164.95846557617188 -242.05978393554688,-13.899092674255371 -242.05978393554688,-13.899092674255371"
                                />
                              </g>
                            </g>
                            <g
                              transform="matrix(1,0,0,1,332.5,332.5)"
                              opacity="1"
                              style={{ display: 'block' }}
                            >
                              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  fillOpacity="0"
                                  stroke="rgb(0,0,0)"
                                  strokeOpacity="1"
                                  strokeWidth="5.121222615327746"
                                  d=" M301.3110046386719,-265.9700012207031 C301.3110046386719,-265.9700012207031 288.5679931640625,-250.68499755859375 267.88299560546875,-225.8730010986328"
                                />
                              </g>
                            </g>
                            <g
                              transform="matrix(1,0,0,1,332.5,332.5)"
                              opacity="1"
                              style={{ display: 'block' }}
                            >
                              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  fillOpacity="0"
                                  stroke="rgb(0,0,0)"
                                  strokeOpacity="1"
                                  strokeWidth="5.121222615327746"
                                  d=" M-242.3560028076172,-13.894000053405762 C-242.3560028076172,-13.894000053405762 -235.80499267578125,-7.553999900817871 -225.1719970703125,2.7360000610351562"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <div className="text-block-6">
                        <span className="text-span">Thank you!</span>
                        <br />
                        Your submission has been received!
                      </div>
                    </div>
                  </div>
                  <div
                    className="error-message w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form failure"
                  >
                    <div className="inline-notification danger">
                      <div className="inline-notification__details">
                        <div className="inline-notification__icon">
                          <div className="inline-notification__icon-error" />
                        </div>
                        <div className="inline-notification__text-wrapper">
                          <div className="inline-notification__title">Oops!</div>
                          <div className="inline-notification__subtitle">
                            Something went wrong while submitting the form.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-w-id="670c8638-78ff-29d6-aa48-ce7b89dd8e71" className="modal-closer musea" />
          </div>
        </div>
      </div>
    </div>
    <div className="div-block-92">
      <div
        data-duration-in="300"
        data-duration-out="100"
        data-current="Tab 1"
        data-easing="ease"
        className="tabs-2 w-tabs"
      >
        <div className="tabs-menu-2 w-tab-menu" role="tablist">
          <a
            data-w-tab="Tab 1"
            className="tab-2 w-inline-block w-tab-link w--current"
            id="w-tabs-0-data-w-tab-0"
            href="#w-tabs-0-data-w-pane-0"
            role="tab"
            aria-controls="w-tabs-0-data-w-pane-0"
            aria-selected="true"
          >
            <div>
              All (<span className="quantity-item">1</span>)
            </div>
          </a>
        </div>
        <div className="tabs-content-2 w-tab-content">
          <div
            data-w-tab="Tab 1"
            className="pane-2 w-tab-pane w--tab-active"
            id="w-tabs-0-data-w-pane-0"
            role="tabpanel"
            aria-labelledby="w-tabs-0-data-w-tab-0"
          >
            <div className="div-block-75-copy external-store">
              <div className="div-block-315" />
              <div className="text-block-standard semi-bold">Product</div>
              <a
                id="w-node-_7eca6648-06e2-4776-9997-23333dfe40a2-adbda994"
                href="/external-stores-list"
                className="third-button small fixed w-inline-block"
              >
                <img src={toolImage} loading="lazy" alt="" className="imagen-icon" />
                <div className="semi-bold">Bulk Edit</div>
              </a>
            </div>
            <div className="div-block-82">
              <div className="div-block-75 external-store">
                <div
                  id="w-node-_01a68949-69ea-ee64-18f1-83c16a4de3b2-adbda994"
                  className="div-block-80"
                >
                  <div className="div-block-79">
                    <img
                      src={shopifyImage}
                      loading="lazy"
                      alt=""
                      className="store-image"
                    />
                  </div>
                  <div className="div-block-81">
                    <div className="text-table">Shopify</div>
                    <div className="text-block-19">http://myshopifystore.com/</div>
                  </div>
                </div>
                <div className="div-block-315" />
                <div
                  id="w-node-_5d48a63f-509c-b40c-26c2-ea8c45c34d34-adbda994"
                  className="w-layout-grid grid-26"
                >
                  <a
                    id="w-node-_5d48a63f-509c-b40c-26c2-ea8c45c34d35-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={copyImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Duplicate</div>
                  </a>
                  <a
                    id="w-node-_5d48a63f-509c-b40c-26c2-ea8c45c34d39-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img
                      src={dollarSignImage}
                      loading="lazy"
                      alt=""
                      className="imagen-icon"
                    />
                    <div>Edit Prices</div>
                  </a>
                  <a
                    id="w-node-_5d48a63f-509c-b40c-26c2-ea8c45c34d3d-adbda994"
                    href="/external-stores-list"
                    className="third-button small line delete w-inline-block"
                  >
                    <img src={trashImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Delete</div>
                  </a>
                </div>
              </div>
              <div className="div-block-75 external-store">
                <div
                  id="w-node-aeff5017-70d3-5527-b0e2-6e5d2576fd99-adbda994"
                  className="div-block-80"
                >
                  <div className="div-block-79">
                    <img
                      src={pixiesetImage}
                      loading="lazy"
                      alt=""
                      className="store-image"
                    />
                  </div>
                  <div className="div-block-81">
                    <div className="text-table">Pixieset</div>
                    <div className="text-block-19">http://pixieset.com/</div>
                  </div>
                </div>
                <div className="div-block-315" />
                <div className="w-layout-grid grid-26">
                  <a
                    id="w-node-_2c507577-29cc-7cf9-7d4d-9555c301a186-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={copyImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Duplicate</div>
                  </a>
                  <a
                    id="w-node-_2c507577-29cc-7cf9-7d4d-9555c301a18a-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img
                      src={dollarSignImage}
                      loading="lazy"
                      alt=""
                      className="imagen-icon"
                    />
                    <div>Edit Prices</div>
                  </a>
                  <a
                    id="w-node-_2c507577-29cc-7cf9-7d4d-9555c301a18e-adbda994"
                    href="/external-stores-list"
                    className="third-button small line delete w-inline-block"
                  >
                    <img src={trashImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Delete</div>
                  </a>
                </div>
              </div>
              <div className="div-block-75 external-store">
                <div
                  id="w-node-_8e1e1ee1-7e90-a3bf-e22c-7423f899fbe8-adbda994"
                  className="div-block-80"
                >
                  <div className="div-block-79">
                    <img
                      src={shootproofImage}
                      loading="lazy"
                      alt=""
                      className="store-image"
                    />
                  </div>
                  <div className="div-block-81">
                    <div className="text-table">Shootproof</div>
                    <div className="text-block-19">http://shootproof.com/</div>
                  </div>
                </div>
                <div className="div-block-315" />
                <div className="w-layout-grid grid-26">
                  <a
                    id="w-node-db5ede6b-53b5-d774-4347-40a22520723e-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={copyImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Duplicate</div>
                  </a>
                  <a
                    id="w-node-db5ede6b-53b5-d774-4347-40a225207242-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img
                      src={dollarSignImage}
                      loading="lazy"
                      alt=""
                      className="imagen-icon"
                    />
                    <div>Edit Prices</div>
                  </a>
                  <a
                    id="w-node-db5ede6b-53b5-d774-4347-40a225207246-adbda994"
                    href="/external-stores-list"
                    className="third-button small line delete w-inline-block"
                  >
                    <img src={trashImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Delete</div>
                  </a>
                </div>
              </div>
              <div className="div-block-75 external-store">
                <div
                  id="w-node-_190f6559-d829-8e02-d603-7d7fb9f9c989-adbda994"
                  className="div-block-80"
                >
                  <div className="div-block-79">
                    <img
                      src={shopifyImage}
                      loading="lazy"
                      alt=""
                      className="store-image"
                    />
                  </div>
                  <div className="div-block-81">
                    <div className="text-table">Shopify</div>
                    <div className="text-block-19">http://myshopifystore.com/</div>
                  </div>
                </div>
                <div className="div-block-315" />
                <div className="w-layout-grid grid-26">
                  <a
                    id="w-node-_99ee872b-ff1a-3fd0-8a25-34dab6d9db88-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={copyImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Duplicate</div>
                  </a>
                  <a
                    id="w-node-_99ee872b-ff1a-3fd0-8a25-34dab6d9db8c-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img
                      src={dollarSignImage}
                      loading="lazy"
                      alt=""
                      className="imagen-icon"
                    />
                    <div>Edit Prices</div>
                  </a>
                  <a
                    id="w-node-_99ee872b-ff1a-3fd0-8a25-34dab6d9db90-adbda994"
                    href="/external-stores-list"
                    className="third-button small line delete w-inline-block"
                  >
                    <img src={trashImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Delete</div>
                  </a>
                </div>
              </div>
              <div className="div-block-75 external-store">
                <div
                  id="w-node-_7b8186a5-e1f7-e3b8-cc17-0bfe5c53c3cd-adbda994"
                  className="div-block-80"
                >
                  <div className="div-block-79">
                    <img
                      src={shopifyImage}
                      loading="lazy"
                      alt=""
                      className="store-image"
                    />
                  </div>
                  <div className="div-block-81">
                    <div className="text-table">Shopify</div>
                    <div className="text-block-19">#4608268468282</div>
                  </div>
                </div>
                <div className="div-block-315" />
                <div className="w-layout-grid grid-26">
                  <a
                    id="w-node-e8cee379-e3da-d714-f43c-d7d2fc3cab91-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={copyImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Duplicate</div>
                  </a>
                  <a
                    id="w-node-e8cee379-e3da-d714-f43c-d7d2fc3cab95-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img
                      src={dollarSignImage}
                      loading="lazy"
                      alt=""
                      className="imagen-icon"
                    />
                    <div>Edit Prices</div>
                  </a>
                  <a
                    id="w-node-e8cee379-e3da-d714-f43c-d7d2fc3cab99-adbda994"
                    href="/external-stores-list"
                    className="third-button small line delete w-inline-block"
                  >
                    <img src={trashImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Delete</div>
                  </a>
                </div>
              </div>
              <div className="div-block-75 external-store">
                <div
                  id="w-node-_1126f722-3892-927f-89e2-d54082e4a8e1-adbda994"
                  className="div-block-80"
                >
                  <div className="div-block-79">
                    <img
                      src={shootproofImage}
                      loading="lazy"
                      alt=""
                      className="store-image"
                    />
                  </div>
                  <div className="div-block-81">
                    <div className="text-table">Shootproof</div>
                    <div className="text-block-19">http://shootproof.com/</div>
                  </div>
                </div>
                <div className="div-block-315" />
                <div className="w-layout-grid grid-26">
                  <a
                    id="w-node-_3e7cd833-978f-e0f9-0d04-babf2d587155-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={copyImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Duplicate</div>
                  </a>
                  <a
                    id="w-node-_3e7cd833-978f-e0f9-0d04-babf2d587159-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img
                      src={dollarSignImage}
                      loading="lazy"
                      alt=""
                      className="imagen-icon"
                    />
                    <div>Edit Prices</div>
                  </a>
                  <a
                    id="w-node-_3e7cd833-978f-e0f9-0d04-babf2d58715d-adbda994"
                    href="/external-stores-list"
                    className="third-button small line delete w-inline-block"
                  >
                    <img src={trashImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Delete</div>
                  </a>
                </div>
              </div>
              <div className="div-block-75 external-store">
                <div
                  id="w-node-_8f5e2562-86ea-da1a-7005-1ca848c791f8-adbda994"
                  className="div-block-80"
                >
                  <div className="div-block-79">
                    <img
                      src={pixiesetImage}
                      loading="lazy"
                      alt=""
                      className="store-image"
                    />
                  </div>
                  <div className="div-block-81">
                    <div className="text-table">Pixieset</div>
                    <div className="text-block-19">http://pixieset.com/</div>
                  </div>
                </div>
                <div className="div-block-315" />
                <div className="w-layout-grid grid-26">
                  <a
                    id="w-node-_5dc746f0-6a40-9c0b-4e28-a7636c27f091-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={copyImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Duplicate</div>
                  </a>
                  <a
                    id="w-node-_5dc746f0-6a40-9c0b-4e28-a7636c27f095-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img
                      src={dollarSignImage}
                      loading="lazy"
                      alt=""
                      className="imagen-icon"
                    />
                    <div>Edit Prices</div>
                  </a>
                  <a
                    id="w-node-_1eaf7140-68f5-7ce9-ffa6-fd70fd5e3ee6-adbda994"
                    href="/external-stores-list"
                    className="third-button small line delete w-inline-block"
                  >
                    <img src={trashImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Delete</div>
                  </a>
                </div>
              </div>
              <div className="div-block-75 external-store">
                <div
                  id="w-node-dee51b15-6952-a6d0-309c-3252a2836de2-adbda994"
                  className="div-block-80"
                >
                  <div className="div-block-79">
                    <img
                      src={shootproofImage}
                      loading="lazy"
                      alt=""
                      className="store-image"
                    />
                  </div>
                  <div className="div-block-81">
                    <div className="text-table">Shootproof</div>
                    <div className="text-block-19">http://shootproof.com/</div>
                  </div>
                </div>
                <div className="div-block-315" />
                <div className="w-layout-grid grid-26">
                  <a
                    id="w-node-dc96ad23-d5b2-5951-85d8-b941769d57ab-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={copyImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Duplicate</div>
                  </a>
                  <a
                    id="w-node-dc96ad23-d5b2-5951-85d8-b941769d57af-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img
                      src={dollarSignImage}
                      loading="lazy"
                      alt=""
                      className="imagen-icon"
                    />
                    <div>Edit Prices</div>
                  </a>
                  <a
                    id="w-node-aeee84b8-252d-985e-47fa-870cda4cb71f-adbda994"
                    href="/external-stores-list"
                    className="third-button small line delete w-inline-block"
                  >
                    <img src={trashImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Delete</div>
                  </a>
                </div>
              </div>
              <div className="div-block-75 external-store">
                <div
                  id="w-node-ee8039fd-38aa-afad-5572-f4aace6030d9-adbda994"
                  className="div-block-80"
                >
                  <div className="div-block-79">
                    <img
                      src={shopifyImage}
                      loading="lazy"
                      alt=""
                      className="store-image"
                    />
                  </div>
                  <div className="div-block-81">
                    <div className="text-table">Shopify</div>
                    <div className="text-block-19">http://myshopifystore.com/</div>
                  </div>
                </div>
                <div className="div-block-315" />
                <div
                  id="w-node-f3df62ae-0530-7f03-db2f-b5cc5be965fd-adbda994"
                  className="w-layout-grid grid-26"
                >
                  <a
                    id="w-node-f3df62ae-0530-7f03-db2f-b5cc5be965fe-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={copyImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Duplicate</div>
                  </a>
                  <a
                    id="w-node-f3df62ae-0530-7f03-db2f-b5cc5be96602-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img
                      src={dollarSignImage}
                      loading="lazy"
                      alt=""
                      className="imagen-icon"
                    />
                    <div>Edit Prices</div>
                  </a>
                  <a
                    id="w-node-c4e90651-f2f5-39e6-1e49-313ccf308a78-adbda994"
                    href="/external-stores-list"
                    className="third-button small line delete w-inline-block"
                  >
                    <img src={trashImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Delete</div>
                  </a>
                </div>
              </div>
              <div className="div-block-75 external-store">
                <div
                  id="w-node-_21a8b8d7-1709-8288-19d8-23fa4cf469f9-adbda994"
                  className="div-block-80"
                >
                  <div className="div-block-79">
                    <img
                      src={pixiesetImage}
                      loading="lazy"
                      alt=""
                      className="store-image"
                    />
                  </div>
                  <div className="div-block-81">
                    <div className="text-table">Pixieset</div>
                    <div className="text-block-19">http://pixieset.com/</div>
                  </div>
                </div>
                <div className="div-block-315" />
                <div className="w-layout-grid grid-26">
                  <a
                    id="w-node-_7e99dc38-4939-c321-8e0d-2768f66fb5e7-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={copyImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Duplicate</div>
                  </a>
                  <a
                    id="w-node-_7e99dc38-4939-c321-8e0d-2768f66fb5eb-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img
                      src={dollarSignImage}
                      loading="lazy"
                      alt=""
                      className="imagen-icon"
                    />
                    <div>Edit Prices</div>
                  </a>
                  <a
                    id="w-node-_7e99dc38-4939-c321-8e0d-2768f66fb5ef-adbda994"
                    href="/external-stores-list"
                    className="third-button small line w-inline-block"
                  >
                    <img src={trashImage} loading="lazy" alt="" className="imagen-icon" />
                    <div>Delete</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ExternalStores
