import NavDrawer from '../NavDrawer'

const Layout = ({ children }) => (
  <div className="w-layout-grid grid">
    <NavDrawer />
    {children}
  </div>
)

export default Layout
