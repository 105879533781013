import { ReactComponent as PersonIcon } from 'images/icons/NavDrawer/account.svg'
import { ReactComponent as BussinesIcon } from 'images/icons/bussines-alternative.svg'
import { ReactComponent as WorldIcon } from 'images/icons/world.svg'
import { ReactComponent as EnvelopeIcon } from 'images/icons/envelope.svg'
import { ReactComponent as PhoneIcon } from 'images/icons/phone.svg'

import { capitalizeFirstLetter } from 'lib/utils'
import PhotographerSales from './PhotographerSales'
import PhotographerOrdersSummary from './PhotographerOrdersSummary'

const PhotographerProfileDetails = ({ profile }) => {
  if (!profile) {
    return null
  }

  return (
    <div className="client-account-block2">
      <div className="sub-heading-container">
        <div className="icon right-margin w-embed">
          <PersonIcon />
        </div>
        <h2 className="heading-title-2">
          {`${capitalizeFirstLetter(profile?.firstName)} ${capitalizeFirstLetter(
            profile?.lastName,
          )}`}
        </h2>
      </div>

      <div className="accordion-item-block no-margin">
        <div className="w-layout-grid grid-13-copy">
          <div
            id="w-node-edb36a92-3594-4788-3cce-c3be0c599e61-41e80a1e"
            className="w-layout-grid grid-31-copy-copy"
          >
            <div className="w-layout-grid grid-23 main _2">
              <div
                id="w-node-edb36a92-3594-4788-3cce-c3be0c599e63-41e80a1e"
                className="icon small x2 w-embed"
              >
                <BussinesIcon />
              </div>
              <div>{profile?.businessName ?? '-'}</div>
            </div>
            <div className="w-layout-grid grid-23 main _2">
              <div
                id="w-node-_0050eec6-e21d-310f-8a29-aae3e0904df1-41e80a1e"
                className="icon small x2 w-embed"
              >
                <WorldIcon />
              </div>
              <div>{profile?.email}</div>
            </div>
          </div>
          <div
            id="w-node-_2a8d8542-1e6a-9f18-eb52-0809d78bbee4-41e80a1e"
            className="w-layout-grid grid-31-copy-copy"
          >
            <div className="w-layout-grid grid-23 main _2">
              <div
                id="w-node-_2a8d8542-1e6a-9f18-eb52-0809d78bbeee-41e80a1e"
                className="icon small x2 w-embed"
              >
                <EnvelopeIcon />
              </div>
              <div>{profile?.businessUrl}</div>
            </div>
            <div className="w-layout-grid grid-23 main _2">
              <div
                id="w-node-_2a8d8542-1e6a-9f18-eb52-0809d78bbef2-41e80a1e"
                className="icon small x2 w-embed"
              >
                <PhoneIcon />
              </div>
              <div>{profile?.phone ?? '-'}</div>
            </div>
          </div>
        </div>

        <div className="line-horizontal margins" />
        <PhotographerSales />
        <div className="line-horizontal margins" />
        <PhotographerOrdersSummary />
      </div>
    </div>
  )
}

export default PhotographerProfileDetails
