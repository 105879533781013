import BasicInformation from 'components/Account/BasicInformation'
import LoginEmail from 'components/Account/LoginEmail'

import Loading from 'components/common/Loading'
import ErrorMessage from 'components/common/ErrorMessage'
import BlanketCertificateResale from 'components/Account/BlanketCertificateResale'
import useProfile from 'hooks/useProfile'
import SalesHistory from 'components/Account/SalesHistory'

const AccountPage = () => {
  const { profile, error, loading, onProfileUpdate, refetchUser } = useProfile()

  const user = profile?.user.data.attributes

  if (loading) {
    return <Loading message="Loading profile" />
  }

  if (error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <div className="client-account-container">
      <div className="client-account-sub-container">
        <div className="client-account-grid">
          <div className="client-account-block2">
            <BasicInformation user={user} onUpdate={onProfileUpdate} />
            <div className="line-horizontal margins" />
          </div>
          <div className="w-layout-grid client-account-block">
            <div className="client-account-block2">
              <LoginEmail user={user} onUpdate={onProfileUpdate} />
              <div className="line-horizontal margins" />
            </div>
            <div className="client-account-block2 horizontal">
              <BlanketCertificateResale user={user} refetchUser={refetchUser} />
              <SalesHistory taxRecords={user?.taxRecordsByYear} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountPage
