import { Link } from 'react-router-dom'
import { ReactComponent as NextIcon } from 'images/icons/next.svg'

const SelectProducts = ({ id }) => (
  <div className="w-layout-grid grid-print-bottom-button">
    <Link to={`/product-selection/${id}`} className="button-2 w-inline-block">
      <div className="icon right-margin w-embed">
        <NextIcon />
      </div>
      <div>Select Products</div>
    </Link>
  </div>
)

export default SelectProducts
