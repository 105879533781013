const PhotographersDatabaseTableHeader = () => (
  <div className="w-layout-grid grid-table-heady top">
    <div className="heady">
      <div>Name</div>
    </div>
    <div className="heady">
      <div>Email</div>
    </div>
    <div className="heady">
      <div>Business Name</div>
    </div>
    <div className="heady">
      <div>Total Sales</div>
    </div>
    <div className="heady">
      <div>Password</div>
    </div>
    <div id="w-node-_79f08531-6afd-7b9d-2f32-6719dcb29f5c-67e80a11" className="heady">
      <div>Status</div>
    </div>
    <div className="heady" />
  </div>
)

export default PhotographersDatabaseTableHeader
