import { useCallback, useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { useForm, Controller } from 'react-hook-form'
import cn from 'classnames'
import values from 'lodash/values'
import axios from 'axios'

import httpClient from 'httpClient'
import { SIGN_UP_STEPS } from 'lib/const'
import { apiErrorMessage } from 'lib/utils'
import { useToggle } from 'hooks'

import { ReactComponent as ErrorIcon } from 'images/icons/error.svg'
import { ReactComponent as CheckIcon } from 'images/icons/check-rounded.svg'

import Heading from 'components/common/Heading'
import ErrorMessage from 'components/common/ErrorMessage'

const Step2 = ({ onNextStep }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm()
  const location = useLocation()
  const [showCode, , setShowCode] = useToggle(true)
  const [validCode, setValidCode] = useState(null)
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)
  const code = watch('code')
  const { token } = queryString.parse(location.search)

  const validateCode = async () => {
    try {
      await httpClient.post('check_referral_code', { code })
      setValidCode(true)
    } catch (error) {
      setValidCode(false)
    }
  }

  useEffect(() => {
    if (code) {
      validateCode()
    }
  }, [code])

  const handleReferralSubmit = useCallback(async (data) => {
    setApiError(null)
    try {
      setLoading(true)
      await axios.patch(`${process.env.REACT_APP_API_URL}update_new_user`, data, {
        headers: { token },
      })
      onNextStep(SIGN_UP_STEPS.resaleCertificate)
    } catch (error) {
      setApiError(apiErrorMessage(error))
      setLoading(false)
    }
  }, [])

  return (
    <div className="div-full-screen white-smoke">
      <div className="div-block-181">
        <Heading title="Welcome to" withBrandName />
        <div className="form-block-3 w-form">
          <form id="email-form" onSubmit={handleSubmit(handleReferralSubmit)} className="form-9">
            <div className="title-info-card center">Were you referred by another photographer?</div>
            <div className="container-center">
              <div className="form-input select-field max-width-100px">
                <div className="select-field-wrapper">
                  <select
                    data-name="Field 3"
                    name="field-3"
                    className="form-select-field w-select"
                    onChange={(e) => setShowCode(e.target.value)}
                  >
                    <option value="yes">Yes</option>
                    <option value="">No</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-item-2">
              <div className="text-input__field-wrapper" />
            </div>
            <div className="form-item-3 centered">
              <div>
                If yes, enter their referral code below.
                <br />
                <br />
                {'If no, click '}
                <span className="text-span-19">Next.</span>
              </div>
            </div>
            <div className="text-block-34 centered">
              <em>
                If you don’t enter a referral code, we will not be able to link accounts later.
              </em>
            </div>
            <div className="container-center margin-bottom">
              {showCode && (
                <div className="form-input select-field width-300px">
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter your email.',
                      },
                    }}
                    name="code"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <div className="select-field-wrapper">
                        <div className="text-input__field-wrapper">
                          <input
                            {...field}
                            type="text"
                            className="text-field-2 font-size-14 w-input"
                            maxLength="256"
                            name="code"
                            disabled={!showCode}
                            placeholder="Refferal Code"
                          />
                          {code && (
                            <div className="eye-2" style={{ opacity: 1, height: '36px' }}>
                              <div className="icon w-embed">
                                {validCode ? (
                                  <CheckIcon color="#73d13d" />
                                ) : (
                                  <ErrorIcon color="#ff4d4f" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
            <div className="divider-16px" />
            <button
              type="submit"
              disabled={showCode ? loading || isSubmitting || !validCode || !code : false}
              className={cn('main-button w-button button-2', {
                disabled: showCode ? loading || isSubmitting || !validCode || !code : false,
              })}
            >
              {loading || isSubmitting ? 'Please wait...' : 'Next'}
            </button>
          </form>
          {(values(errors) || apiError) && (
            <div className="form-item-2">
              <ErrorMessage errors={values(errors)?.[0]?.message || apiError} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Step2
