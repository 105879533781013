const ScreenBlocker = () => (
  <div className="screen-blocker">
    <div className="div-block-267">
      <div className="icon big w-embed">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
          <rect width="256" height="256" fill="none" />
          <path d="M208,40H48A24.0275,24.0275,0,0,0,24,64V176a24.0275,24.0275,0,0,0,24,24h72v16H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16H136V200h72a24.0275,24.0275,0,0,0,24-24V64A24.0275,24.0275,0,0,0,208,40Zm0,144H48a8.00917,8.00917,0,0,1-8-8V160H216v16A8.00917,8.00917,0,0,1,208,184Z" />
        </svg>
      </div>
      <div className="divider" />
      <h2 className="heading-title">Your browser is too small.</h2>
      <div className="divider" />
      <div>Your screen needs to be at least 1280px wide to access this page</div>
    </div>
  </div>
)

export default ScreenBlocker
