import { Helmet } from 'react-helmet'

import Layout from './Layout'

const PublicRoute = ({ children, withLayout, title }) => (
  <>
    <Helmet>
      <title>{`MuseaLab - ${title}`}</title>
    </Helmet>
    {withLayout ? <Layout>{children}</Layout> : children}
  </>
)

export default PublicRoute
