import 'css/normalize.css'
import 'css/components.css'
import 'css/musea-lab-system.css'
import 'react-tippy/dist/tippy.css'

import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { useMediaQuery } from 'hooks'
import { SCREEN_BLOCKER_QUERY } from 'lib/const'

import LoginPage from 'pages/Login'
import SignUpPagePage from 'pages/SignUp'
import NotFound from 'pages/404'
import PrintOrderCreatePage from 'pages/PrintOrderCreate'
import LabOrdersPage from 'pages/LabOrders'
import UnbundledLineItemsPage from 'pages/UnbundledLineItemsPage'
import LabOrderSummaryPage from 'pages/LabOrderSummaryPage'
import LabOrderDetailsPage from 'pages/LabOrderDetails'
import ForgotPasswordPage from 'pages/ForgotPassword'
import ResetPasswordPage from 'pages/ResetPassword'
import CheckoutPage from 'pages/Checkout'
import AccountPage from 'pages/Account'
import AccountPendingPage from 'pages/AccountPending'
import CreditPage from 'pages/Credit'
import PhotographersDatabasePage from 'pages/PhotographersDatabase'
import PhotographerProfilePage from 'pages/PhotographerProfile'
import ExternalStores from 'pages/ExternalStores'
import Pricing from 'pages/Pricing'
import Design from 'pages/Design'
import IntegrationInstalling from 'pages/IntegrationInstalling'
import Product from 'pages/Product'
import ProductSelection from 'pages/ProductSelection'
import ProductSelectionCopy from 'pages/ProductSelectionCopy'
import ProductSelectionAlbum from 'pages/ProductSelectionAlbum'
// eslint-disable-next-line import/no-extraneous-dependencies
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import ScreenBlocker from './ScreenBlocker'

const queryClient = new QueryClient()

const App = () => {
  const showBlocker = useMediaQuery(SCREEN_BLOCKER_QUERY)

  if (showBlocker) {
    return <ScreenBlocker />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <PublicRoute title="Login">
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path="/sign-up"
            element={
              <PublicRoute title="Register">
                <SignUpPagePage />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute title="Forgot Password">
                <ForgotPasswordPage />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute title="Reset your Password">
                <ResetPasswordPage />
              </PublicRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute title="Lab Orders" withLayout>
                <LabOrdersPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute title="My Account" withLayout>
                <AccountPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/external-stores-list"
            element={
              <PublicRoute title="External Stores" withLayout>
                <ExternalStores />
              </PublicRoute>
            }
          />
          <Route
            path="/pricing"
            element={
              <PublicRoute title="Edit Retail Price" withLayout>
                <Pricing />
              </PublicRoute>
            }
          />
          <Route
            path="/design"
            element={
              <PublicRoute title="Create a Product Template" withLayout>
                <Design />
              </PublicRoute>
            }
          />
          <Route
            path="/installing"
            element={
              <PublicRoute title="Install Musea App">
                <IntegrationInstalling />
              </PublicRoute>
            }
          />
          <Route
            path="/product"
            element={
              <PublicRoute title="Create a product template">
                <Product />
              </PublicRoute>
            }
          />
          <Route
            path="/product-selection/:id"
            element={
              <PublicRoute title="Product Selection">
                <ProductSelection />
              </PublicRoute>
            }
          />
          <Route
            path="/product-selection-copy"
            element={
              <PublicRoute title="Product Selection Copy">
                <ProductSelectionCopy />
              </PublicRoute>
            }
          />
          <Route
            path="/product-selection-album"
            element={
              <PublicRoute title="Product Selection Album">
                <ProductSelectionAlbum />
              </PublicRoute>
            }
          />
          <Route
            path="/account-pending"
            element={
              <PrivateRoute title="Pending account">
                <AccountPendingPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/credit"
            element={
              <PrivateRoute title="Credit" withLayout>
                <CreditPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/lab-orders"
            element={
              <PrivateRoute title="Lab Orders" withLayout>
                <LabOrdersPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/lab-orders/unbundled-line-items"
            element={
              <PrivateRoute title="Unbundled Line Items" withLayout>
                <UnbundledLineItemsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/lab-orders/:id"
            element={
              <PrivateRoute title="Lab Order Details" withLayout>
                <LabOrderDetailsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/lab-orders/:id/summary"
            element={
              <PrivateRoute title="Lab Order Summary" withLayout>
                <LabOrderSummaryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/print-order/create"
            element={
              <PrivateRoute title="New Print Order" withLayout>
                <PrintOrderCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <PrivateRoute title="Checkout Order">
                <CheckoutPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/photographers"
            element={
              <PrivateRoute title="Photographers Database" withLayout>
                <PhotographersDatabasePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/photographer/:id"
            element={
              <PrivateRoute title="Photographer Profile" withLayout>
                <PhotographerProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute title="Not Found" withLayout>
                <NotFound />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
