import { useRef } from 'react'
import cn from 'classnames'

import { useToggle, useOutsideClickHandler } from 'hooks'
import { ORDER_BY } from 'lib/const'
import { ReactComponent as OrderIcon } from 'images/icons/order.svg'
import InputRadio from './InputRadio'

const OrderByDropdown = ({ onChange, active }) => {
  const ref = useRef()
  const [isOpen, toggleState, setToggleState] = useToggle(false)

  useOutsideClickHandler(ref, () => setToggleState(false))

  return (
    <div className="dropdown w-dropdown" ref={ref}>
      <div
        className="filter-select-field radius w-dropdown-toggle"
        id="w-dropdown-toggle-3"
        aria-controls="w-dropdown-list-3"
        aria-haspopup="menu"
        aria-expanded="false"
        tabIndex="0"
        role="button"
        onClick={toggleState}
        onKeyPress={toggleState}
      >
        <div className="icon small left-margin w-embed">
          <OrderIcon />
        </div>
      </div>
      <nav
        className={cn('dropdown-list sort w-dropdown-list', {
          'w--open': isOpen,
        })}
        id="w-dropdown-list-3"
        aria-labelledby="w-dropdown-toggle-3"
      >
        <div className="form-block-filter w-form">
          <form id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-3">
            {Object.keys(ORDER_BY).map((option) => (
              <InputRadio
                key={option}
                label={ORDER_BY[option].label}
                onClick={onChange}
                value={option}
                active={active === option}
              />
            ))}
          </form>
        </div>
      </nav>
    </div>
  )
}

export default OrderByDropdown
