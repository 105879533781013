import './styles.css'
// import shopifyImage from '../../images/shopify-plus22-logo--black.png'
// import shootproofImage from '../../images/descarga22.png'
// import pixiesetImage from '../../images/Cirila2-Р-majuskla.svg.png'
// import copyImage from '../../images/copy.svg'
// import dollarSignImage from '../../images/dollar-sign.svg'
// import trashImage from '../../images/trash-2.svg'
import priceInfoImage from '../../images/info-4.svg'

const Pricing = () => (
  <div className="main-wrapper scroll">
    <div className="w-layout-grid navbar-wrapper">
      <div className="w-layout-grid top-grid">
        <div id="w-node-fd41b655-37fb-d06c-6b9b-ba7a049b653f-2bbda995">
          <h3 className="heading-3">Edit Retail Price</h3>
        </div>
        <nav id="w-node-fd41b655-37fb-d06c-6b9b-ba7a049b6542-2bbda995" className="breadcrumb">
          <a href="/" className="breadcrumb-link">
            Products
          </a>
          <div className="breadcrumb-divider"></div>
          <a href="/" className="breadcrumb-link">
            Home &amp; Living
          </a>
          <div className="breadcrumb-divider"></div>
          <a href="/product" className="breadcrumb-link">
            Framed Posters
          </a>
        </nav>
        <div id="w-node-fd41b655-37fb-d06c-6b9b-ba7a049b654b-2bbda995" className="div-block-71">
          <a href="/" className="_2nd-button main w-inline-block">
            <div className="icon-3 right-margin w-embed">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                <rect width="256" height="256" fill="none" />
                <path d="M215.99512,71.99805H130.667l-27.75-20.80469a16.07363,16.07363,0,0,0-9.57812-3.19531H39.99512a16.01581,16.01581,0,0,0-16,16v136a16.01582,16.01582,0,0,0,16,16H216.88574a15.13062,15.13062,0,0,0,15.10938-15.10938V87.99805A16.01582,16.01582,0,0,0,215.99512,71.99805ZM152,152H136v16a8,8,0,0,1-16,0V152H104a8,8,0,0,1,0-16h16V120a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16Z" />
              </svg>
            </div>
            <div>Save Prices</div>
          </a>
        </div>
      </div>
    </div>
    <div className="div-block-88">
      <div className="block-wrap pane-2">
        <div className="card-copy">
          <div className="pricing-head-table">
            <div className="text-block-standard semi-bold">Variant</div>
          </div>
          <div className="pricing-head-table">
            <div className="text-block-standard semi-bold">Musea Price</div>
            <img
              src={priceInfoImage}
              loading="lazy"
              data-tippy-content="Doesn't include tax, shipping, and other additional charges"
              alt=""
              className="help-icon tippy"
            />
          </div>
          <div className="pricing-head-table">
            <div className="text-block-standard semi-bold">Retail Price</div>
            <img
              src={priceInfoImage}
              loading="lazy"
              data-tippy-content="What's a Retail Price?"
              alt=""
              className="help-icon tippy"
            />
          </div>
          <div className="pricing-head-table">
            <div className="text-block-standard semi-bold">Profit</div>
            <img
              src={priceInfoImage}
              loading="lazy"
              data-tippy-content="Lorem Ipsum Dolor"
              alt=""
              className="help-icon tippy"
            />
          </div>
        </div>
        <div className="pricing-grid sub">
          <div className="pricing-head-table"></div>
          <div
            id="w-node-e4dcc409-16f7-57d7-4e57-5745e5fd541c-2bbda995"
            className="pricing-head-table"
          >
            <div className="text-block-standard">$19.42 - $79.74</div>
          </div>
          <div className="pricing-head-table">
            <div className="text-block-standard">$81.50 - $330</div>
          </div>
          <div
            id="w-node-e4dcc409-16f7-57d7-4e57-5745e5fd5422-2bbda995"
            className="pricing-head-table grid"
          >
            <div
              data-delay="90"
              data-hover="false"
              id="w-node-e4dcc409-16f7-57d7-4e57-5745e5fd5423-2bbda995"
              data-w-id="e4dcc409-16f7-57d7-4e57-5745e5fd5423"
              className="dropdown mobile w-dropdown"
            >
              <div
                className="dropdown-toggle-2 low w-dropdown-toggle"
                id="w-dropdown-toggle-1"
                aria-controls="w-dropdown-list-1"
                aria-haspopup="menu"
                aria-expanded="false"
                role="button"
                tabIndex="0"
              >
                <div
                  style={{
                    transform:
                      'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    transformStyle: 'preserve-3d',
                  }}
                  className="dropdown-toggle__icon"
                />
                <div>Increase By</div>
              </div>
              <nav
                className="dropdown-list-2 frost-blur w-dropdown-list"
                style={{ height: 0, display: 'none' }}
                id="w-dropdown-list-1"
                aria-labelledby="w-dropdown-toggle-1"
              >
                <div className="dropdown-item">
                  <a href="/" className="overflow-menu__link _3 w-inline-block" tabIndex="0">
                    <div>Increase By</div>
                  </a>
                  <a href="/" className="overflow-menu__link _3 w-dropdown-link" tabIndex="0">
                    Decrease By
                  </a>
                  <a href="/" className="overflow-menu__link _3 w-dropdown-link" tabIndex="0">
                    Change to
                  </a>
                </div>
              </nav>
            </div>
            <div
              data-delay="90"
              data-hover="false"
              id="w-node-e4dcc409-16f7-57d7-4e57-5745e5fd5432-2bbda995"
              data-w-id="e4dcc409-16f7-57d7-4e57-5745e5fd5432"
              className="dropdown mobile w-dropdown"
            >
              <div
                className="dropdown-toggle-2 small w-dropdown-toggle"
                id="w-dropdown-toggle-2"
                aria-controls="w-dropdown-list-2"
                aria-haspopup="menu"
                aria-expanded="false"
                role="button"
                tabIndex="0"
              >
                <div
                  style={{
                    transform:
                      'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    transformStyle: 'preserve-3d',
                  }}
                  className="dropdown-toggle__icon"
                />
                <div>$</div>
              </div>
              <nav
                className="dropdown-list-2 frost-blur w-dropdown-list"
                style={{ height: 0, display: 'none' }}
                id="w-dropdown-list-2"
                aria-labelledby="w-dropdown-toggle-2"
              >
                <div className="dropdown-item">
                  <a href="/" className="overflow-menu__link _3 w-inline-block" tabIndex="0">
                    <div>$</div>
                  </a>
                  <a href="/" className="overflow-menu__link _3 w-dropdown-link" tabIndex="0">
                    %
                  </a>
                </div>
              </nav>
            </div>
            <div className="form-block-4 w-form">
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                method="get"
                aria-label="Email Form 2"
              >
                <input
                  type="number"
                  className="number-input white w-input"
                  maxLength="256"
                  name="name-3"
                  data-name="Name 3"
                  placeholder="10"
                  id="name-3"
                />
              </form>
              <div
                className="w-form-done"
                tabIndex="-1"
                role="region"
                aria-label="Email Form 2 success"
              >
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div
                className="w-form-fail"
                tabIndex="-1"
                role="region"
                aria-label="Email Form 2 failure"
              >
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-table-pricing">
          <div className="pricing-grid">
            <div className="pricing-table">
              <div className="div-block-81">
                <div className="text-block-18">Framed Poster</div>
                <div className="text-block-19">#4608268468282</div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$19.42</div>
            </div>
            <div className="pricing-head-table">
              <div className="div-block-87">
                <div className="text-block-standard light">$ </div>
                <div className="divider horizontal"></div>
                <div className="form-block-4 w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    aria-label="Email Form 2"
                  >
                    <input
                      type="number"
                      className="number-input input w-input"
                      maxLength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      id="name-2"
                    />
                  </form>
                  <div
                    className="w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 failure"
                  >
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$62.08</div>
            </div>
          </div>
          <div className="pricing-grid">
            <div className="pricing-table">
              <div className="div-block-81">
                <div className="text-block-18">Framed Poster</div>
                <div className="text-block-19">#4608268468282</div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$19.42</div>
            </div>
            <div className="pricing-head-table">
              <div className="div-block-87">
                <div className="text-block-standard light">$ </div>
                <div className="divider horizontal"></div>
                <div className="form-block-4 w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    aria-label="Email Form 2"
                  >
                    <input
                      type="number"
                      className="number-input input w-input"
                      maxLength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      id="name-2"
                    />
                  </form>
                  <div
                    className="w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 failure"
                  >
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$62.08</div>
            </div>
          </div>
          <div className="pricing-grid">
            <div className="pricing-table">
              <div className="div-block-81">
                <div className="text-block-18">Framed Poster</div>
                <div className="text-block-19">#4608268468282</div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$19.42</div>
            </div>
            <div className="pricing-head-table">
              <div className="div-block-87">
                <div className="text-block-standard light">$ </div>
                <div className="divider horizontal"></div>
                <div className="form-block-4 w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    aria-label="Email Form 2"
                  >
                    <input
                      type="number"
                      className="number-input input w-input"
                      maxLength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      id="name-2"
                    />
                  </form>
                  <div
                    className="w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 failure"
                  >
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$62.08</div>
            </div>
          </div>
          <div className="pricing-grid">
            <div className="pricing-table">
              <div className="div-block-81">
                <div className="text-block-18">Framed Poster</div>
                <div className="text-block-19">#4608268468282</div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$19.42</div>
            </div>
            <div className="pricing-head-table">
              <div className="div-block-87">
                <div className="text-block-standard light">$ </div>
                <div className="divider horizontal"></div>
                <div className="form-block-4 w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    aria-label="Email Form 2"
                  >
                    <input
                      type="number"
                      className="number-input input w-input"
                      maxLength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      id="name-2"
                    />
                  </form>
                  <div
                    className="w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 failure"
                  >
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$62.08</div>
            </div>
          </div>
          <div className="pricing-grid">
            <div className="pricing-table">
              <div className="div-block-81">
                <div className="text-block-18">Framed Poster</div>
                <div className="text-block-19">#4608268468282</div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$19.42</div>
            </div>
            <div className="pricing-head-table">
              <div className="div-block-87">
                <div className="text-block-standard light">$ </div>
                <div className="divider horizontal"></div>
                <div className="form-block-4 w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    aria-label="Email Form 2"
                  >
                    <input
                      type="number"
                      className="number-input input w-input"
                      maxLength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      id="name-2"
                    />
                  </form>
                  <div
                    className="w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 failure"
                  >
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$62.08</div>
            </div>
          </div>
          <div className="pricing-grid">
            <div className="pricing-table">
              <div className="div-block-81">
                <div className="text-block-18">Framed Poster</div>
                <div className="text-block-19">#4608268468282</div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$19.42</div>
            </div>
            <div className="pricing-head-table">
              <div className="div-block-87">
                <div className="text-block-standard light">$ </div>
                <div className="divider horizontal"></div>
                <div className="form-block-4 w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    aria-label="Email Form 2"
                  >
                    <input
                      type="number"
                      className="number-input input w-input"
                      maxLength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      id="name-2"
                    />
                  </form>
                  <div
                    className="w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 failure"
                  >
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$62.08</div>
            </div>
          </div>
          <div className="pricing-grid">
            <div className="pricing-table">
              <div className="div-block-81">
                <div className="text-block-18">Framed Poster</div>
                <div className="text-block-19">#4608268468282</div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$19.42</div>
            </div>
            <div className="pricing-head-table">
              <div className="div-block-87">
                <div className="text-block-standard light">$ </div>
                <div className="divider horizontal"></div>
                <div className="form-block-4 w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    aria-label="Email Form 2"
                  >
                    <input
                      type="number"
                      className="number-input input w-input"
                      maxLength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      id="name-2"
                    />
                  </form>
                  <div
                    className="w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 failure"
                  >
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$62.08</div>
            </div>
          </div>
          <div className="pricing-grid">
            <div className="pricing-table">
              <div className="div-block-81">
                <div className="text-block-18">Framed Poster</div>
                <div className="text-block-19">#4608268468282</div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$19.42</div>
            </div>
            <div className="pricing-head-table">
              <div className="div-block-87">
                <div className="text-block-standard light">$ </div>
                <div className="divider horizontal"></div>
                <div className="form-block-4 w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    aria-label="Email Form 2"
                  >
                    <input
                      type="number"
                      className="number-input input w-input"
                      maxLength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      id="name-2"
                    />
                  </form>
                  <div
                    className="w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 failure"
                  >
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$62.08</div>
            </div>
          </div>
          <div className="pricing-grid">
            <div className="pricing-table">
              <div className="div-block-81">
                <div className="text-block-18">Framed Poster</div>
                <div className="text-block-19">#4608268468282</div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$19.42</div>
            </div>
            <div className="pricing-head-table">
              <div className="div-block-87">
                <div className="text-block-standard light">$ </div>
                <div className="divider horizontal"></div>
                <div className="form-block-4 w-form">
                  <form
                    id="email-form-2"
                    name="email-form-2"
                    data-name="Email Form 2"
                    method="get"
                    aria-label="Email Form 2"
                  >
                    <input
                      type="number"
                      className="number-input input w-input"
                      maxLength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder=""
                      id="name-2"
                    />
                  </form>
                  <div
                    className="w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Email Form 2 failure"
                  >
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-head-table">
              <div className="text-block-standard light">$62.08</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Pricing
