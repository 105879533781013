import { isArray } from 'lodash'

const ErrorMessage = ({ errors }) => {
  if (!errors) return null
  return (
    <div
      className="error-message w-form-fail"
      style={{
        display: 'block',
        maxWidth: '100%',
        width: '100%',
      }}
    >
      {isArray(errors) ? (
        // eslint-disable-next-line react/no-array-index-key
        errors.map((error, index) => <div key={`error-${index}`}>{error}</div>)
      ) : (
        <div>{errors}</div>
      )}
    </div>
  )
}

export default ErrorMessage
