/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import { useCallback } from 'react'
import cn from 'classnames'
import { useToggle } from 'hooks'
import { useDropzone } from 'react-dropzone'

import { ReactComponent as UploadIcon } from 'images/icons/upload.svg'
import ErrorMessage from 'components/common/ErrorMessage'

const CertificateUpload = ({
  onCertificateUpload,
  onNoTennesseeUser,
  loading,
  apiError,
  success,
}) => {
  const [showUpload, , setShowUpload] = useToggle(true)
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => onCertificateUpload(file))
  }, [])

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: '.pdf',
    maxFiles: 1,
    onDrop,
  })

  return (
    <div className="form-block-3 w-form">
      <form id="email-form" className="form-9">
        <div className="title-info-card center">Is your business located in Tennessee?</div>
        <div className="container-center">
          <div className="form-input select-field max-width-100px">
            <div className="select-field-wrapper">
              <select
                data-name="Field 3"
                name="field-3"
                className="form-select-field w-select"
                onChange={(e) => setShowUpload(e.target.value)}
              >
                <option value="yes">Yes</option>
                <option value="">No</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-item-2">
          <div className="text-input__field-wrapper" />
        </div>
        <div className="form-item-3 centered">
          <div>
            If yes, we require a copy of your Blanket Certificate of Resale
            <br />
            <br />
            If no, then you can click <span className="text-span-20">'SUBMIT'</span>
          </div>
        </div>
        {showUpload && (
          <div
            role="button"
            {...getRootProps()}
            onClick={open}
            onKeyPress={open}
            className="link-block-8-2 secondary w-inline-block upload-div"
          >
            <input {...getInputProps()} />
            <div className="icon right-margin w-embed">
              <UploadIcon />
            </div>
            <div>Upload Certificate of Resale</div>
          </div>
        )}

        <div className="divider-16px" />
        <div className="text-block-34">
          By entering your email, you consent to receive email updates from MUSEA.
        </div>
        <div className="divider-16px" />
        <div className="divider-16px" />
        <button
          type="submit"
          disabled={showUpload ? loading || !success : false}
          className={cn('button-2 main-button w-button', {
            disabled: showUpload ? loading || !success : false,
          })}
          onClick={showUpload ? onCertificateUpload : onNoTennesseeUser}
        >
          {loading ? 'Please wait...' : 'Submit Application'}
        </button>
      </form>
      {apiError && (
        <div className="form-item-2">
          <ErrorMessage errors={apiError} />
        </div>
      )}
    </div>
  )
}

export default CertificateUpload
