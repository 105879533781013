import { Link } from 'react-router-dom'
import { useLottie } from 'hooks'

import animationData from 'animations/checkout.json'
import { ReactComponent as HomeIcon } from 'images/icons/home.svg'

const lottieOptions = {
  animationData,
  loop: true,
  autoplay: true,
  renderer: 'svg',
  direction: 1,
  duration: 0,
}

const ThankYouMessage = () => {
  const [, container] = useLottie(lottieOptions)

  return (
    <div className="thanks-wrapper" style={{ opacity: 1, display: 'flex' }}>
      <div className="div-block-248">
        <div
          ref={container}
          data-w-id="bae123d8-b18e-8791-8521-8b7fbad9723d"
          className="lottie-animation-3"
        />
        <h1 className="heading-9">
          Thank You for your order
          <br />
          and for believing in the power of prints!
        </h1>
        <div>You should receive an order confirmation in your email inbox very soon.</div>
        <div className="divider _24px" />
        <Link to="/" className="button-2 secondary no-width w-inline-block">
          <div className="icon right-margin w-embed">
            <HomeIcon />
          </div>
          <div>Go Back home</div>
        </Link>
      </div>
    </div>
  )
}

export default ThankYouMessage
