import { useEffect } from 'react'

const useOutsideClickHandler = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  const handleEscapeKey = (e) => {
    const { keyCode } = e || window.event

    if (keyCode === 27 && ref.current) {
      return callback()
    }
    return null
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    document.addEventListener('keydown', handleEscapeKey)

    return () => {
      document.removeEventListener('click', handleClick)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  })
}

export default useOutsideClickHandler
