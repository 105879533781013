import { forwardRef } from 'react'

const TextInput = forwardRef(({ children, ...rest }, ref) => (
  <div
    id="w-node-_13845923-1f53-7434-fb6b-fbd05663125e-0ce80a13"
    className="input-container paper-type"
  >
    <div className="label-container">
      <div className="div-block-150">
        <div className="sub-label-form">{rest.label}</div>
      </div>
    </div>
    <div className="form-block-input w-form">
      <div
        id="email-form"
        name="email-form"
        data-name="Email Form"
        className="form-input select-field"
      >
        <div className="select-field-wrapper">
          <input
            {...rest}
            ref={ref}
            type="text"
            className="text-field-2 font-size-14 w-input"
            maxLength="256"
          />
          {children}
        </div>
      </div>
    </div>
  </div>
))

export default TextInput
