import { useRef } from 'react'
import cn from 'classnames'
import { useOutsideClickHandler, useToggle } from 'hooks'

import { ReactComponent as RestartIcon } from 'images/icons/restart.svg'
import { LAB_ORDER_STATUS } from 'lib/const'
import InputCheckbox from './InputCheckbox'

const StatusDropdown = ({ onChange, onReset, activeFilters }) => {
  const ref = useRef()
  const [isOpen, toggleState, setToggleState] = useToggle(false)

  useOutsideClickHandler(ref, () => setToggleState(false))

  return (
    <div
      id="w-node-_8afe4b18-289a-31ff-3e3f-c4f5718c11c8-2283c889"
      className="w-dropdown"
      role="button"
      onClick={toggleState}
      onKeyPress={toggleState}
      ref={ref}
    >
      <div
        className={cn('filter-select-field w-dropdown-toggle', {
          'w--open': isOpen,
        })}
      >
        <div>Status</div>
        <div className="icon-2 w-icon-dropdown-toggle" />
      </div>
      <nav
        className={cn('dropdown-list w-dropdown-list', {
          'w--open': isOpen,
        })}
      >
        <div className="form-block-filter w-form">
          <form id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-3">
            {Object.keys(LAB_ORDER_STATUS).map((option) => (
              <InputCheckbox
                key={option}
                label={LAB_ORDER_STATUS[option].label}
                value={option}
                type="status"
                checked={activeFilters.includes(option)}
                onClick={onChange}
              />
            ))}
          </form>
        </div>
        <div className="container-info-filter bg" onClick={onReset} role="button">
          <div className="icon small right-margin x2 w-embed">
            <RestartIcon />
          </div>
          <div>Reset All</div>
        </div>
      </nav>
    </div>
  )
}

export default StatusDropdown
