/* eslint-disable no-confusing-arrow */
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tippy'

const TopButton = ({ label, to, icon: Icon, withTooltip }) =>
  withTooltip ? (
    <Tooltip title={label}>
      <Link to={to} className="white-button link-block-3 green shadow tippy w-inline-block">
        {Icon && (
          <div className="icon small w-embed">
            <Icon />
          </div>
        )}
      </Link>
    </Tooltip>
  ) : (
    <Link to={to} className="link-block-3 green shadow w-inline-block">
      {Icon && (
        <div className="icon small w-embed">
          <Icon />
        </div>
      )}
      {label && (
        <div className="text-button">
          <div>{label}</div>
        </div>
      )}
    </Link>
  )

export default TopButton
