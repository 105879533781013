import React, { useState, useEffect, useCallback } from 'react'
import ReactModal from 'react-modal'

import { isFunction } from 'lib/utils'
import { DEFAULT_ANIMATION } from 'lib/const'

import { ReactComponent as AlertIcon } from 'images/icons/alert.svg'
import { ReactComponent as CloseIcon } from 'images/icons/close.svg'
import { ReactComponent as AcceptIcon } from 'images/icons/accept.svg'

const customStyles = {
  overlay: {
    background: 'none',
    zIndex: '9999999',
    inset: 0,
  },
  content: {
    inset: 0,
    border: 0,
    background: 'unset',
  },
}

export default () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    ReactModal.setAppElement('body')
  }, [])

  const Modal = ({ handleCloseModal, onContinue }) => {
    const onCloseModal = useCallback(() => {
      setIsOpen(false)
      isFunction(handleCloseModal) && handleCloseModal()
    }, [handleCloseModal])

    return (
      <ReactModal isOpen={isOpen} onRequestClose={onCloseModal} style={customStyles}>
        <div
          className="hide-me-overlay"
          style={{
            display: isOpen ? 'flex' : 'none',
            opacity: isOpen ? 1 : 0,
            transition: DEFAULT_ANIMATION,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className="div-block-270"
            style={{
              transition: 'all 400ms ease-in 0s',
              transform: isOpen
                ? 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
                : 'translate3d(0px, 50px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            }}
          >
            <div className="div-block-271">
              <div className="sub-heading-container-2">
                <div className="icon right-margin w-embed">
                  <AlertIcon />
                </div>
                <h2 className="heading-title-2-2">Confirm Action</h2>
              </div>
            </div>
            <div className="div-block-272">
              <p>Are you sure you want to do this?</p>
            </div>
            <div className="div-block-273">
              <div className="w-layout-grid grid-74">
                <button
                  type="button"
                  className="button-2-2 secondary w-inline-block"
                  onClick={onCloseModal}
                  onKeyPress={onCloseModal}
                >
                  <div className="icon smaller right-margin w-embed">
                    <CloseIcon />
                  </div>
                  <div>Cancel</div>
                </button>
                <button
                  type="button"
                  className="button-2-2 w-inline-block"
                  onClick={onContinue}
                  onKeyPress={onContinue}
                >
                  <div className="icon small right-margin w-embed">
                    <AcceptIcon />
                  </div>
                  <div>Confirm Action</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }

  return { Modal, setIsOpen }
}
