import { ReactComponent as NoteIcon } from 'images/icons/note.svg'
import { ReactComponent as EditIcon } from 'images/icons/edit-alternative.svg'

const PhotographerAdminNotes = () => (
  <div className="client-account-block2">
    <div className="accordion-item-block no-margin">
      <div className="sub-heading-container both-edges">
        <div className="div-block-278">
          <div className="icon right-margin w-embed">
            <NoteIcon />
          </div>
          <h2 className="heading-title-2">Admin Notes</h2>
        </div>
      </div>
    </div>

    <div className="div-block-290">
      <div className="gray-block">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros
          elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
          commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem
          imperdiet. Nunc ut sem vitae risus tristique posuere.
        </p>
      </div>
    </div>

    <div id="w-node-b92431e6-1250-9465-1635-0b8636fbfd6d-41e80a1e">
      <a href="/" className="button-2 secondary w-inline-block">
        <div className="icon small right-margin w-embed">
          <EditIcon />
        </div>
        <div>Add / Edit Note</div>
      </a>
    </div>
  </div>
)

export default PhotographerAdminNotes
