import { useCallback } from 'react'

import { useToggle } from 'hooks'
import { LAB_ORDER_ATTRIBUTES } from 'lib/const'

import { ReactComponent as InfoIcon } from 'images/icons/info.svg'
import { ReactComponent as ChevronDownIcon } from 'images/icons/chevron-down.svg'
import { useCheckout } from '../../useCheckout'

import AddCreditCard from './AddCreditCard'

const NEW_CARD = 'newCard'

const CreditCard = () => {
  const { updateOrder, profile, order } = useCheckout()
  const [showNewCard, toggleShowNewCard, setShowNewCard] = useToggle(false)

  const orderWithCard = order?.data?.attributes?.selectedCardToUse

  const savedCards = profile?.cards?.data

  const handleChange = useCallback((e) => {
    const selectedCardToUse = e.target.value
    if (selectedCardToUse === NEW_CARD) {
      toggleShowNewCard()
    } else {
      setShowNewCard(false)
      updateOrder({
        type: LAB_ORDER_ATTRIBUTES.selectedCardToUse,
        value: selectedCardToUse,
      })
    }
  }, [])

  return (
    <>
      <div
        id="w-node-_22c1976e-78a7-2d1b-8a12-41db4b10a94a-5683c88d"
        className="input-container paper-type"
      >
        <div className="label-container">
          <div className="label-subcontainer">
            <div className="sub-label-form">card in use</div>
            <div className="icon mini margin-left opacity70 w-embed">
              <InfoIcon />
            </div>
          </div>
        </div>
        <div className="form-block-input w-form">
          <div
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="form-input select-field"
          >
            <div className="select-field-wrapper">
              <select
                id="card"
                name="card"
                onChange={handleChange}
                className="form-select-field font-size-14 w-select"
                defaultValue={orderWithCard || ''}
              >
                {!orderWithCard && (
                  <option value="" disabled>
                    Select a credit card
                  </option>
                )}
                {savedCards?.map((card) => (
                  <option key={card.attributes.id} value={card.attributes.id}>
                    {card.attributes.maskedCard}
                  </option>
                ))}
                <option value={NEW_CARD}>Add Credit / Debit Card</option>
              </select>
              <div className="div-block-63">
                <ChevronDownIcon className="img-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showNewCard && <AddCreditCard />}
    </>
  )
}

export default CreditCard
