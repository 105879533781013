import dayjs from 'dayjs'

export const TOKEN = 'token'

export const SUPPORTED_LANGUAGES = ['en']
export const DEFAULT_LANGUAGE = 'en'

export const DEFAULT_ANIMATION = 'ease-in 400ms'

export const SCREEN_BLOCKER_QUERY = '(max-width: 990px)'

export const ACCEPTED_IMAGE_TYPES = 'image/png, image/jpg, image/jpeg'

export const DATE_FORMAT = {
  monthDayYear: 'MM/DD/YY ',
  full: 'MMMM D, YYYY h:mm A',
  filter: 'YYYY-MM-DD',
}

export const QUERYSTRING_PARSE_FORMAT = {
  arrayFormat: 'bracket',
  skipNull: true,
  skipEmptyString: true,
}
export const DEFAULT_DEBOUNCE = 300

export const usStates = [
  { name: 'ALABAMA', abbreviation: 'AL' },
  { name: 'ALASKA', abbreviation: 'AK' },
  { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
  { name: 'ARIZONA', abbreviation: 'AZ' },
  { name: 'ARKANSAS', abbreviation: 'AR' },
  { name: 'CALIFORNIA', abbreviation: 'CA' },
  { name: 'COLORADO', abbreviation: 'CO' },
  { name: 'CONNECTICUT', abbreviation: 'CT' },
  { name: 'DELAWARE', abbreviation: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
  { name: 'FLORIDA', abbreviation: 'FL' },
  { name: 'GEORGIA', abbreviation: 'GA' },
  { name: 'GUAM', abbreviation: 'GU' },
  { name: 'HAWAII', abbreviation: 'HI' },
  { name: 'IDAHO', abbreviation: 'ID' },
  { name: 'ILLINOIS', abbreviation: 'IL' },
  { name: 'INDIANA', abbreviation: 'IN' },
  { name: 'IOWA', abbreviation: 'IA' },
  { name: 'KANSAS', abbreviation: 'KS' },
  { name: 'KENTUCKY', abbreviation: 'KY' },
  { name: 'LOUISIANA', abbreviation: 'LA' },
  { name: 'MAINE', abbreviation: 'ME' },
  { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
  { name: 'MARYLAND', abbreviation: 'MD' },
  { name: 'MASSACHUSETTS', abbreviation: 'MA' },
  { name: 'MICHIGAN', abbreviation: 'MI' },
  { name: 'MINNESOTA', abbreviation: 'MN' },
  { name: 'MISSISSIPPI', abbreviation: 'MS' },
  { name: 'MISSOURI', abbreviation: 'MO' },
  { name: 'MONTANA', abbreviation: 'MT' },
  { name: 'NEBRASKA', abbreviation: 'NE' },
  { name: 'NEVADA', abbreviation: 'NV' },
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
  { name: 'NEW JERSEY', abbreviation: 'NJ' },
  { name: 'NEW MEXICO', abbreviation: 'NM' },
  { name: 'NEW YORK', abbreviation: 'NY' },
  { name: 'NORTH CAROLINA', abbreviation: 'NC' },
  { name: 'NORTH DAKOTA', abbreviation: 'ND' },
  { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
  { name: 'OHIO', abbreviation: 'OH' },
  { name: 'OKLAHOMA', abbreviation: 'OK' },
  { name: 'OREGON', abbreviation: 'OR' },
  { name: 'PALAU', abbreviation: 'PW' },
  { name: 'PENNSYLVANIA', abbreviation: 'PA' },
  { name: 'PUERTO RICO', abbreviation: 'PR' },
  { name: 'RHODE ISLAND', abbreviation: 'RI' },
  { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
  { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
  { name: 'TENNESSEE', abbreviation: 'TN' },
  { name: 'TEXAS', abbreviation: 'TX' },
  { name: 'UTAH', abbreviation: 'UT' },
  { name: 'VERMONT', abbreviation: 'VT' },
  { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
  { name: 'VIRGINIA', abbreviation: 'VA' },
  { name: 'WASHINGTON', abbreviation: 'WA' },
  { name: 'WEST VIRGINIA', abbreviation: 'WV' },
  { name: 'WISCONSIN', abbreviation: 'WI' },
  { name: 'WYOMING', abbreviation: 'WY' },
]

export const LAB_ORDER_ATTRIBUTES = {
  shippingAddressId: 'shipping_address_id',
  localPickup: 'local_pickup',
  selectedCardToUse: 'selected_card_to_use',
  discountCode: 'discount_code',
  boutiqueShipping: 'boutique_shipping',
}

export const LAB_ORDER_STATUS = {
  open: { label: 'Open' },
  paid: { label: 'Paid' },
  printing: { label: 'Printing' },
  framing: { label: 'Framing' },
  canceled: { label: 'Canceled' },
  shipped: { label: 'Shipped' },
  reprinting: { label: 'Reprinting' },
}

export const LAB_ORDER_NOTES = {
  urgent: 'urgent',
  adminNote: 'admin_note',
  orderNote: 'order_note',
}

export const USER_STATUS = {
  approved: 'approved',
  declined: 'declined',
  pending: 'pending',
}

export const TODAY = dayjs().format(DATE_FORMAT.filter)
export const YESTERDAY = dayjs().subtract(1, 'days').format(DATE_FORMAT.filter)
export const THIS_WEEK = dayjs().subtract(7, 'days').format(DATE_FORMAT.filter)
export const LAST_30_DAYS = dayjs().subtract(30, 'days').format(DATE_FORMAT.filter)
export const LAST_3_MONTHS = dayjs().subtract(3, 'months').format(DATE_FORMAT.filter)

export const DATE_FILTERS = {
  today: { label: 'Today', startDate: TODAY, endDate: TODAY },
  yesterday: { label: 'Yesterday', startDate: YESTERDAY, endDate: YESTERDAY },
  thisWeek: { label: 'This Week', startDate: THIS_WEEK, endDate: TODAY },
  last30Days: { label: 'Last 30 days', startDate: LAST_30_DAYS, endDate: TODAY },
  last3Months: { label: 'Last 3 Months', startDate: LAST_3_MONTHS, endDate: TODAY },
}

export const PAPER_TYPES = {
  lustre: { label: 'Lustre', abbreviation: 'L' },
  sating: { label: 'Satin', abbreviation: 'S' },
  photo_matte: { label: 'Photo Matte', abbreviation: 'PM' },
  ultra_smooth: { label: 'Ultra Smooth', abbreviation: 'US' },
  platine: { label: 'Platine', value: 'platine', abbreviation: 'PT' },
  canson_rag: { label: 'Canson Rag', abbreviation: 'CR' },
  museum_etching: { label: 'Museum Etching', abbreviation: 'ME' },
  deckled: { label: 'Deckled', abbreviation: 'DKL' },
}

export const SERVICE_TYPES = {
  mat: { label: 'Matting', abbreviation: 'MAT', iconClassname: 'matting' },
  mount: { label: 'Mounting', abbreviation: 'MNT', iconClassname: 'mounting' },
  frame: { label: 'Framing', abbreviation: 'FRM', iconClassname: 'framing' },
  canvas: { label: 'Canvas', abbreviation: 'CNV', iconClassname: 'canvas' },
  api_order: { label: 'API Orders', abbreviation: 'API', iconClassname: 'api' },
  test_print: {
    label: 'Test Prints',

    abbreviation: 'TP',
    iconClassname: 'test-prints',
  },
  boutique: {
    label: 'Boutique',

    abbreviation: 'BTQ',
    iconClassname: 'boutique',
  },
  artist_proof: {
    label: 'Artist Proofs',
    abbreviation: 'AP',
    iconClassname: 'calibration',
  },
}

export const ORDER_BY = {
  'a-z_asc': { label: 'Ascending (A-Z)' },
  'a-z_desc': { label: 'Descending (Z-A)' },
  paid_at_desc: { label: 'Newest to Oldest' },
  paid_at_asc: { label: 'Oldest to Newest' },
}

export const SHIPPING_METHODS = {
  twoDay: 'two_day',
  overnight: 'overnight',
}

export const SIGN_UP_STEPS = {
  registration: 1,
  referral: 2,
  resaleCertificate: 3,
}

export const PROFILE_RULES = {
  firstName: {
    required: {
      value: true,
      message: 'Please enter your first name.',
    },
  },
  lastName: {
    required: {
      value: true,
      message: 'Please enter your last name.',
    },
  },
  email: {
    required: {
      value: true,
      message: 'Please enter your email.',
    },
  },
  phone: {
    required: {
      value: true,
      message: 'Please enter your phone number.',
    },
  },
  businessUrl: {
    required: {
      value: false,
      message: 'Please enter your Website or Social Media URL.',
    },
  },
  password: {
    required: { value: true, message: 'Password is required' },
  },
  confirmPassword: {
    required: { value: true, message: 'Password is required' },
  },
  businessName: {
    required: { value: false },
  },
}
