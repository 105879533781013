import { useState } from 'react'
import { useForm } from 'react-hook-form'
import httpClient from 'httpClient'
import classNames from 'classnames'

import { apiErrorMessage } from 'lib/utils'

import ErrorMessage from 'components/common/ErrorMessage'
import Heading from 'components/common/Heading'

const ForgotPasswordPage = () => {
  const { register, handleSubmit } = useForm()
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSubmit = async (data) => {
    setApiError(null)
    setLoading(true)
    setSuccess(false)

    try {
      await httpClient.post('check_for_email', data)
      await httpClient.post('forgot_password', data)
      setLoading(false)
      setSuccess(true)
    } catch (error) {
      setApiError(apiErrorMessage(error))
      setLoading(false)
    }
  }

  return (
    <div className="div-full-screen white-smoke">
      <div className="div-block-183">
        <Heading title="Forgot Password?" />
        {!success && (
          <div className="div-block-184">
            <div className="form-item-3">
              <div className="text-block-34">
                Enter your email address to receive a recover link
              </div>
            </div>
            <div className="divider-16px" />
          </div>
        )}
        <div className="form-block-3 w-form">
          {!success && (
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form-9"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-item-2">
                <div className="text-input__field-wrapper">
                  <input
                    type="email"
                    className="text-input-2 w-input"
                    maxLength="256"
                    name="Password-2"
                    data-name="Password 2"
                    placeholder="john@email.com"
                    id="Password-2"
                    required=""
                    {...register('email')}
                  />
                </div>
              </div>
              <input
                type="submit"
                value={loading ? 'Please wait...' : 'Submit Now'}
                className={classNames('main-button button-2 more-margin w-button', {
                  disabled: loading,
                })}
                disabled={loading}
              />
            </form>
          )}
          <div
            className="success-message w-form-done"
            style={{ display: success ? 'block' : 'none' }}
          >
            <div className="div-block-274">
              <div
                data-w-id="581f60d7-6020-c00f-9b6d-588473ea971c"
                data-animation-type="lottie"
                data-src="documents/lf20_fkqmc2ur.json"
                data-loop="0"
                data-direction="1"
                data-autoplay="1"
                data-is-ix2-target="0"
                data-renderer="svg"
                data-default-duration="2"
                data-duration="0"
                className="lottie-animation-4"
              />
              <div className="text-block-36">
                <span className="text-span-5">Thank you!</span>
                <br />
                Your submission has been received!
              </div>
            </div>
          </div>
          {apiError && <ErrorMessage errors={apiError} />}
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordPage
