import { useLottie } from 'hooks'
import animationData from 'animations/loading.json'

const lottieOptions = {
  loop: true,
  direction: 1,
  autoplay: true,
  renderer: 'svg',
  animationData,
  duration: 2.125,
}

const Loading = ({ message }) => {
  const [, container] = useLottie(lottieOptions)
  return (
    <div className="img-container-print-order">
      <div className="loading-container">
        <div className="loading-div">
          <div ref={container} id="c521c7fc-da03-0479-208e-9b8b5df9da78" />
        </div>
        <div className="divider-16px" />
        <h2 className="heading-title-2">{message}</h2>
      </div>
    </div>
  )
}

export default Loading
