import { dollarTemplate } from 'lib/utils'

const LineItem = ({ lineItem }) => (
  <div className="w-layout-grid grid-order-summary-container single">
    <div className="w-layout-grid grid-element-order-summary client less">
      <div id="w-node-ff43ca08-2906-f71c-d8e7-5ad72bc9fdd5-fde80a15" className="content">
        <div className="div-block-136">
          <img
            src={lineItem.photographCroppedUrl}
            loading="lazy"
            sizes="(max-width: 479px) 50px, (max-width: 1279px) 80px, (max-width: 1439px) 6vw, 80px"
            srcSet="https://assets.website-files.com/6059c05c1023d6a08a74c8b5/605cb190d27fc16bae6b6100_default-placeholder-p-500.jpeg 500w, https://assets.website-files.com/6059c05c1023d6a08a74c8b5/605cb190d27fc16bae6b6100_default-placeholder-p-800.jpeg 800w, https://assets.website-files.com/6059c05c1023d6a08a74c8b5/605cb190d27fc16bae6b6100_default-placeholder-p-1080.jpeg 1080w, https://assets.website-files.com/6059c05c1023d6a08a74c8b5/605cb190d27fc16bae6b6100_default-placeholder.jpg 1500w"
            alt=""
            className="img-order-summary"
          />
        </div>
      </div>
      <div className="content print-details">
        <div>
          <div className="text-table">{`${lineItem.printSizeAttributes.paper} ${lineItem.printSizeAttributes.printSize}`}</div>
          <div className="w-layout-grid grid-23 main">
            <div className="icon smaller w-embed">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                <rect width="256" height="256" fill="none" />
                <path d="M209.65479,122.3418a8,8,0,0,1-.00733,11.31347l-82.05322,81.94629a56.00006,56.00006,0,0,1-79.1919-79.20019L147.6709,35.751A39.99954,39.99954,0,1,1,204.27832,92.2793l-99.269,100.65136A23.99954,23.99954,0,1,1,71.02979,159.0293L154.291,74.38477a8.0001,8.0001,0,0,1,11.40625,11.2207L82.38965,170.29688a8,8,0,1,0,11.2666,11.36035L192.92578,81.00488a23.99971,23.99971,0,1,0-33.90185-33.97949L59.75488,147.67578a40.00024,40.00024,0,1,0,56.5293,56.6084L198.34082,122.335A7.99885,7.99885,0,0,1,209.65479,122.3418Z" />
              </svg>
            </div>
            <div>{lineItem.photoFileName}</div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="text-table-top-container">
          <div className="text-table">{lineItem?.finishingService?.formattedDetailOne}</div>
          <div className="text-table">{lineItem?.finishingService?.formattedDetailTwo}</div>
          <div className="text-table">{lineItem?.finishingService?.formattedDetailThree}</div>
        </div>
      </div>
      <div className="content">
        <div>
          <div className="text-table red">{lineItem.notes}</div>
        </div>
      </div>
      <div id="w-node-ff43ca08-2906-f71c-d8e7-5ad72bc9fe03-fde80a15" className="content">
        <div className="text-table red">{lineItem.quantity}</div>
      </div>
      <div className="content">
        <div className="text-table price">{dollarTemplate(lineItem.itemTotal)}</div>
      </div>
    </div>
  </div>
)

export default LineItem
