import { useState, useEffect, useCallback } from 'react'

import httpClient from 'httpClient'
import { apiErrorMessage } from 'lib/utils'

const useProfile = () => {
  const [profile, setProfile] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchUser = async () => {
    try {
      setLoading(true)
      const user = await httpClient('profile')
      setProfile(user)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setError(apiErrorMessage(err))
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  const onProfileUpdate = useCallback(
    (data) => {
      setProfile(data)
    },
    [profile],
  )

  return { profile, error, loading, onProfileUpdate, refetchUser: fetchUser }
}

export default useProfile
