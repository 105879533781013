import { useState, useCallback } from 'react'
import httpClient from 'httpClient'
import { useForm, Controller } from 'react-hook-form'
import values from 'lodash/values'
import classNames from 'classnames'

import { ReactComponent as VisibilityIcon } from 'images/icons/visibility.svg'
import { apiErrorMessage } from 'lib/utils'

import ErrorMessage from 'components/common/ErrorMessage'
import Heading from 'components/common/Heading'

const ResetPasswordPage = () => {
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm()
  const [displayPassword, setDisplayPassword] = useState(false)
  const [displayPasswordConfirmation, setDisplayPasswordConfirmation] = useState(false)

  const onSubmit = useCallback(async ({ challengeToken, email, password }) => {
    setLoading(true)
    setSuccess(null)
    setApiError(null)
    try {
      await httpClient.post('check_for_email', { email })
      await httpClient.post('validate_challenge_token', {
        email,
        challengeToken,
      })
      await httpClient.post('reset_password', {
        email,
        password,
      })
      setLoading(false)
      setSuccess(true)
    } catch (error) {
      setApiError(apiErrorMessage(error))
      setLoading(false)
    }
  }, [])

  return (
    <div className="div-full-screen white-smoke">
      <div className="div-block-183 reset">
        <Heading title="Reset Password" />
        <div className="form-block-3 max-width-320px w-form">
          <div className="form-block-3 w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form-9"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="title-info-card-2 center">
                Confirm your registration by entering the 6-digit token and email address
              </div>
              <div className="divider-16px" />

              <Controller
                rules={{
                  required: { value: true, message: 'New password is required' },
                }}
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="form-item-2">
                    <div className="text-input__field-wrapper">
                      <input
                        {...field}
                        type={displayPassword ? 'text' : 'password'}
                        className="text-input-2 w-input"
                        placeholder="New Password"
                        id="Password"
                      />
                      <div
                        className="eye-2"
                        onClick={() => setDisplayPassword((prev) => !prev)}
                        role="button"
                      >
                        <div className="icon w-embed">
                          <VisibilityIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              />

              <Controller
                rules={{
                  required: { value: true, message: 'Confirm new password is required' },
                  validate: (value) =>
                    value === watch('password') ||
                    "Confirmation password doesn't match new password",
                }}
                name="confirmNewPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="form-item-2">
                    <div className="text-input__field-wrapper">
                      <input
                        {...field}
                        type={displayPasswordConfirmation ? 'text' : 'password'}
                        className="text-input-2 w-input"
                        placeholder="Confirm New Password"
                        id="confirmNewPassword"
                        disabled={isSubmitting}
                      />
                      <div
                        className="eye-2"
                        onClick={() => setDisplayPasswordConfirmation((prev) => !prev)}
                        role="button"
                      >
                        <div className="icon w-embed">
                          <VisibilityIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              />

              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter the challenge token we sent you by email.',
                  },
                }}
                name="challengeToken"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="form-item-2">
                    <div className="text-input__field-wrapper">
                      <input
                        {...field}
                        type="text"
                        className="text-input-2 w-input"
                        placeholder="Enter Token Number"
                        id="challengeToken"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                )}
              />

              <Controller
                rules={{
                  required: { value: true, message: 'Please fill with your email.' },
                }}
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="form-item-2">
                    <div className="text-input__field-wrapper">
                      <input
                        {...field}
                        type="email"
                        className="text-input-2 w-input"
                        placeholder="Enter Email Address"
                        id="email"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                )}
              />

              {(values(errors) || apiError) && (
                <div className="form-item-2">
                  <ErrorMessage errors={values(errors)?.[0]?.message || apiError} />
                </div>
              )}

              {!success && (
                <button
                  type="submit"
                  disabled={isSubmitting || loading}
                  className={classNames('main-button button-2 w-button', {
                    disabled: loading || isSubmitting,
                  })}
                >
                  {loading || isSubmitting ? 'Please wait...' : 'Submit Now'}
                </button>
              )}
            </form>
          </div>
        </div>

        {success && (
          <div
            className="success-message w-form-done"
            aria-hidden="true"
            style={{ display: 'block' }}
          >
            <div className="div-block-274" aria-hidden="true">
              <div
                data-w-id="aac6939a-7a21-2b7a-d781-6cfc83b1d54d"
                data-animation-type="lottie"
                data-src="documents/lf20_fkqmc2ur.json"
                data-loop="0"
                data-direction="1"
                data-autoplay="1"
                data-is-ix2-target="0"
                data-renderer="svg"
                data-default-duration="2"
                data-duration="0"
                className="lottie-animation-4"
                aria-hidden="true"
              />
              <div className="text-block-36" aria-hidden="true">
                <span className="text-span-5" aria-hidden="true">
                  Thank you!
                </span>
                <br aria-hidden="true" />
                Your submission has been received!
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResetPasswordPage
