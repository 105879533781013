import { useEffect } from 'react'
import { useParams } from 'react-router'
import useOrders from 'hooks/useOrders'

import Loading from 'components/common/Loading'
import ErrorMessage from 'components/common/ErrorMessage'
import LabOrderDetailsNav from 'components/LabOrderDetails/LabOrderDetailsNav'
import LabOrderDetailsInformation from 'components/LabOrderDetails/LabOrderDetailsInformation'

const LabOrderDetailsPage = () => {
  const { id: currentOrderId } = useParams()
  const { loading, error, orders, getOrder, order } = useOrders()

  useEffect(() => {
    getOrder(currentOrderId)
  }, [currentOrderId])

  if (loading) {
    return <Loading message="Loading order" />
  }

  if (error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <div className="container-orders">
      <div className="sub-container-orders">
        <LabOrderDetailsNav orders={orders} currentOrder={currentOrderId} />
        <LabOrderDetailsInformation order={order} />
      </div>
    </div>
  )
}

export default LabOrderDetailsPage
