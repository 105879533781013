/* eslint-disable react/no-array-index-key */
/* eslint-disable operator-linebreak */
import { useState, useCallback, useEffect } from 'react'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'

import { addOrRemove, toArray } from 'lib/utils'
import {
  LAB_ORDER_NOTES,
  ORDER_BY,
  SERVICE_TYPES,
  PAPER_TYPES,
  QUERYSTRING_PARSE_FORMAT,
  LAB_ORDER_STATUS,
} from 'lib/const'

import TopButton from 'components/common/TopButton'
import { ReactComponent as CreateIcon } from 'images/icons/create.svg'
import { ReactComponent as UnbundledLineItemsIcon } from 'images/icons/unbundledLineItems.svg'
import Filter from 'components/common/Filter'
import SearchBar from './SearchBar'
import StatusDropdown from './StatusDropdown'
import OrderByDropdown from './OrderByDropdown'
import DateDropdown from './DateDropdown'
import NotesDropdown from './NotesDropdown'
import ServiceDropdown from './ServiceDropdown'
import PaperTypeDropdown from './PaperTypeDropdown'

const LabOrdersHeader = ({ isAdmin, location }) => {
  const navigate = useNavigate();

  const [status, setStatus] = useState([])
  const [paperTypes, setPaperTypes] = useState([])
  const [service, setService] = useState([])
  const [orderBy, setOrderBy] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [urgent, setUrgent] = useState(null)
  const [adminNote, setAdminNote] = useState(null)
  const [orderNote, setOrderNote] = useState(null)
  const [term, setTerm] = useState(null)

  const handleOnSearchChange = useCallback((_term) => setTerm(_term), [])

  const handleToggleStatus = (value) => setStatus((prev) => addOrRemove(prev, value))
  const handleResetStatus = useCallback(() => {
    setStatus([])
  }, [])
  const handleTogglePaperTypes = (value) => setPaperTypes((prev) => addOrRemove(prev, value))

  const handleResetPaperTypes = useCallback(() => {
    setPaperTypes([])
  }, [])
  const handleToggleService = (value) => setService((prev) => addOrRemove(prev, value))
  const handleResetService = useCallback(() => {
    setService([])
  }, [])

  const handleToggleOrderBy = useCallback((e) => setOrderBy(e.target.value), [])

  const handleToggleNotes = (value) => {
    switch (value) {
      case LAB_ORDER_NOTES.urgent:
        setUrgent((prev) => (prev ? null : value))
        break
      case LAB_ORDER_NOTES.adminNote:
        setAdminNote((prev) => (prev ? null : value))
        break
      case LAB_ORDER_NOTES.orderNote:
        setOrderNote((prev) => (prev ? null : value))
        break
      default:
        break
    }
  }

  const handleResetNotes = useCallback(() => {
    setUrgent(null)
    setAdminNote(null)
    setOrderNote(null)
  }, [])

  const handleStartDateChange = (value) => setStartDate(value)
  const handleEndDateChange = (value) => setEndDate(value)
  const handleResetDate = useCallback(() => {
    setStartDate('')
    setEndDate('')
  }, [])

  useEffect(() => {
    const queryParams = queryString.parse(location.search, QUERYSTRING_PARSE_FORMAT)
    setStatus(toArray(queryParams.status))
    setOrderBy(queryParams.orderBy)
    setTerm(queryParams.term)
    setStartDate(queryParams.start_date)
    setEndDate(queryParams.end_date)

    if (isAdmin) {
      setPaperTypes(toArray(queryParams.paperTypes))
      setService(toArray(queryParams.service))
      setUrgent(queryParams[LAB_ORDER_NOTES.urgent])
      setAdminNote(queryParams[LAB_ORDER_NOTES.adminNote])
      setOrderNote(queryParams[LAB_ORDER_NOTES.orderNote])
    }
  }, [])

  useEffect(() => {
    const search = queryString.stringify(
      {
        status,
        order_by: orderBy,
        query: term,
        ...(isAdmin && {
          papers: paperTypes,
          service,
          ...(urgent && { [LAB_ORDER_NOTES.urgent]: !!urgent }),
          ...(adminNote && { [LAB_ORDER_NOTES.adminNote]: !!adminNote }),
          ...(orderNote && { [LAB_ORDER_NOTES.orderNote]: !!orderNote }),
        }),
        start_date: startDate,
        end_date: endDate,
      },
      QUERYSTRING_PARSE_FORMAT,
    )

    navigate({ pathname: location.pathname, search }, { replace: true })
  }, [status, orderBy, term, urgent, adminNote, orderNote, paperTypes, service, startDate, endDate])

  return (
    <div className="div-block-47">
      <div className="top-menu-controls">
        <div className="grid-container-controls">
          <SearchBar onChange={handleOnSearchChange} term={term} />
          <div className={cn('w-layout-grid grid-filters', { single: !isAdmin })}>
            <StatusDropdown
              activeFilters={status}
              onChange={handleToggleStatus}
              onReset={handleResetStatus}
            />
            {isAdmin && (
              <>
                <div className="grid-vertical-line" />
                <PaperTypeDropdown
                  activeFilters={paperTypes}
                  onChange={handleTogglePaperTypes}
                  onReset={handleResetPaperTypes}
                />
                <div className="grid-vertical-line" />
                <ServiceDropdown
                  activeFilters={service}
                  onChange={handleToggleService}
                  onReset={handleResetService}
                />
              </>
            )}
          </div>
          <DateDropdown
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
            currentValues={{ endDate, startDate }}
            onReset={handleResetDate}
          />

          <OrderByDropdown onChange={handleToggleOrderBy} active={orderBy} />
          {isAdmin && (
            <NotesDropdown
              isActiveUrgent={urgent}
              isActiveAdminNotes={adminNote}
              isActiveOrderNote={orderNote}
              onChange={handleToggleNotes}
              onReset={handleResetNotes}
            />
          )}
        </div>
        <div className="top-button on-grid">
          {isAdmin && (
            <TopButton
              withTooltip
              label="Unbundled Line Items"
              to="/lab-orders/unbundled-line-items"
              icon={UnbundledLineItemsIcon}
            />
          )}
          <TopButton label="New Order" to="/print-order/create" icon={CreateIcon} />
        </div>
      </div>
      <div className="w-layout-grid grid-filter-info margin-top">
        {status.map((el, index) => (
          <Filter
            key={index}
            label={LAB_ORDER_STATUS[el].label}
            onDelete={() => handleToggleStatus(el)}
          />
        ))}
        {startDate && (
          <Filter
            label={`SINCE: ${startDate}`}
            onDelete={() => {
              setStartDate(null)
            }}
          />
        )}
        {endDate && (
          <Filter
            label={`UNTIL: ${endDate}`}
            onDelete={() => {
              setEndDate(null)
            }}
          />
        )}
        {orderBy && <Filter label={ORDER_BY[orderBy].label} onDelete={() => setOrderBy(null)} />}

        {urgent && <Filter label="Urgent" onDelete={() => setUrgent(null)} />}
        {adminNote && <Filter label="Admin Note" onDelete={() => setAdminNote(null)} />}
        {orderNote && <Filter label="Order Note" onDelete={() => setOrderNote(null)} />}

        {paperTypes.map((el, index) => (
          <Filter
            key={index}
            label={PAPER_TYPES[el].label}
            onDelete={() => handleTogglePaperTypes(el)}
          />
        ))}
        {service.map((el, index) => (
          <Filter
            key={index}
            label={SERVICE_TYPES[el].label}
            onDelete={() => handleToggleService(el)}
          />
        ))}
      </div>
    </div>
  )
}

export default LabOrdersHeader
