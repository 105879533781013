import { useCallback } from 'react'
import { useHover, useConfirmationModal } from 'hooks'
import { dollarTemplate } from 'lib/utils'

import { ReactComponent as DeleteIcon } from 'images/icons/delete.svg'
import { ReactComponent as EditIcon } from 'images/icons/edit.svg'
import placeholderImage from 'images/default-placeholder.jpg'

const Item = ({ lineItem, position, onUpdateQty, onDelete }) => {
  const [hoverRef, isHovered] = useHover()
  const { Modal, setIsOpen } = useConfirmationModal()

  const openModal = useCallback(
    (e) => {
      e.preventDefault()
      setIsOpen(true)
    },
    [setIsOpen],
  )

  return (
    <>
      <div
        id="w-node-c769d59e-763a-a11d-1b07-716415342c16-69e80a54"
        data-w-id="c769d59e-763a-a11d-1b07-716415342c16"
        className="w-layout-grid grid-element-order-summary smaller number"
        ref={hoverRef}
      >
        <div className="content center">
          <div className="div-block-266">
            <div>{Number(position + 1)}</div>
          </div>
        </div>
        <div id="w-node-c769d59e-763a-a11d-1b07-716415342c17-69e80a54" className="content">
          <div className="img-order-summary-container">
            <a href="/" className="w-inline-block w-lightbox">
              <img
                src={lineItem.photographCroppedUrl ?? placeholderImage}
                alt="Line item"
                className="img-order-summary smaller"
              />
            </a>
          </div>
        </div>
        <div className="content print-details">
          <div className="text-table-container">
            <div className="text-table smaller">{`${lineItem.printSizeAttributes.paper} ${lineItem.printSizeAttributes.printSize}`}</div>
            <div className="w-layout-grid grid-23 main">
              <div className="icon smaller w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M209.65479,122.3418a8,8,0,0,1-.00733,11.31347l-82.05322,81.94629a56.00006,56.00006,0,0,1-79.1919-79.20019L147.6709,35.751A39.99954,39.99954,0,1,1,204.27832,92.2793l-99.269,100.65136A23.99954,23.99954,0,1,1,71.02979,159.0293L154.291,74.38477a8.0001,8.0001,0,0,1,11.40625,11.2207L82.38965,170.29688a8,8,0,1,0,11.2666,11.36035L192.92578,81.00488a23.99971,23.99971,0,1,0-33.90185-33.97949L59.75488,147.67578a40.00024,40.00024,0,1,0,56.5293,56.6084L198.34082,122.335A7.99885,7.99885,0,0,1,209.65479,122.3418Z" />
                </svg>
              </div>
              <div>{lineItem.photoFileName}</div>
            </div>
            <div className="edit-mini-button">
              <div className="icon smaller right-margin w-embed">
                <EditIcon />
              </div>
              <div>Edit</div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="text-table-container">
            <div className="text-table smaller">
              {lineItem?.finishingService?.formattedDetailOne}
            </div>
            <div className="text-table smaller">
              {lineItem?.finishingService?.formattedDetailTwo}
            </div>
            <div className="text-table smaller">
              {lineItem?.finishingService?.formattedDetailThree}
            </div>
          </div>
        </div>
        <div className="content">
          <div>
            <div className="text-table red smaller">{lineItem?.notes}</div>
          </div>
        </div>

        <div className="content center">
          <div className="form-qty-mini w-form">
            <form id="email-form-3" name="email-form-3" data-name="Email Form 3">
              <input
                type="number"
                className="qty-input-mini w-input"
                maxLength="256"
                name="email"
                data-name="Email"
                placeholder="12"
                id="email"
                required=""
                onChange={(e) => onUpdateQty(lineItem?.guid, e.target.value)}
                defaultValue={lineItem?.quantity}
              />
            </form>
          </div>
        </div>

        <div id="w-node-c769d59e-763a-a11d-1b07-716415342c48-69e80a54" className="content center">
          <div className="text-table price smaller">{dollarTemplate(lineItem?.itemTotal)}</div>
        </div>
        <div
          role="button"
          style={{ opacity: isHovered ? 1 : 0 }}
          className="mini-button-delete"
          onClick={openModal}
        >
          <div className="icon smaller w-embed">
            <DeleteIcon />
          </div>
        </div>
      </div>

      <Modal
        onContinue={() => {
          setIsOpen(false)
          onDelete(lineItem?.guid)
        }}
      />
    </>
  )
}

export default Item
