import ReviewCart from 'components/Checkout'
import { CheckoutProvider } from 'components/Checkout/useCheckout'

const CheckoutPage = () => (
  <CheckoutProvider>
    <ReviewCart />
  </CheckoutProvider>
)

export default CheckoutPage
