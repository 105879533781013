/* eslint-disable no-control-regex */
const LS_TOKEN = 'token'
const LS_EMAIL = 'email'
const LS_NAME = 'name'
const LS_BUSINESS_NAME = 'businessName'

export const getAuthToken = () => window.localStorage.getItem(LS_TOKEN)

export const setAuth = ({ token, email, name, businessName }) => {
  window.localStorage.setItem(LS_TOKEN, token)
  window.localStorage.setItem(LS_EMAIL, email)
  window.localStorage.setItem(LS_NAME, name)
  window.localStorage.setItem(LS_BUSINESS_NAME, businessName)
}

export const removeAuth = () => {
  window.localStorage.removeItem(LS_TOKEN)
  window.localStorage.removeItem(LS_EMAIL)
  window.localStorage.removeItem(LS_NAME)
  window.localStorage.removeItem(LS_BUSINESS_NAME)
}

export const capitalizeFirstLetter = (string) => string[0].toUpperCase() + string.slice(1)

export const isFunction = (func) => typeof func === 'function'

// eslint-disable-next-line no-confusing-arrow
export const addOrRemove = (arr, item) =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]

export const toArray = (value) => {
  if (!value) return []
  return typeof value === 'string' ? [value] : value
}

export const getFilterLabel = (arr, currentValue) =>
  arr.find((filter) => filter.value === currentValue).label

const formatPrice = (value) => Number(value)?.toFixed(2) ?? 0
export const dollarTemplate = (value) => `$ ${formatPrice(value)}`

export const apiErrorMessage = (error) => {
  if (typeof error?.response?.data?.error?.user_message === 'object') {
    return error?.response?.data?.error?.user_message?.message
  }
  if (error?.response?.data?.error?.user_message) {
    return toArray(error?.response?.data?.error?.user_message)?.join('. ')
  }
  if (error?.response?.data?.errors) {
    return toArray(error?.response?.data?.errors)?.join('. ')
  }
  return 'Oops! Something went wrong!'
}

export const formatAddress = (attributes) => `${attributes.street}, ${attributes.city}`

export const isEmail = (email) =>
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
    email,
  )

export const getFileNameFromUrl = (url) => {
  const fileName = url?.split('/')

  return fileName[fileName.length - 1].split('?')[0]
}

export const copyToClipboard = (textToCopy) => navigator.clipboard.writeText(textToCopy ?? '')

export const toTitleCase = (phrase) =>
  phrase
    .replace(/_/g, ' ')
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
