import SalesHistoryItem from './SalesHistoryItem'

const SalesHistory = ({ taxRecords }) => (
  <div
    id="w-node-_0bddf85c-9418-5271-b783-6289be9e436b-0ce80a13"
    className="w-layout-grid grid-info-order"
  >
    <div className="div-block-108-copy">
      <div className="text-details-container">
        <div className="text-bold">Sales History</div>
      </div>
    </div>
    {taxRecords &&
      Object.keys(taxRecords)?.map((year) => (
        <SalesHistoryItem key={year} year={year} link={taxRecords[year]} />
      ))}
  </div>
)

export default SalesHistory
