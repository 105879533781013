import { useEffect, useState, useRef } from 'react'

const createUseLottie = (lottie) => (options) => {
  const container = useRef(null)
  const [instance, setInstance] = useState(null)

  useEffect(() => {
    if (container && options && !instance) {
      setInstance(lottie.loadAnimation({ container: container.current, ...options }))
    }
  }, [instance, container, options])

  return [instance, container]
}

export default createUseLottie
