import { useCallback, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { DEFAULT_ANIMATION } from 'lib/const'
import { removeAuth } from 'lib/utils'

import MuseaLogo from 'images/Green_Musea_Logo.svg'
import { ReactComponent as OrdersIcon } from 'images/icons/NavDrawer/orders.svg'
import { ReactComponent as DashboardIcon } from 'images/icons/NavDrawer/dashboard.svg'
import { ReactComponent as PhotographersIcon } from 'images/icons/NavDrawer/photographers.svg'
import { ReactComponent as PricingIcon } from 'images/icons/NavDrawer/pricing.svg'
import { ReactComponent as CreditIcon } from 'images/icons/NavDrawer/credit.svg'
import { ReactComponent as AccountIcon } from 'images/icons/NavDrawer/account.svg'
import { ReactComponent as LogoutIcon } from 'images/icons/NavDrawer/logout.svg'
import { ReactComponent as ExpandIcon } from 'images/icons/NavDrawer/expand.svg'

import NavItem from './NavItem'

const NavDrawer = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const navigate = useNavigate()

  const handleExpande = useCallback(() => setIsExpanded((prev) => !prev), [])

  const handleLogout = useCallback((e) => {
    e.preventDefault()
    removeAuth()
    navigate('/login')
  }, [])

  return (
    <div
      data-collapse="medium"
      role="banner"
      className="navbar w-nav"
      style={{
        width: isExpanded ? '215px' : '80px',
        transition: DEFAULT_ANIMATION,
      }}
    >
      <div className="div-block-58">
        <NavLink to="/" className="brand w-nav-brand">
          <img src={MuseaLogo} loading="lazy" alt="" className="logo-img" />
          <div
            data-w-id="4ed3bba0-20f4-ecdd-52a3-fa7ad124ba00"
            className="text-logo"
            style={{
              opacity: isExpanded ? '1' : '0',
              transition: DEFAULT_ANIMATION,
            }}
          >
            <span className="text-span">MUSEA</span>
            {' Lab'}
          </div>
        </NavLink>
      </div>
      <div className="div-block-6">
        <div className="w-layout-grid grid-sidebar">
          <NavItem to="/lab-orders" isExpanded={isExpanded} icon={OrdersIcon} label="Orders" />
          <NavItem to="/dashboard" isExpanded={isExpanded} icon={DashboardIcon} label="Dashboard" />
          <NavItem
            to="/photographers"
            isExpanded={isExpanded}
            icon={PhotographersIcon}
            label="Photographers"
          />
          <NavItem to="/pricing" isExpanded={isExpanded} icon={PricingIcon} label="Pricing" />
          <NavItem to="/credit" isExpanded={isExpanded} icon={CreditIcon} label="Credit" />
        </div>
        <div>
          <div className="w-layout-grid grid-sidebar">
            <div className="div-block-40">
              <div className="div-block-2">
                <div className="line-divider" />
              </div>
              <div className="w-layout-grid grid-sidebar _2">
                <NavItem to="/account" isExpanded={isExpanded} icon={AccountIcon} label="Account" />
                <button
                  id="w-node-_4ed3bba0-20f4-ecdd-52a3-fa7ad124ba06-d124b9fc"
                  aria-current="page"
                  className="sidebar-button w-inline-block logout"
                  onClick={handleLogout}
                  onKeyPress={handleLogout}
                  type="button"
                >
                  <div className="img-order-summary-container">
                    <div className="icon w-embed">
                      <LogoutIcon />
                    </div>
                  </div>
                  <div
                    className="div-block"
                    style={{
                      transform: isExpanded
                        ? 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
                        : 'translate3d(-20px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                      transformStyle: 'preserve-3d',
                      transition: DEFAULT_ANIMATION,
                      width: isExpanded ? 'auto' : '0',
                      opacity: isExpanded ? '1' : '0',
                    }}
                  >
                    <div>Logout</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div data-w-id="16af4c97-cddb-68d0-8589-5c73ee4f8f4d" className="bottom-sidebar">
          <div
            role="button"
            onClick={handleExpande}
            onKeyPress={handleExpande}
            data-w-id="13d4cefa-2761-212e-aa74-f62eceb860ac"
            className="icon w-embed"
            style={{ transform: isExpanded ? 'rotate(180deg)' : 'none' }}
          >
            <ExpandIcon />
          </div>
        </div>
      </div>
      <div className="w-nav-button">
        <div className="w-icon-nav-menu" />
      </div>
    </div>
  )
}

export default NavDrawer
