import { dollarTemplate } from 'lib/utils'

const BillDetails = ({ attributes }) => (
  <div data-w-id="88c8398a-15de-8814-f63f-d95b5bbbc025" className="order-2nd-div">
    <div className="w-layout-grid grid-bottom-details">
      <div />
      <div id="w-node-_652204d4-e5ff-36d4-25fb-e3b74f1e5df7-fde80a15" className="div-block-details">
        <h2 className="heading-title">Bill Details</h2>
        <div className="grid-details right-copy">
          <div className="container-details full">
            <div className="text-details-container">
              <div>Subtotal:</div>
            </div>
            <div>{dollarTemplate(attributes.subtotal)}</div>
          </div>
          <div className="container-details full">
            <div className="text-details-container">
              <div>Shipping:</div>
            </div>
            <div>{dollarTemplate(attributes.shippingTotal)}</div>
          </div>
          <div className="container-details full">
            <div className="text-details-container">
              <div>Credit Used:</div>
            </div>
            <div>{dollarTemplate(attributes.creditApplied)}</div>
          </div>
          <div className="container-details full">
            <div className="text-details-container">
              <div>Discount:</div>
            </div>
            <div>{dollarTemplate(attributes.discount)}</div>
          </div>
          <div className="horizontal-line" />
          <div className="container-details _2">
            <div
              id="w-node-_652204d4-e5ff-36d4-25fb-e3b74f1e5e13-fde80a15"
              className="text-details-container"
            >
              <div>Total:</div>
            </div>
            <h2 className="heading-title">{dollarTemplate(attributes.total)}</h2>
          </div>
          <div className="container-details full red">
            <div className="text-details-container">
              <div>Refund Total:</div>
            </div>
            <div>{dollarTemplate(attributes.refundAmount)}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default BillDetails
