import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useQuery } from '@tanstack/react-query'
import httpClient from 'httpClient'
import { Link } from 'react-router-dom'
import SortImages from '../components/SortImages'
import ChevronIcon from '../images/chevron-down-12.svg'
import styles from './ProductSelection.module.css'

const ProductSelection = () => {
  const location = useLocation()
  const { id } = useParams()
  const [photos, setPhotos] = useState([])
  const [selectedPhoto, setSelectedPhoto] = useState('')
  const [selectedPaperType, setSelectedPaperType] = useState()
  const [selectedPrintSize, setSelectedPrintSize] = useState()
  const [selectedFinishingService, setSelectedFinishingService] = useState()
  // const [printChoices, setPrintChoices] = useState({
  //   paperType: '',
  //   printSize: '',
  //   finishingService: '',
  //   finishingDetails: '',
  // })
  // const selectedPhoto = useRef(null)

  const logOrder = useCallback(() => {
    console.log('location: ', location)
  }, [location])

  useEffect(() => {
    logOrder()
  }, [logOrder])

  useQuery({
    queryKey: ['photos', id],
    queryFn: () => httpClient.get(`lab_orders/${id}/lab_order_photographs`),
    onSuccess: ({ data }) => {
      setPhotos(data)
      setSelectedPhoto(data[0])
    },
    staleTime: Infinity,
  })

  function handleSelectedPhoto(photo) {
    setSelectedPhoto(photo)
  }

  const handleChangePhotoOrder = (images) => {
    setPhotos(images)
  }

  const paperTypeOptionsQuery = useQuery({
    queryKey: ['paperTypeOptions'],
    queryFn: () => httpClient.get('products/papers'),
    staleTime: Infinity,
  })

  // TODO: sort by x_aspect ascending
  const printSizeOptionsQuery = useQuery({
    queryKey: ['paperSizeOptions', selectedPaperType],
    queryFn: () => httpClient.get(`products/${selectedPaperType}/print_sizes`),
    enabled: !!selectedPaperType,
    staleTime: Infinity,
  })

  // TODO: 1. add call to finishing categories for Finishing Service Dropdown
  // 2. set state with selectedPrimaryFinishingCategory to "Matting", "Mounting", "Framing", "none"
  // queryFn: () => httpClient.get(`products/${selectedPrintSize}/finishing_categories`),
  const finishingServiceOptionsQuery = useQuery({
    queryKey: ['finishingServiceOptions', selectedPrintSize],
    queryFn: () => httpClient.get(`products/${selectedPrintSize}/finishing_services`),
    enabled: !!selectedPrintSize,
    staleTime: Infinity,
  })

  // TODO: 1. bassed on selectedPrimaryFinishingCategory, filter finishingServiceCategoryOptions
  // to only show finishingCategoryOptions that have matching finishing_category_primary_name
  // so filter finishing_category_primary_name === selectedPrimaryFinishingCategory
  // ("Matting", "Mounting", "Framing", "none")

  // 2. Set next drop down based on
  const finishingDetailsOptionsQuery = useQuery({
    queryKey: ['finishingDetailsOptions', selectedFinishingService],
    queryFn: () => httpClient.get(`products/${selectedFinishingService}/finishing_details`),
    enabled: !!selectedFinishingService,
    staleTime: Infinity,
  })
  console.log('paperTypeOptionsQuery: ', finishingDetailsOptionsQuery)

  const resetSelectedOptions = () => {
    setSelectedPaperType()
    setSelectedPrintSize()
    setSelectedFinishingService()
  }

  return (
    <div className="w-layout-grid grid-print-order">
      <div className="sidebar-product-selection white">
        <div className="controls-block-container">
          <div className="controls-block-grid top">
            <Link to="/" data-tippy-content="Back to Home" className="div-block-169-copy ghost">
              <div className="icon small w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M218.76367,103.7002,138.75684,30.96436a15.93657,15.93657,0,0,0-21.52637.00146L37.2373,103.69971A16.03108,16.03108,0,0,0,32,115.53857l0,92.09522a16.47275,16.47275,0,0,0,4.01066,10.96174A15.91729,15.91729,0,0,0,48.002,223.999H95.96484a8,8,0,0,0,8-8V167.9917a8,8,0,0,1,8-8h32a8,8,0,0,1,8,8V215.999a8,8,0,0,0,8,8h48.05731a15.40625,15.40625,0,0,0,7.53406-1.85584A16.08415,16.08415,0,0,0,224,207.999v-92.46A16.03567,16.03567,0,0,0,218.76367,103.7002Z" />
                </svg>
              </div>
            </Link>
            <button
              data-tippy-content="Reset All"
              type="button"
              className="div-block-169-copy ghost"
              onClick={() => resetSelectedOptions()}
            >
              <div className="icon small w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <polyline
                    points="79.833 99.716 31.833 99.716 31.833 51.716"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <path
                    d="M190.2254,65.7746a88,88,0,0,0-124.4508,0L31.83348,99.71573"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <polyline
                    points="176.167 156.284 224.167 156.284 224.167 204.284"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <path
                    d="M65.7746,190.2254a88,88,0,0,0,124.4508,0l33.94112-33.94113"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                </svg>
              </div>
            </button>
            <a href="/product-selection" className="button-2 w-inline-block">
              <div className="icon right-margin w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M128,23.99805a104,104,0,1,0,104,104A104.12041,104.12041,0,0,0,128,23.99805Zm40,112H136v32a8,8,0,1,1-16,0v-32H88a8,8,0,0,1,0-16h32v-32a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
                </svg>
              </div>
              <div>Upload More</div>
            </a>
          </div>
        </div>
        <div className="print-order-sidebar grid">
          <div className="accordion-item-block">
            <div className="w-layout-grid grid-form">
              <div
                id="w-node-ef53a1b5-32fb-b097-4006-9c6a2441164c-5b83c88b"
                className="input-container quantity"
              >
                <div className="label-container">
                  <div className="div-block-150">
                    <div className="sub-label-form">Quantity</div>
                    <div className="icon mini margin-left opacity70 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none" />
                        <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="form-block-input w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="input-wrapper"
                  >
                    <input
                      type="number"
                      maxLength="256"
                      name="field-4"
                      data-name="Field 4"
                      id="data-jscolor"
                      className="text-field-2 data-jscolor w-input"
                    />
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
              <div
                id="w-node-ef53a1b5-32fb-b097-4006-9c6a2441165c-5b83c88b"
                className="input-container paper-type"
              >
                <div className="label-container">
                  <div className="div-block-150">
                    <div className="sub-label-form">Paper type</div>
                    <div className="icon mini margin-left opacity70 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none" />
                        <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="form-block-input w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="form-input select-field"
                  >
                    <div className="select-field-wrapper">
                      <select
                        id="my-field-3"
                        name="field-3"
                        data-name="Field 3"
                        className="form-select-field w-select"
                        onChange={(e) => {
                          setSelectedPaperType(e.target.value);
                        }}
                      >
                        {
                          paperTypeOptionsQuery.isLoading ? (
                            <option value="">Loading...</option>
                          ) : (
                            <option value="">Select Paper Type</option>
                          )
                        }
                        {
                          typeof paperTypeOptionsQuery.data !== 'undefined' && paperTypeOptionsQuery.data?.data?.map((paperType) => (
                            <option
                              key={paperType.id}
                              value={paperType.id}
                              selected={paperType.id === selectedPaperType}
                            >
                              {paperType.attributes.name}
                            </option>
                          ))
                        }
                      </select>

                      <div className={`div-block-63 ${styles['chevron-icon']}`}>
                        <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                      </div>
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-layout-grid grid-form">
              <div className="input-container">
                <div className="label-container">
                  <div className="div-block-150">
                    <div className="sub-label-form">Print Size (IN)</div>
                    <div className="icon mini margin-left opacity70 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none" />
                        <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="form-block-input w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="form-input select-field"
                  >
                    <div className="select-field-wrapper">
                      <select
                        id="size"
                        name="field-3"
                        data-name="Field 3"
                        className="form-select-field w-select"
                        onChange={(e) => {
                          setSelectedPrintSize(e.target.value);
                        }}
                      >
                        {
                          printSizeOptionsQuery.isInitialLoading ? (
                            <option value="">Loading...</option>
                          ) : (
                            <option value="">Select Print Size</option>
                          )
                        }
                        {
                          typeof printSizeOptionsQuery.data !== 'undefined' && printSizeOptionsQuery.data.map((printSize) => (
                            <option
                              key={printSize.guid}
                              value={printSize.guid}
                              selected={printSize.guid === selectedPrintSize}
                            >
                              {printSize.name} (${printSize.price})
                            </option>
                          ))
                        }
                      </select>
                      <div className={`div-block-63 ${styles['chevron-icon']}`}>
                        <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                      </div>
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
              <div
                id="w-node-ef53a1b5-32fb-b097-4006-9c6a24411682-5b83c88b"
                className="input-container paper-type"
              >
                <div className="label-container">
                  <div className="div-block-150">
                    <div className="sub-label-form">Finishing Service</div>
                    <div className="icon mini margin-left opacity70 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none" />
                        <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="form-block-input w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="form-input select-field"
                  >
                    <div className="select-field-wrapper">
                      <select
                        id="selector"
                        name="field-3"
                        data-name="Field 3"
                        className="form-select-field w-select"
                        onChange={(e) => {
                          setSelectedFinishingService(e.target.value);
                        }}
                      >
                        {
                          finishingServiceOptionsQuery.isInitialLoading ? (
                            <option value="">Loading...</option>
                          ) : (
                            <option value="">Select Finishing Service</option>
                          )
                        }
                        {
                          typeof finishingServiceOptionsQuery.data !== 'undefined' && finishingServiceOptionsQuery.data.data.map((finishingService) => (
                            <option
                              key={finishingService.id}
                              value={finishingService.id}
                              selected={finishingService.id === selectedFinishingService}
                            >
                              {finishingService.attributes.name}
                            </option>
                          ))
                        }
                      </select>

                      <div className={`div-block-63 ${styles['chevron-icon']}`}>
                        <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                      </div>
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-matting" className="wrapper-dynamic-matting">
              <div className="w-layout-grid grid-dynamic">
                <div className="input-container">
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">format</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="4 Ply">4 Ply</option>
                          <option value="8 Ply">8 Ply</option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_79897ac1-1d3e-128c-1fa7-d5c7764520c4-5b83c88b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Mat size</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="11x14 ($14.00)">11x14 ($14.00)</option>
                          <option value="11x13 ($14.00)">11x13 ($14.00)</option>
                          <option value="12x15 ($20.00)">12x15 ($20.00)</option>
                          <option value="13x13 ($20.00)">13x13 ($20.00)</option>
                          <option value="14x14 ($20.00)">14x14 ($20.00)</option>
                          <option value="11x15 ($20.00)">11x15 ($20.00)</option>
                          <option value="14x17 ($25.00)">14x17 ($25.00)</option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-mounting" className="wrapper-dynamic-mounting">
              <div className="w-layout-grid grid-dynamic">
                <div className="input-container">
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Mount size</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="7x10 mount ($9.00)">7x10 mount ($9.00)</option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-eaf3fe18-d18d-b6ac-8d17-cfe23919bc4d-5b83c88b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Mount color</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Black $(0.00)">Black $(0.00)</option>
                          <option value="White $(0.00)">White $(0.00)</option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-framing" className="wrapper-dynamic-framing">
              <div id="frame" className="frame">
                <div className="w-layout-grid grid-dynamic _1fr">
                  <div
                    id="w-node-_8b379125-90a6-7d04-edd2-bcdf9dde0fcf-5b83c88b"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Choose type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="choose-frame"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="standard">Standard Framing</option>
                            <option value="premium">Premium Framing</option>
                          </select>
                          <div className={`div-block-63 ${styles['chevron-icon']}`}>
                            <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-standard" className="standard-frame">
              <div className="w-layout-grid grid-dynamic _1fr">
                <div
                  id="w-node-_0f289548-9dd2-1946-e3e5-62552baefdd4-5b83c88b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Adelaide">Adelaide</option>
                          <option value="Albany">Albany</option>
                          <option value="Athens">Athens</option>
                          <option value="Aurora">Aurora</option>
                          <option value="Bristol">Bristol</option>
                          <option value="Charleston">Charleston</option>
                          <option value="Charlotte">Charlotte</option>
                          <option value="Chelsea">Chelsea</option>
                          <option value="Columbia">Columbia</option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_0f289548-9dd2-1946-e3e5-62552baefde6-5b83c88b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame Mat</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="No Mat $(125.00)">No Mat $(125.00)</option>
                          <option value='2" Mat $(125.00)'>2&quot; Mat $(125.00)</option>
                          <option value='4" mat $(125.00)'>4&quot; mat $(125.00)</option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_0f289548-9dd2-1946-e3e5-62552baefdf8-5b83c88b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">acrylic</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-premium" className="premium-frame">
              <div className="w-layout-grid grid-dynamic _1fr">
                <div
                  id="w-node-_912a507f-5e0c-80e6-e86c-639726716a16-5b83c88b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame wood</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="frame-wood"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="maple">Maple 3/4&quot; PROFILE</option>
                          <option value='Maple 1.5" PROFILE'>Maple 1.5&quot; PROFILE</option>
                          <option value='Ash 3/4" PROFILE'>Ash 3/4&quot; PROFILE</option>
                          <option value='Ash 1.5" PROFILE'>Ash 1.5&quot; PROFILE</option>
                          <option value='Cherry 3/4" PROFILE'>Cherry 3/4&quot; PROFILE</option>
                          <option value='Cherry 1.5" PROFILE'>Cherry 1.5&quot; PROFILE</option>
                          <option value='Walnut 3/4" PROFILE'>Walnut 3/4&quot; PROFILE</option>
                          <option value='Walnut 1.5" PROFILE'>Walnut 1.5&quot; PROFILE</option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_912a507f-5e0c-80e6-e86c-639726716a28-5b83c88b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame Mat</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="No Mat $(125.00)">No Mat $(125.00)</option>
                          <option value='2" Mat $(125.00)'>2&quot; Mat $(125.00)</option>
                          <option value='4" mat $(125.00)'>4&quot; mat $(125.00)</option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_912a507f-5e0c-80e6-e86c-639726716a3a-5b83c88b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">acrylic</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-c1c7bde4-8fac-4ab8-4dc4-37f5d4782818-5b83c88b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame stain</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Rising White">Rising White</option>
                          <option value="Whitewash">Whitewash</option>
                          <option value="Satin Black">Satin Black</option>
                          <option value="Dark Grey">Dark Grey</option>
                        </select>

                        <div className={`div-block-63 ${styles['chevron-icon']}`}>
                          <img src={ChevronIcon} loading="lazy" alt="" className="img-icon" />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="line-horizontal" />
            <div data-ix="fade-up-1" className="accordion js-accordion">
              <div className="accordion__item js-accordion-item">
                <div className="accordion-header js-accordion-header">
                  <div className="accordion-item-2">
                    <div className="icon small x2 right-margin w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none" />
                        <path d="M128,23.99805a104,104,0,1,0,104,104A104.12041,104.12041,0,0,0,128,23.99805Zm40,112H136v32a8,8,0,1,1-16,0v-32H88a8,8,0,0,1,0-16h32v-32a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
                      </svg>
                    </div>
                    <div>Add Notes</div>
                  </div>
                </div>
                <div data-ix="hide-on-load" className="accordion-body js-accordion-body">
                  <div className="accordion-item-content">
                    <div className="input-container add-notes">
                      <div className="label-container">
                        <div className="div-block-150">
                          <div className="sub-label-form">Editing notes for:</div>
                          <div data-hover="" data-delay="0" className="w-dropdown">
                            <div className="dropdown-toggle-3 w-dropdown-toggle">
                              <div className="sub-label-form all-photos">current photo:</div>
                              <div className="icon-3 w-icon-dropdown-toggle" />
                            </div>
                            <nav className="dropdown-list-3 w-dropdown-list">
                              <div className="sub-label-form unselected">all photos</div>
                            </nav>
                          </div>
                          <div className="icon mini margin-left opacity70 w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none" />
                              <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          className="input-wrapper"
                        >
                          <textarea
                            placeholder="Example Text"
                            maxLength="5000"
                            id="field-6"
                            name="field-6"
                            className="text-field-2 area w-input"
                          />
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-controls-product-selection-sidebar">
          <div className="controls-block-grid">
            <div id="w-node-ef53a1b5-32fb-b097-4006-9c6a244116ab-5b83c88b">Subtotal:</div>
            <div className="text-block-32">$ 142.00</div>
          </div>
          <a href="/product-selection" className="container-cart-button w-inline-block">
            <div className="div-block-186">
              <div className="icon right-margin w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M223.87109,65.43115l-12.15722,66.86231A23.98711,23.98711,0,0,1,188.10156,152H72.13135l4.36377,24H184a24.01161,24.01161,0,1,1-22.624,16H102.624a24.00309,24.00309,0,1,1-40.85986-7.57666L34.05078,32H16a8,8,0,0,1,0-16H34.05078A15.9918,15.9918,0,0,1,49.793,29.13818L54.67676,56H216a8.00076,8.00076,0,0,1,7.87109,9.43115Z" />
                </svg>
              </div>
              <div>Add To Cart</div>
            </div>
            <div className="div-block-187">
              <div className="div-block-188">
                <div>3</div>
              </div>
            </div>
          </a>
          <a href="/product-selection" className="button-2 w-inline-block">
            <div className="icon right-margin w-embed">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                <rect width="256" height="256" fill="none" />
                <path d="M247.31055,124.75061c-.35157-.79-8.81934-19.57617-27.65332-38.41113C194.57324,61.25256,162.87793,47.99182,128,47.99182S61.42676,61.25256,36.34277,86.33948c-18.834,18.835-27.30175,37.62109-27.65332,38.41113a8.00282,8.00282,0,0,0,0,6.49805c.35157.791,8.82032,19.57226,27.6543,38.40429C61.42773,194.734,93.12207,207.99182,128,207.99182S194.57227,194.734,219.65625,169.653c18.834-18.832,27.30273-37.61328,27.6543-38.40429A8.00282,8.00282,0,0,0,247.31055,124.75061ZM128,92.00061a36,36,0,1,1-36,36A36.04061,36.04061,0,0,1,128,92.00061Z" />
              </svg>
            </div>
            <div>Review Order</div>
          </a>
        </div>
      </div>
      <div>
        <div className="product-selection-picture-container">
          <div className="product-selection-main-picture-container">
            <div className="main-picture-grid">
              <div
                id="w-node-_2dfa92fa-719e-7852-4c62-0cb41c2db9b9-5b83c88b"
                className="picture-product-selection"
              >
                <img
                  src={selectedPhoto?.attributes?.presignedPhotoUrl}
                  sizes="(max-width: 479px) 247.5px, (max-width: 767px) 52vw, (max-width: 1439px) 43vw, 44vw"
                  loading="lazy"
                  alt=""
                  style={{ maxHeight: '550px' }}
                />
                <div id="prem-framing" className="prem-frame-wrap">
                  <div className="prem-frame" />
                </div>
              </div>
              <div className="img-controls-grid">
                <div className="rotate-button">
                  <div className="icon small w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none" />
                      <polyline
                        points="176.167 99.716 224.167 99.716 224.167 51.716"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <path
                        d="M190.2254,190.2254a88,88,0,1,1,0-124.4508l33.94112,33.94113"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['side-controls-product-selection']}>
            <div className="w-layout-grid grid-44">
              {/* eslint-disable-next-line arrow-body-style */}
              <SortImages
                handleImage={(newImages) => handleChangePhotoOrder(newImages)}
                images={photos}
              />
              <div className="rotate-button">
                <div className="icon small w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                    <rect width="256" height="256" fill="none" />
                    <path
                      d="M43.99951,143.27273V52a8,8,0,0,1,8-8h152a8,8,0,0,1,8,8V204a8,8,0,0,1-8,8h-68.3641"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="24"
                    />
                    <polyline
                      points="128 152 64 216 32 184"
                      fill="none"
                      stroke="currentcolor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="24"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['bottom-controls-product-selection']}>
            <div className={styles['bottom-controls-product-selection-grid']}>
              {photos.length > 0 &&
                photos.map((photo) => (
                  <div
                    key={photo.id}
                    className="picture-element-product-selection"
                    onClick={() => handleSelectedPhoto(photo)}
                    role="button"
                    style={{ position: 'relative' }}
                  >
                    <div className="w-layout-grid top-buttons-image-element">
                      <div className="cart-symbol">
                        <div className="icon small x2 w-embed">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                            <rect width="256" height="256" fill="none" />
                            <path d="M96,216a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,96,216Zm88-16a16,16,0,1,0,16,16A16.00016,16.00016,0,0,0,184,200ZM228.10059,67.18262A7.99866,7.99866,0,0,0,221.71387,64H48.32031L40.207,35.604A16.06984,16.06984,0,0,0,24.82227,24H8A8,8,0,0,0,8,40H24.82227l9.73046,34.05469c.02637.09863.05372.19677.084.29443l26.35547,92.24365A24.10087,24.10087,0,0,0,84.06836,184h95.86328a24.101,24.101,0,0,0,23.07617-17.40674l26.39844-92.39551A8.00023,8.00023,0,0,0,228.10059,67.18262Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <img
                      src={photo.attributes.smallestPhoto}
                      loading="lazy"
                      sizes="90px"
                      alt={photo.attributes.photoFileName}
                      className="image-thumbnail"
                      style={{ margin: '8px 0' }}
                    />
                    <div
                      id="w-node-b7640de5-7a20-fbc4-52d4-86e40130e18c-5b83c88b"
                      className="info-container-mini"
                    >
                      <div className="icon smaller right-margin supermini w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentcolor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none" />
                          <path
                            d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                        </svg>
                      </div>
                      <div>{photo.attributes.photoFileName}</div>
                    </div>
                    <div
                      id="w-node-b7640de5-7a20-fbc4-52d4-86e40130e190-5b83c88b"
                      className="w-layout-grid top-buttons-image-element"
                      style={{ display: 'none' }}
                    >
                      <div className="cart-symbol">
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none" />
                            <path d="M96,216a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,96,216Zm88-16a16,16,0,1,0,16,16A16.00016,16.00016,0,0,0,184,200ZM228.10059,67.18262A7.99866,7.99866,0,0,0,221.71387,64H48.32031L40.207,35.604A16.06984,16.06984,0,0,0,24.82227,24H8A8,8,0,0,0,8,40H24.82227l9.73046,34.05469c.02637.09863.05372.19677.084.29443l26.35547,92.24365A24.10087,24.10087,0,0,0,84.06836,184h95.86328a24.101,24.101,0,0,0,23.07617-17.40674l26.39844-92.39551A8.00023,8.00023,0,0,0,228.10059,67.18262Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSelection
