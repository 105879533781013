import { Link } from 'react-router-dom'

import { ReactComponent as BackIcon } from 'images/icons/back.svg'

import ErrorMessage from 'components/common/ErrorMessage'
import { useCheckout } from 'components/Checkout/useCheckout'
import DiscountCode from './DiscountCode'
import ClientName from './ClientName'
import Address from './Address'
import CreditCard from './CreditCard'
import BoutiquePackaging from './BoutiquePackaging'

const CheckoutDetails = () => {
  const { order, error } = useCheckout()

  return (
    <div id="w-node-_3f65e1c0-1d27-a12c-7163-306528279207-5683c88d" className="form-cart-container">
      <div className="accordion-item-block no-margin">
        <ClientName />
        <div className="line-horizontal" />
        <Address />
        <div className="line-horizontal" />
        <CreditCard />
        <div className="line-horizontal" />
        <DiscountCode />
        <div className="line-horizontal" />
      </div>
      {order?.data?.attributes?.eligibleForBoutiqueShipping && <BoutiquePackaging />}
      <div className="line-horizontal" />
      <div className="w-layout-grid grid-bottom-buttons auto margin-24px margin-top">
        <Link
          to={`lab-orders/${order?.data?.attributes?.guid}/summary`}
          className="button secondary w-inline-block"
        >
          <div className="icon right-margin w-embed">
            <BackIcon />
          </div>
          <div>Back to Review</div>
        </Link>
      </div>
      {error && <ErrorMessage errors={error} />}
    </div>
  )
}

export default CheckoutDetails
