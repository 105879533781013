import { useState, useEffect } from 'react'
import { useParams } from 'react-router'

import httpClient from 'httpClient'
import { apiErrorMessage } from 'lib/utils'

import PhotographerProfileDetails from 'components/PhotographerProfile/PhotographerProfileDetails'
import PhotographerRecentNotes from 'components/PhotographerProfile/PhotographerRecentNotes'
import PhotographerProgress from 'components/PhotographerProfile/PhotographerProgress'
import PhotographerPassword from 'components/PhotographerProfile/PhotographerPassword'
import PhotographerAdminNotes from 'components/PhotographerProfile/PhotographerAdminNotes'
import Loading from 'components/common/Loading'
import ErrorCode from 'components/common/ErrorCode'

const PhotographerProfilePage = () => {
  const { id } = useParams()
  const [profile, setProfile] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchPhotographer = async () => {
    try {
      setLoading(true)
      const user = await httpClient(`photographers/${id}`)
      setProfile(user)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setError(apiErrorMessage(err))
    }
  }

  useEffect(() => {
    fetchPhotographer()
  }, [])

  if (loading) {
    return <Loading message="Loading user" />
  }

  if (error) {
    return <ErrorCode errors={error} code="401" />
  }

  if (!profile?.data) {
    return <ErrorCode errors="Photographer not found" code="404" />
  }

  return (
    <div className="photographer-profile-container">
      <div className="w-layout-grid photographer-profile-grid">
        <div className="w-layout-grid photographer-profile-subgrid">
          <PhotographerProfileDetails profile={profile?.data?.attributes} />
          <PhotographerRecentNotes />
        </div>
        <div className="w-layout-grid photographer-profile-subgrid">
          <PhotographerProgress />
          <PhotographerPassword />
          <PhotographerAdminNotes />
        </div>
      </div>
    </div>
  )
}

export default PhotographerProfilePage
