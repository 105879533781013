import Header from './Header'
import Details from './Details'
import BillDetails from './BillDetails'
import OrderSummary from './OrderSummary'

const LabOrderDetailsInformation = ({ order }) => {
  if (!order) return null

  return (
    <div className="wrapper">
      <div className="container-order-block">
        <Header attributes={order?.data.attributes} />
        <Details attributes={order?.data.attributes} />
        <OrderSummary lineItems={order?.included} />
        <BillDetails attributes={order?.data.attributes} />
      </div>
    </div>
  )
}

export default LabOrderDetailsInformation
