import LabOrderCard from './LabOrderCard'

const LabOrders = ({ orders, onDelete, isAdmin }) => (
  <div className="main-cards-container">
    <div className="w-layout-grid sub-container-cards">
      {orders?.map((order) => (
        <LabOrderCard key={order.id} order={order} onDelete={onDelete} isAdmin={isAdmin} />
      ))}
    </div>
  </div>
)

export default LabOrders
