import { memo } from 'react'
import cn from 'classnames'

const InputRadio = ({ label, value, onClick, active }) => (
  <label className="radio-button-field w-radio">
    <div
      className={cn(
        'w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input',
        {
          'w--redirected-checked': active,
        },
      )}
    />
    <input
      type="radio"
      data-name="Radio 5"
      id="radio-5"
      name="radio"
      value={value}
      style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
      onClick={onClick}
    />
    <span className="w-form-label">{label}</span>
  </label>
)

export default memo(InputRadio)
