import { Link } from 'react-router-dom'

import { useLottie } from 'hooks'
import animationData from 'animations/signupSuccess.json'

const lottieOptions = {
  animationData,
  loop: true,
  autoplay: true,
  renderer: 'svg',
  direction: 1,
  duration: 0,
}

const SuccessMessage = () => {
  const [, container] = useLottie(lottieOptions)
  return (
    <div className="success-message-2 w-form-done" style={{ display: 'block' }}>
      <div className="div-block-315">
        <div className="div-block-316">
          <div ref={container} className="lottie-animation-6" />
          <div className="divider-16px" />
          <div className="divider" />
          <h2 className="heading-4-copy-2">Congratulations!</h2>
          <div className="divider" />
          <div>You can now sign in and place orders with Musea Lab!</div>
          <div className="divider-16px" />
          <div className="divider-16px" />
          <Link to="/" className="main-button w-button">
            Back to MuseaLab.com
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SuccessMessage
