import { useRef, useState, useCallback } from 'react'
import cn from 'classnames'
import { ReactComponent as DatePickerIcon } from 'images/icons/date-picker.svg'
import { ReactComponent as CalendarIcon } from 'images/icons/calendar.svg'
import { ReactComponent as RestartIcon } from 'images/icons/restart.svg'
import { ReactComponent as StartIcon } from 'images/icons/start.svg'
import { useOutsideClickHandler, useToggle } from 'hooks'
import { DATE_FILTERS } from 'lib/const'
import InputRadio from './InputRadio'

const DateDropdown = ({ onStartDateChange, onEndDateChange, currentValues, onReset }) => {
  const ref = useRef()
  const [isOpen, toggleState, setToggleState] = useToggle(false)
  const [customStartDate, setCustomStartDate] = useState('')
  const [customEndDate, setCustomEndDate] = useState('')

  useOutsideClickHandler(ref, () => setToggleState(false))

  const handleFreeDate = useCallback(() => {
    if (customStartDate && customEndDate) {
      onStartDateChange(customStartDate)
      onEndDateChange(customEndDate)
    }
  }, [customStartDate, customEndDate])

  return (
    <div className="dropdown w-dropdown" ref={ref}>
      <div
        className={cn('filter-select-field radius w-dropdown-toggle', { 'w--open': isOpen })}
        onClick={toggleState}
        onKeyPress={toggleState}
        role="button"
      >
        <div
          className="icon small left-margin w-embed"
          role="button"
          onClick={toggleState}
          onKeyPress={toggleState}
        >
          <DatePickerIcon />
        </div>
      </div>
      <nav className={cn('dropdown-list date w-dropdown-list', { 'w--open': isOpen })}>
        <div className="form-block-2-copy w-form">
          <form
            id="email-form-3"
            name="email-form-3"
            data-name="Email Form 3"
            className="form-3-copy"
          >
            <div className="div-block-64">
              <div className="div-block-65">
                <div className="icon small right-margin x2 w-embed">
                  <CalendarIcon />
                </div>
                <div>By Date Range</div>
              </div>
              <div className="w-layout-grid grid-18">
                <div>
                  <div>Since</div>
                  <input
                    type="date"
                    className="datepicker w-input"
                    maxLength="256"
                    name="since"
                    data-name="Check Out"
                    placeholder="MM/DD/YY"
                    id="Check-out"
                    value={customStartDate ?? currentValues.startDate}
                    onChange={(e) => setCustomStartDate(e.target.value)}
                  />
                </div>
                <div>
                  <div>Until</div>
                  <input
                    type="date"
                    className="datepicker w-input"
                    maxLength="256"
                    name="until"
                    data-name="Check Out 2"
                    placeholder="MM/DD/YY"
                    id="Check-out-2"
                    value={customEndDate ?? currentValues.endDate}
                    onChange={(e) => setCustomEndDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="div-block-66" />
        <div className="form-block-2-copy w-form">
          <form
            id="email-form-3"
            name="email-form-3"
            data-name="Email Form 3"
            className="form-3-copy"
          >
            {Object.keys(DATE_FILTERS).map((option) => (
              <InputRadio
                key={option}
                label={DATE_FILTERS[option].label}
                onClick={() => {
                  onStartDateChange(DATE_FILTERS[option].startDate)
                  onEndDateChange(DATE_FILTERS[option].endDate)
                }}
                active={
                  DATE_FILTERS[option].startDate === currentValues.startDate &&
                  DATE_FILTERS[option].endDate === currentValues.endDate
                }
              />
            ))}
          </form>
        </div>
        <div className="w-layout-grid grid-19">
          <button className="container-info-filter bg" type="button" value="" onClick={onReset}>
            <div className="icon small right-margin x2 w-embed">
              <RestartIcon />
            </div>
            <div>Reset All</div>
          </button>
          <button
            className="container-info-filter _1"
            onClick={handleFreeDate}
            type="button"
            disabled={!customEndDate || !customStartDate}
          >
            <div className="icon small right-margin x2 w-embed">
              <StartIcon />
            </div>
            <div>Filter Now</div>
          </button>
        </div>
      </nav>
    </div>
  )
}

export default DateDropdown
