import { dollarTemplate } from 'lib/utils'

import ReferralCodeForm from './ReferralCodeForm'
import ReferralUrl from './ReferralUrl'
import ReferredByForm from './ReferredByForm'

const ClientCredit = ({ user, onUpdate, onRefetchUser }) => (
  <div className="client-credit-block">
    <div className="w-layout-grid client-credit-grid horizontal auto-1fr">
      <div className="w-layout-grid summary-grid-elements">
        <div className="div-big-text">
          <h2 className="heading-title right-margin">Client Credit</h2>
        </div>
        <div className="line-horizontal" />
        <div className="divider" />
        <div className="w-layout-grid main-summary-order-elements vertical">
          <div className="div-big-text">
            <div>Credit</div>
          </div>
          <div className="div-big-text">
            <h4 className="sub-heading-summary">{dollarTemplate(user?.referralCreditAvailable)}</h4>
          </div>
        </div>
        <div className="w-layout-grid main-summary-order-elements vertical">
          <div className="div-big-text">
            <div>Reffered by:</div>
          </div>
          <div className="div-big-text">
            <h4 className="sub-heading-summary">{user?.referredByEmail ?? '-'}</h4>
          </div>
        </div>
      </div>
      <div className="w-layout-grid summary-grid-elements margin-16">
        <ReferredByForm refetchUser={onRefetchUser} />
        <ReferralCodeForm
          referralCode={user?.referralCode}
          userGuid={user?.guid}
          onUpdate={onUpdate}
        />
        <ReferralUrl referralCode={user?.referralCode} />
      </div>
    </div>
  </div>
)

export default ClientCredit
