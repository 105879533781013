import { useCallback } from 'react'
import classNames from 'classnames'

import { useConfirmationModal } from 'hooks'
import { dollarTemplate } from 'lib/utils'

import { ReactComponent as CheckRoundedIcon } from 'images/icons/check-rounded.svg'
import { useCheckout } from './useCheckout'

const SummaryElement = ({ label, value }) => (
  <div className="w-layout-grid main-summary-order-elements">
    <div className="div-big-text">
      <div>{label}</div>
    </div>
    <div className="div-big-text">
      <h4 className="sub-heading-summary">{dollarTemplate(value)}</h4>
    </div>
  </div>
)

const CheckoutSummary = () => {
  const { Modal, setIsOpen } = useConfirmationModal()
  const { order, canSubmitOrder, processPayment } = useCheckout()

  const openModal = useCallback(
    (e) => {
      e.preventDefault()
      setIsOpen(true)
    },
    [setIsOpen],
  )

  if (!order) {
    return null
  }

  return (
    <div className="order-summary-container">
      <div className="w-layout-grid client-credit-grid">
        <div className="div-big-text">
          <h2 className="heading-title right-margin">Order Summary</h2>
        </div>
        <div className="line-horizontal" />
        <div className="w-layout-grid summary-grid-elements">
          <SummaryElement label="Subtotal" value={order.data.attributes.subtotal} />
          <SummaryElement label="Discount" value={order.data.attributes.discount} />
          <SummaryElement label="Credit" value={order.data.attributes.creditApplied} />
          <SummaryElement label="Boutique" value={order.data.attributes.boutiqueShippingPrice} />
          <SummaryElement label="Shipping" value={order.data.attributes.shippingTotal} />
        </div>
        <div className="line-horizontal" />
        <div className="w-layout-grid main-summary-order-elements">
          <div className="div-big-text">
            <h4 className="heading-title-summary">Total</h4>
          </div>
          <div className="div-big-text">
            <h4 className="heading-title-summary">{dollarTemplate(order.data.attributes.total)}</h4>
          </div>
        </div>
        <div className="div-block-224">
          <div className="w-layout-grid grid-56">
            <button
              type="button"
              id="w-node-_0a96a71b-ab9d-d8dd-f08d-1d36e64263d5-80e80a52"
              className={classNames('button w-inline-block', { disabled: !canSubmitOrder })}
              disabled={!canSubmitOrder}
              onClick={openModal}
            >
              <div className="icon right-margin w-embed">
                <CheckRoundedIcon />
              </div>
              <div>Pay Now</div>
            </button>
          </div>
        </div>
      </div>
      <Modal
        onContinue={() => {
          setIsOpen(false)
          processPayment()
        }}
      />
    </div>
  )
}

export default CheckoutSummary
