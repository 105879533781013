import { useCallback, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import cn from 'classnames'
import values from 'lodash/values'

import { apiErrorMessage } from 'lib/utils'
import ErrorMessage from 'components/common/ErrorMessage'

const PhotographerResetPassword = () => {
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm()

  const onSubmit = useCallback(async () => {
    setLoading(true)
    setSuccess(null)
    setApiError(null)
    try {
      setLoading(false)
      setSuccess(true)
    } catch (error) {
      setApiError(apiErrorMessage(error))
      setLoading(false)
    }
  }, [])

  return (
    <div id="show-password" data-w-id="eecad620-b05a-d726-6d01-379529be50f5">
      <div className="w-layout-grid grid-84">
        <div className="line-horizontal margins" />
        <form className="w-layout-grid grid-form _1fr" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            rules={{
              required: { value: true, message: 'New password is required' },
              minLength: { value: 4, message: 'New password length 4' },
            }}
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <div
                id="w-node-a184e61d-5a93-35ac-408d-8379ec332cac-41e80a1e"
                className="input-container paper-type"
              >
                <div className="label-container">
                  <div className="div-block-150">
                    <div className="sub-label-form">new password</div>
                  </div>
                </div>
                <div className="form-block-input w-form">
                  <div className="form-input select-field">
                    <div className="select-field-wrapper">
                      <input
                        {...field}
                        type="password"
                        className="text-field-2 font-size-14 w-input"
                        maxLength="256"
                        name="password"
                        placeholder="●●●●●●●●"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
          <Controller
            rules={{
              required: { value: true, message: 'Confirm new password is required' },
              minLength: { value: 4, message: 'Confirm new password length 4' },
              validate: (value) =>
                value === watch('password') || "Confirmation password doesn't match new password",
            }}
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <div
                id="w-node-a184e61d-5a93-35ac-408d-8379ec332cac-41e80a1e"
                className="input-container paper-type"
              >
                <div className="label-container">
                  <div className="div-block-150">
                    <div className="sub-label-form">new password confirmation</div>
                  </div>
                </div>
                <div className="form-block-input w-form">
                  <div className="form-input select-field">
                    <div className="select-field-wrapper">
                      <input
                        {...field}
                        type="password"
                        className="text-field-2 font-size-14 w-input"
                        maxLength="256"
                        name="password"
                        placeholder="●●●●●●●●"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          />

          {(values(errors).length || apiError) && (
            <div className="form-item-2">
              <ErrorMessage errors={values(errors)?.[0]?.message || apiError} />
            </div>
          )}

          <div className="button-form-container">
            <button
              type="submit"
              data-w-id="a184e61d-5a93-35ac-408d-8379ec332ccb"
              disabled={isSubmitting || loading}
              className={cn('button-2 w-inline-block', {
                disabled: loading || isSubmitting,
              })}
            >
              <div>{loading || isSubmitting ? 'Please wait...' : 'Save New Password'}</div>
            </button>
          </div>
        </form>
        {success && <p>Password updated</p>}
      </div>
    </div>
  )
}

export default PhotographerResetPassword
