import { copyToClipboard } from 'lib/utils'

const ReferralUrl = ({ referralCode }) => {
  const referralUrl = `${process.env.REACT_APP_BASE_URL}refer/${referralCode}`

  return (
    <div className="w-layout-grid grid-form auto _1fr-right">
      <div
        id="w-node-a9aac738-f91f-229c-42ce-b1c9ecd0c698-04e80a14"
        className="input-container paper-type"
      >
        <div className="label-container">
          <div className="label-subcontainer">
            <div className="sub-label-form">Refferal url</div>
          </div>
        </div>
        <div className="form-block-input w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="form-input select-field"
          >
            <div className="select-field-wrapper">
              <input
                type="text"
                className="text-field-2 font-size-14 active w-input"
                maxLength="256"
                name="field-4"
                data-name="Field 4"
                disabled
                placeholder={referralUrl}
                id="data-jscolor"
              />
            </div>
          </form>
        </div>
      </div>
      <button
        type="button"
        id="w-node-a9aac738-f91f-229c-42ce-b1c9ecd0c6a7-04e80a14"
        className="button-2 secondary w-inline-block"
        onClick={() => copyToClipboard(referralUrl)}
      >
        <div>Copy Link</div>
      </button>
    </div>
  )
}

export default ReferralUrl
