import { useCallback, useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import httpClient from 'httpClient'

import './LabOrders.css'

import { apiErrorMessage } from 'lib/utils'

import LabOrdersHeader from 'components/LabOrders/LabOrdersHeader'
import LabOrders from 'components/LabOrders'
import Loading from 'components/common/Loading'
import ErrorMessage from 'components/common/ErrorMessage'

const LabOrdersPage = () => {
  const location = useLocation()
  const [deleting, setDeleting] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setApiError] = useState(null)
  const [orders, setOrders] = useState(null)
  const [metadata, setMetadata] = useState(null)

  const searchOrders = useCallback(async () => {
    try {
      setLoading('Loading orders')
      setApiError(null)
      const { data, meta } = await httpClient(`lab_orders/search${location.search ?? ''}`)
      setOrders(data)
      setMetadata(meta)
      setLoading(false)
    } catch (err) {
      setApiError(apiErrorMessage(err))
      setLoading(false)
    }
  }, [location])

  const handleDeleteOrders = useCallback(async (id) => {
    try {
      setDeleting(true)
      await httpClient.delete(`lab_orders/${id}`)
      searchOrders()
      setDeleting(false)
    } catch (err) {
      setApiError(apiErrorMessage(err))
      setDeleting(false)
    }
  }, [])

  useEffect(() => {
    searchOrders()
  }, [location])

  if (deleting) {
    return <Loading message="Deleting order" />
  }

  if (error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <div className="w-layout-grid grid-main-container">
      <LabOrdersHeader isAdmin={metadata?.currentUserIsAdmin} location={location} />
      {loading ? (
        <Loading message="Loading orders" />
      ) : (
        <LabOrders
          orders={orders}
          onDelete={handleDeleteOrders}
          isAdmin={metadata?.currentUserIsAdmin}
        />
      )}
    </div>
  )
}

export default LabOrdersPage
