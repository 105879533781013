import { useState, useCallback } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'

import './Login.css'

import axios from 'axios'
import { apiErrorMessage, setAuth } from 'lib/utils'

import { ReactComponent as VisibilityIcon } from 'images/icons/visibility.svg'
import ErrorMessage from 'components/common/ErrorMessage'
import Heading from 'components/common/Heading'

const Login = () => {
  const { register, handleSubmit } = useForm()
  const [apiError, setApiError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hiddenPassword, setHiddenPassword] = useState(true)
  const { state } = useLocation()
  const navigate = useNavigate()

  const handleToogleVisibility = useCallback(() => setHiddenPassword((prev) => !prev), [])

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      setApiError(null)
      const res = await axios.post(`${process.env.REACT_APP_API_URL}login`, data)
      setAuth(res.data)
      setLoading(false)
      navigate(state?.from?.pathname ?? '/lab-orders')
    } catch (error) {
      setLoading(false)
      setApiError(apiErrorMessage(error))
    }
  }

  return (
    <div className="div-full-screen white-smoke">
      <div className="div-block-183 add-block">
        <img src="images/input-onlinepngtools.png" loading="lazy" alt="" className="image" />
        <Heading title="Login to" withBrandName />
        <div className="form-block-3 w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="form-9"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-item-2">
              <div className="text-input__field-wrapper">
                <input
                  type="email"
                  className="text-input-2 w-input"
                  maxLength="256"
                  name="Email-2"
                  data-name="Email 2"
                  placeholder="Email"
                  id="Email-2"
                  {...register('email')}
                  required
                />
              </div>
            </div>
            <div className="form-item-2">
              <div className="text-input__field-wrapper">
                <input
                  type={hiddenPassword ? 'password' : 'text'}
                  className="text-input-2 w-input"
                  maxLength="256"
                  name="Password-2"
                  data-name="Password 2"
                  placeholder="Password"
                  id="Password-2"
                  required
                  {...register('password')}
                />
                <div className="eye-2">
                  <div
                    role="button"
                    className="icon w-embed"
                    onClick={handleToogleVisibility}
                    onKeyPress={handleToogleVisibility}
                  >
                    <VisibilityIcon />
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className={classNames('main-button button-2 more-margin w-button', {
                disabled: loading,
              })}
            >
              {loading ? 'Please wait...' : 'Sign In Now'}
            </button>
          </form>
          {apiError && <ErrorMessage errors={apiError} />}
        </div>
        <div className="div-block-182 black-upper-line">
          <div className="div-block-184">
            <div className="form-item-3">
              <div className="text-block-34">
                {"Don't have an account yet? "}
                <Link to="/sign-up" className="link-3">
                  Sign Up
                </Link>
              </div>
            </div>
            <div className="form-item-3">
              <div className="text-block-34">
                {'Forgot Password? '}
                <Link to="/forgot-password" className="link-3">
                  Reset
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
