import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { ReactComponent as PlusIcon } from 'images/icons/plus.svg'
import { ACCEPTED_IMAGE_TYPES } from 'lib/const'
import { ReactComponent as UploadIcon } from 'images/icons/upload.svg'

const DropZone = ({ onUpload }) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => onUpload(file))
  }, [])

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ACCEPTED_IMAGE_TYPES,
    onDrop,
  })

  return (
    <div data-ix="fade-up-1" className="accordion js-accordion">
      <div className="accordion__item js-accordion-item">
        <div className="accordion-header js-accordion-header">
          <div className="accordion-item-2">
            <div className="icon small x2 right-margin w-embed">
              <PlusIcon />
            </div>
            <div>Add / Select Photos</div>
          </div>
        </div>
        <div className="accordion-item-content">
          <div data-ix="hide-on-load" className="accordion-body js-accordion-body">
            <div className="input-container add-notes">
              <div
                className="upload-div"
                {...getRootProps()}
                onClick={open}
                onKeyPress={open}
                role="button"
              >
                <div className="info-print-order-product-container">
                  <div className="icon small right-margin w-embed">
                    <UploadIcon />
                  </div>
                  <div>
                    <input {...getInputProps()} />
                    <div>Save and upload</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DropZone
