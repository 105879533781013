const useSort = () => {
  // Sort images in ascending order (A-Z) based on name
  function sortAscendingByName(items) {
    items.sort((a, b) => a.attributes.photoFileName.localeCompare(b.attributes.photoFileName))
    return items
  }

  // Sort items in descending order (Z-A) based on name
  function sortDescendingByName(items) {
    items.sort((a, b) => b.attributes.photoFileName.localeCompare(a.attributes.photoFileName))
    return items
  }

  // Sort items from newest to oldest based on date
  function sortNewestToOldest(items) {
    items.sort((a, b) => new Date(b.attributes.created_at) - new Date(a.attributes.created_at));
    return items
  }

  // Sort items from oldest to newest based on date
  function sortOldestToNewest(items) {
    items.sort((a, b) => new Date(a.attributes.created_at) - new Date(b.attributes.created_at));
    return items
  }

  return {
    sortAscendingByName,
    sortDescendingByName,
    sortNewestToOldest,
    sortOldestToNewest,
  }
}

export default useSort
