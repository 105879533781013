import Item from './Item'

const OrderSummary = ({ lineItems, onUpdateQty, onDelete }) => (
  <div className="container-rightside-order-summary no-margin">
    <div className="heading-container margin-bottom">
      <div className="grid-order-icons">
        <h2 className="heading-title right-margin">Order Summary</h2>
      </div>
    </div>

    <div className="w-layout-grid grid-element-order-summary head white smaller number">
      <div id="w-node-c769d59e-763a-a11d-1b07-716415342c00-69e80a54" className="heady center">
        <div>#</div>
      </div>
      <div className="heady print-details">
        <div>Image</div>
      </div>
      <div className="heady">
        <div>Print Details</div>
      </div>
      <div className="heady">
        <div>Details</div>
      </div>
      <div className="heady center">
        <div>Notes</div>
      </div>
      <div className="heady center">
        <div>Qty</div>
      </div>
      <div className="heady center">
        <div>Total</div>
      </div>
    </div>

    <div className="w-layout-grid grid-order-summary-container no-scroll">
      {lineItems?.map((lineItem, index) => (
        <Item
          key={lineItem.id}
          lineItem={lineItem.attributes}
          position={index}
          onUpdateQty={onUpdateQty}
          onDelete={onDelete}
        />
      ))}
    </div>
  </div>
)

export default OrderSummary
