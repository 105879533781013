import { Tooltip } from 'react-tippy'

const CustomTooltip = ({ children, title }) => (
  <Tooltip
    title={title}
    animation="scale"
    trigger="mouseenter"
    position="bottom"
    duration={200}
    arrow
    delay={[0, 50]}
    arrowType="sharp"
    theme="light"
    maxWidth={220}
  >
    {children}
  </Tooltip>
)

export default CustomTooltip
