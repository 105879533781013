import React, { useState, useEffect, useCallback } from 'react'
import ReactModal from 'react-modal'

import { isFunction } from 'lib/utils'
import { DEFAULT_ANIMATION } from 'lib/const'

const customStyles = {
  overlay: {
    background: 'none',
    zIndex: '9999999',
    inset: 0,
  },
  content: {
    inset: 0,
    border: 0,
    background: 'unset',
  },
}

export default () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    ReactModal.setAppElement('body')
  }, [])

  const Modal = ({ handleCloseModal, onContinue, title, description, icon: Icon }) => {
    const onCloseModal = useCallback(() => {
      setIsOpen(false)
      isFunction(handleCloseModal) && handleCloseModal()
    }, [handleCloseModal])

    return (
      <ReactModal isOpen={isOpen} onRequestClose={onCloseModal} style={customStyles}>
        <div
          className="hide-me-overlay"
          style={{
            display: isOpen ? 'block' : 'none',
            opacity: isOpen ? 1 : 0,
            transition: DEFAULT_ANIMATION,
          }}
        >
          <div className="modal-scroll">
            <div data-w-id="b10cc316-8271-6866-d0ef-7ee90fa4bb66" className="close-overlay" />
            <div
              className="upgrade-container"
              style={{
                transition: 'all 400ms ease-in 0s',
                transform: isOpen
                  ? 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
                  : 'translate3d(0px, 50px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
              }}
            >
              <div className="div-block-31">
                <div className="div-block-68">
                  {Icon && (
                    <div className="icon right-margin w-embed">
                      <Icon />
                    </div>
                  )}
                  <h4 className="heading-2">{title}</h4>
                </div>
                <p className="paragraph">{description}</p>
                <div className="w-layout-grid grid-20">
                  <button
                    type="button"
                    className="link-block-3-copy _2 w-inline-block"
                    onClick={onCloseModal}
                    onKeyPress={onCloseModal}
                  >
                    <div>Cancel</div>
                  </button>
                  <button
                    className="link-block-3-copy w-inline-block"
                    type="button"
                    onClick={onContinue}
                    onKeyPress={onContinue}
                  >
                    <div>Delete</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }

  return { Modal, setIsOpen }
}
