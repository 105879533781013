import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router'

const ProductSelectionAlbum = () => {
  const location = useLocation()

  const logOrder = useCallback(async () => {
    console.log('location: ', location)
  }, [location])

  useEffect(() => {
    logOrder()
  }, [location])

  return (
    <div className="w-layout-grid grid-print-order">
      <div className="sidebar-product-selection white">
        <div className="controls-block-container">
          <div className="controls-block-grid top">
            <div data-tippy-content="Back to Home" className="div-block-169-copy ghost">
              <div className="icon small w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M218.76367,103.7002,138.75684,30.96436a15.93657,15.93657,0,0,0-21.52637.00146L37.2373,103.69971A16.03108,16.03108,0,0,0,32,115.53857l0,92.09522a16.47275,16.47275,0,0,0,4.01066,10.96174A15.91729,15.91729,0,0,0,48.002,223.999H95.96484a8,8,0,0,0,8-8V167.9917a8,8,0,0,1,8-8h32a8,8,0,0,1,8,8V215.999a8,8,0,0,0,8,8h48.05731a15.40625,15.40625,0,0,0,7.53406-1.85584A16.08415,16.08415,0,0,0,224,207.999v-92.46A16.03567,16.03567,0,0,0,218.76367,103.7002Z" />
                </svg>
              </div>
            </div>
            <div data-tippy-content="Reset All" className="div-block-169-copy ghost">
              <div className="icon small w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <polyline
                    points="79.833 99.716 31.833 99.716 31.833 51.716"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <path
                    d="M190.2254,65.7746a88,88,0,0,0-124.4508,0L31.83348,99.71573"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <polyline
                    points="176.167 156.284 224.167 156.284 224.167 204.284"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                  <path
                    d="M65.7746,190.2254a88,88,0,0,0,124.4508,0l33.94112-33.94113"
                    fill="none"
                    stroke="currentcolor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="24"
                  />
                </svg>
              </div>
            </div>
            <a href="/product-selection" className="button-2 w-inline-block">
              <div className="icon right-margin w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M128,23.99805a104,104,0,1,0,104,104A104.12041,104.12041,0,0,0,128,23.99805Zm40,112H136v32a8,8,0,1,1-16,0v-32H88a8,8,0,0,1,0-16h32v-32a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
                </svg>
              </div>
              <div>Upload More</div>
            </a>
          </div>
        </div>
        <div className="print-order-sidebar grid">
          <div className="accordion-item-block">
            <div className="w-layout-grid grid-form">
              <div
                id="w-node-_95f9ce64-2aff-654a-b841-4969aaa3373c-a480b94b"
                className="input-container paper-type"
              >
                <div className="label-container">
                  <div className="div-block-150">
                    <div className="sub-label-form">choose product type</div>
                    <div className="icon mini margin-left opacity70 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="form-block-input w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                    className="form-input select-field"
                  >
                    <div className="select-field-wrapper">
                      <select
                        id="select-product"
                        name="field-3"
                        data-name="Field 3"
                        data-w-id="95f9ce64-2aff-654a-b841-4969aaa33745"
                        className="form-select-field w-select"
                      >
                        <option value="choose-one">Choose One</option>
                        <option value="albums">Albums</option>
                      </select>
                      <div className="div-block-63">
                        <img
                          src="images/chevron-down-12.svg"
                          loading="lazy"
                          alt=""
                          className="img-icon"
                        />
                      </div>
                    </div>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-album-options" className="wrapper-dynamic-album">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form _1fr">
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33752-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Choose album type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="album-type"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="artisan">Artisan Album</option>
                            <option value="matted">Matted Album</option>
                            <option value="museum">Museum Case</option>
                            <option value="folio">Double Folio</option>
                            <option value="slipcases">Matted Slipcase</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-canvas" className="wrapper-dynamic-canvas">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form">
                  <div className="input-container">
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Quantity</div>
                      </div>
                    </div>
                    <div className="form-block-input qty w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <input
                            type="number"
                            className="text-field-2 qty w-input"
                            maxLength="256"
                            name="field-4"
                            data-name="Field 4"
                            placeholder="01"
                            id="data-jscolor"
                          />
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33776-a480b94b"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">canvas size</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-size">Choose Size</option>
                            <option value="4x6 ($7.00)">4x6 ($7.00)</option>
                            <option value="5x5 ($7.00)">5x5 ($7.00)</option>
                            <option value="5x7 ($9.00)">5x7 ($9.00)</option>
                            <option value="6x9 ($14.00)">6x9 ($14.00)</option>
                            <option value="8x8 ($16.00)">8x8 ($16.00)</option>
                            <option value="7x10 ($19.00)">7x10 ($19.00)</option>
                            <option value="8x10 ($19.00)">8x10 ($19.00)</option>
                            <option value="8x12 ($25.00)">8x12 ($25.00)</option>
                            <option value="10x10 ($25.00)">10x10 ($25.00)</option>
                            <option value="12x12 ($30.00)">12x12 ($30.00)</option>
                          </select>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-layout-grid grid-form _1fr">
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33789-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">wrap style</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-style">Choose Style</option>
                            <option value="Gallery Wrap">Gallery Wrap</option>
                            <option value="Mirror Wrap">Mirror Wrap</option>
                            <option value="Black Wrap">Black Wrap</option>
                            <option value="White Wrap">White Wrap</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa3379b-a480b94b"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">frame</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="selector"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="No Frame">No Frame</option>
                            <option value="Oil and Wax Maple">Oil and Wax Maple</option>
                            <option value="White Maple">White Maple</option>
                            <option value="Light Grey Maple">Light Grey Maple</option>
                            <option value="Medium Grey Maple">Medium Grey Maple</option>
                            <option value="Satin Black Maple">Satin Black Maple</option>
                            <option value="Whitewash Ash">Whitewash Ash</option>
                            <option value="Rising White Ash">Rising White Ash</option>
                            <option value="Dark Grey Ash">Dark Grey Ash</option>
                            <option value="Satin Black Ash">Satin Black Ash</option>
                            <option value="Oil and Wax Cherry">Oil and Wax Cherry</option>
                            <option value="Aged Cherry">Aged Cherry</option>
                            <option value="Antique Cherry">Antique Cherry</option>
                            <option value="Black Cherry">Black Cherry</option>
                            <option value="Oil and Wax Walnut">Oil and Wax Walnut</option>
                            <option value="Ebony Walnut">Ebony Walnut</option>
                            <option value="Special Dark Walnut">Special Dark Walnut</option>
                            <option value="Satin Black Walnut">Satin Black Walnut</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-prints" className="wrapper-dynamic-prints">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form">
                  <div className="input-container">
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Quantity</div>
                      </div>
                    </div>
                    <div className="form-block-input qty w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <input
                            type="number"
                            className="text-field-2 qty w-input"
                            maxLength="256"
                            name="field-4"
                            data-name="Field 4"
                            placeholder="01"
                            id="data-jscolor"
                          />
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa337bf-a480b94b"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">paPer Type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="my-field-3"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="Lustre">Lustre</option>
                            <option value="Satin">Satin</option>
                            <option value="Photo Matte">Photo Matte</option>
                            <option value="Ultra Smooth">Ultra Smooth</option>
                            <option value="Platine">Platine</option>
                            <option value="Canson Rag">Canson Rag</option>
                            <option value="Museum Etching">Museum Etching</option>
                            <option value="Deckled with border">Deckled with border</option>
                            <option value="Deckled no border">Deckled no border</option>
                            <option value="Circle Deckled with border">
                              Circle Deckled with border
                            </option>
                            <option value="Circle Deckled no border">
                              Circle Deckled no border
                            </option>
                            <option value="Canvas Gallery Wrap">Canvas Gallery Wrap</option>
                            <option value="Canvas Mirror Wrap">Canvas Mirror Wrap</option>
                            <option value="Canvas White Wrap">Canvas White Wrap</option>
                            <option value="Canvas Black Wrap">Canvas Black Wrap</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-layout-grid grid-form">
                  <div className="input-container">
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Print Size (IN)</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-size">Choose Size</option>
                            <option value="4x6 ($7.00)">4x6 ($7.00)</option>
                            <option value="5x5 ($7.00)">5x5 ($7.00)</option>
                            <option value="5x7 ($9.00)">5x7 ($9.00)</option>
                            <option value="6x9 ($14.00)">6x9 ($14.00)</option>
                            <option value="8x8 ($16.00)">8x8 ($16.00)</option>
                            <option value="7x10 ($19.00)">7x10 ($19.00)</option>
                            <option value="8x10 ($19.00)">8x10 ($19.00)</option>
                            <option value="8x12 ($25.00)">8x12 ($25.00)</option>
                            <option value="10x10 ($25.00)">10x10 ($25.00)</option>
                            <option value="12x12 ($30.00)">12x12 ($30.00)</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa337e4-a480b94b"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Finishing Service</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="choose-finishing"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="framing">Framing</option>
                            <option value="matting">Matting</option>
                            <option value="mounting">Mounting</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-artisan" className="wrapper-dynamic-artisan">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form auto">
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa337f9-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">cover fabric</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Willowbrook">Willowbrook</option>
                            <option value="Bronze">Bronze</option>
                            <option value="Cadet Grey">Cadet Grey</option>
                            <option value="Bisque">Bisque</option>
                            <option value="Cape Cod Sand">Cape Cod Sand</option>
                            <option value="Spice">Spice</option>
                            <option value="Dark Amber">Dark Amber</option>
                            <option value="Eggplant">Eggplant</option>
                            <option value="Fawn">Fawn</option>
                            <option value="Goldenrod">Goldenrod</option>
                            <option value="Heathered Sand">Heathered Sand</option>
                            <option value="Heathered Slate">Heathered Slate</option>
                            <option value="Heathered Walnut">Heathered Walnut</option>
                            <option value="Honey">Honey</option>
                            <option value="Hibiscus">Hibiscus</option>
                            <option value="White Marigold">White Marigold</option>
                            <option value="Symphony Blue">Symphony Blue</option>
                            <option value="Pastel Pink">Pastel Pink</option>
                            <option value="Cobalt">Cobalt</option>
                            <option value="Copper Fox">Copper Fox</option>
                            <option value="Agave">Agave</option>
                            <option value="Blackout">Blackout</option>
                            <option value="Light Grey">Light Grey</option>
                            <option value="Oat">Oat</option>
                            <option value="Stone">Stone</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa3380b-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">album size</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="10x10">10x10</option>
                            <option value="10x12 Vertical">10x12 Vertical</option>
                            <option value="10x12 Horizontal">10x12 Horizontal</option>
                            <option value="8x16">8x16</option>
                            <option value="12x12">12x12</option>
                            <option value="11x14 Vertical">11x14 Vertical</option>
                            <option value="11x14 Horizontal">11x14 Horizontal</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div id="show-album-options" className="wrapper-dynamic-album">
                    <div className="accordion-item-block no-margin">
                      <div className="w-layout-grid grid-form _1fr">
                        <div
                          id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33820-a480b94b"
                          className="input-container"
                        >
                          <div className="label-container">
                            <div className="label-subcontainer">
                              <div className="sub-label-form">cover fabric</div>
                            </div>
                          </div>
                          <div className="form-block-input w-form">
                            <form
                              id="email-form"
                              name="email-form"
                              data-name="Email Form"
                              method="get"
                              className="form-input select-field"
                            >
                              <div className="select-field-wrapper">
                                <select
                                  id="size"
                                  name="field-3"
                                  data-name="Field 3"
                                  className="form-select-field w-select"
                                >
                                  <option value="choose-one">Choose One</option>
                                  <option value="artisan-album">Artisan Album</option>
                                  <option value="Matted-Album">Matted Album</option>
                                  <option value="Museum Case">Museum Case</option>
                                  <option value="Double Folio">Double Folio</option>
                                  <option value="Matted Slipcase">Matted Slipcase</option>
                                </select>
                                <div className="div-block-63">
                                  <img
                                    src="images/chevron-down-12.svg"
                                    loading="lazy"
                                    alt=""
                                    className="img-icon"
                                  />
                                </div>
                              </div>
                            </form>
                            <div className="w-form-done">
                              <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                              <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33832-a480b94b"
                    className="w-layout-grid grid-form _1fr"
                  >
                    <div
                      id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33833-a480b94b"
                      className="input-container large"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">Interior fabric</div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <select
                              id="size"
                              name="field-3"
                              data-name="Field 3"
                              className="form-select-field w-select"
                            >
                              <option value="choose-one">Choose One</option>
                              <option value="Willowbrook">Willowbrook</option>
                              <option value="Bronze">Bronze</option>
                              <option value="Cadet Grey">Cadet Grey</option>
                              <option value="Bisque">Bisque</option>
                              <option value="Cape Cod Sand">Cape Cod Sand</option>
                              <option value="Spice">Spice</option>
                              <option value="Dark Amber">Dark Amber</option>
                              <option value="Eggplant">Eggplant</option>
                              <option value="Fawn">Fawn</option>
                              <option value="Goldenrod">Goldenrod</option>
                              <option value="Heathered Sand">Heathered Sand</option>
                              <option value="Heathered Slate">Heathered Slate</option>
                              <option value="Heathered Walnut">Heathered Walnut</option>
                              <option value="Honey">Honey</option>
                              <option value="Hibiscus">Hibiscus</option>
                              <option value="White Marigold">White Marigold</option>
                              <option value="Symphony Blue">Symphony Blue</option>
                              <option value="Pastel Pink">Pastel Pink</option>
                              <option value="Cobalt">Cobalt</option>
                              <option value="Copper Fox">Copper Fox</option>
                              <option value="Agave">Agave</option>
                              <option value="Blackout">Blackout</option>
                              <option value="Light Grey">Light Grey</option>
                              <option value="Oat">Oat</option>
                              <option value="Stone">Stone</option>
                            </select>
                            <div className="div-block-63">
                              <img
                                src="images/chevron-down-12.svg"
                                loading="lazy"
                                alt=""
                                className="img-icon"
                              />
                            </div>
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33845-a480b94b"
                      className="input-container quantity _2"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">number of spreads</div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <select
                              id="size"
                              name="field-3"
                              data-name="Field 3"
                              className="form-select-field w-select"
                            >
                              <option value="choose-one">Choose One</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                              <option value="32">32</option>
                              <option value="33">33</option>
                              <option value="34">34</option>
                              <option value="35">35</option>
                              <option value="36">36</option>
                              <option value="37">37</option>
                              <option value="38">38</option>
                              <option value="39">39</option>
                              <option value="40">40</option>
                            </select>
                            <div className="div-block-63">
                              <img
                                src="images/chevron-down-12.svg"
                                loading="lazy"
                                alt=""
                                className="img-icon"
                              />
                            </div>
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33854-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Debossing or Foil Stamping</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Blind Debossing">Blind Debossing</option>
                            <option value="Gold Foil Stamping">Gold Foil Stamping</option>
                            <option value="Silver Foil Stamping">Silver Foil Stamping</option>
                            <option value="None">None</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33867-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          Debossing / Foil Stamping text | <span className="green">top line</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <input
                            type="text"
                            className="text-field-2 w-input"
                            maxLength="256"
                            name="field-7"
                            data-name="Field 7"
                            placeholder="Example Text"
                            id="field-7"
                            required=""
                          />
                        </div>
                      </form>
                      <div className="success-message-3 w-form-done" />
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_3380b0e3-683e-864d-37e7-548450c35c1f-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">
                          <span className="grey">(OPTIONAL)</span> Debossing / Foil Stamping text |{' '}
                          <span className="green">bottom line</span>{' '}
                        </div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <input
                            type="text"
                            className="text-field-2 w-input"
                            maxLength="256"
                            name="field-7"
                            data-name="Field 7"
                            placeholder="Example Text"
                            id="field-7"
                            required=""
                          />
                        </div>
                      </form>
                      <div className="success-message-3 w-form-done" />
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33875-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Clamshell Upgrade</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Traditional Clamshell">Traditional Clamshell</option>
                            <option value="Split Case">Split Case</option>
                            <option value="None">None</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-matted" className="wrapper-dynamic-matted">
              <div className="accordion-item-block no-margin">
                <div className="w-layout-grid grid-form">
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa3388d-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">cover fabric</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Cover Fabric">Cover Fabric</option>
                            <option value="Interior Fabric">Interior Fabric</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa338d7-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Interior fabric</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Willowbrook">Willowbrook</option>
                            <option value="Bronze">Bronze</option>
                            <option value="Cadet Grey">Cadet Grey</option>
                            <option value="Bisque">Bisque</option>
                            <option value="Cape Cod Sand">Cape Cod Sand</option>
                            <option value="Spice">Spice</option>
                            <option value="Dark Amber">Dark Amber</option>
                            <option value="Eggplant">Eggplant</option>
                            <option value="Fawn">Fawn</option>
                            <option value="Goldenrod">Goldenrod</option>
                            <option value="Heathered Sand">Heathered Sand</option>
                            <option value="Heathered Slate">Heathered Slate</option>
                            <option value="Heathered Walnut">Heathered Walnut</option>
                            <option value="Honey">Honey</option>
                            <option value="Hibiscus">Hibiscus</option>
                            <option value="White Marigold">White Marigold</option>
                            <option value="Symphony Blue">Symphony Blue</option>
                            <option value="Pastel Pink">Pastel Pink</option>
                            <option value="Cobalt">Cobalt</option>
                            <option value="Copper Fox">Copper Fox</option>
                            <option value="Agave">Agave</option>
                            <option value="Blackout">Blackout</option>
                            <option value="Light Grey">Light Grey</option>
                            <option value="Oat">Oat</option>
                            <option value="Stone">Stone</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa3389f-a480b94b"
                    className="input-container"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">Select paper type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="Museum Etching">Museum Etching</option>
                            <option value="Canson Rag">Canson Rag</option>
                            <option value="Ultra Smooth">Ultra Smooth</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa338c4-a480b94b"
                    className="input-container quantity _2"
                  >
                    <div className="label-container">
                      <div className="label-subcontainer">
                        <div className="sub-label-form">number of Images</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="size"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="choose-one">Choose One</option>
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="14">14</option>
                            <option value="16">16</option>
                            <option value="18">18</option>
                            <option value="20">20</option>
                            <option value="22">22</option>
                            <option value="24">24</option>
                            <option value="26">26</option>
                            <option value="28">28</option>
                            <option value="30">30</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa338e9-a480b94b"
                    className="w-layout-grid grid-form"
                  >
                    <div
                      id="w-node-_95f9ce64-2aff-654a-b841-4969aaa338ea-a480b94b"
                      className="input-container"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">Debossing or Foil Stamping</div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <select
                              id="size"
                              name="field-3"
                              data-name="Field 3"
                              className="form-select-field w-select"
                            >
                              <option value="choose-one">Choose One</option>
                              <option value="Blind Debossing">Blind Debossing</option>
                              <option value="Gold Foil Stamping">Gold Foil Stamping</option>
                              <option value="Silver Foil Stamping">Silver Foil Stamping</option>
                              <option value="None">None</option>
                            </select>
                            <div className="div-block-63">
                              <img
                                src="images/chevron-down-12.svg"
                                loading="lazy"
                                alt=""
                                className="img-icon"
                              />
                            </div>
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_95f9ce64-2aff-654a-b841-4969aaa338fd-a480b94b"
                      className="input-container"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">
                            Debossing / Foil Stamping text |<span className="green"> top line</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <input
                              type="text"
                              className="text-field-2 w-input"
                              maxLength="256"
                              name="field-7"
                              data-name="Field 7"
                              placeholder="Example Text"
                              id="field-7"
                              required=""
                            />
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_85a63cac-e2c7-080c-e07e-357b3d741095-a480b94b"
                      className="input-container"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">
                            <span className="grey">(OPTIONAL)</span> Debossing / Foil Stamping text
                            |<span className="green"> Bottom line</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <input
                              type="text"
                              className="text-field-2 w-input"
                              maxLength="256"
                              name="field-7"
                              data-name="Field 7"
                              placeholder="Example Text"
                              id="field-7"
                              required=""
                            />
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-_95f9ce64-2aff-654a-b841-4969aaa3390d-a480b94b"
                      className="input-container"
                    >
                      <div className="label-container">
                        <div className="label-subcontainer">
                          <div className="sub-label-form">Clamshell Upgrade</div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="form-input select-field"
                        >
                          <div className="select-field-wrapper">
                            <select
                              id="size"
                              name="field-3"
                              data-name="Field 3"
                              className="form-select-field w-select"
                            >
                              <option value="choose-one">Choose One</option>
                              <option value="Traditional Clamshell">Traditional Clamshell</option>
                              <option value="Split Case">Split Case</option>
                              <option value="None">None</option>
                            </select>
                            <div className="div-block-63">
                              <img
                                src="images/chevron-down-12.svg"
                                loading="lazy"
                                alt=""
                                className="img-icon"
                              />
                            </div>
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33920-a480b94b"
                    className="line-horizontal margins"
                  />
                </div>
              </div>
            </div>
            <div id="show-frame" className="wrapper-dynamic-framing">
              <div id="frame" className="frame">
                <div className="w-layout-grid grid-dynamic _1fr">
                  <div
                    id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33924-a480b94b"
                    className="input-container paper-type"
                  >
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">Choose framing type</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="choose-frame"
                            name="field-3"
                            data-name="Field 3"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose One</option>
                            <option value="standard">Standard Framing</option>
                            <option value="premium">Premium Framing</option>
                          </select>

                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-slipcases" className="wrapper-dynamic-slipcases">
              <div className="w-layout-grid grid-dynamic _1fr">
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33939-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Matted Slipcase Fabric</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="size"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="Willowbrook">Willowbrook</option>
                          <option value="Bronze">Bronze</option>
                          <option value="Cadet Grey">Cadet Grey</option>
                          <option value="Bisque">Bisque</option>
                          <option value="Cape Cod Sand">Cape Cod Sand</option>
                          <option value="Spice">Spice</option>
                          <option value="Dark Amber">Dark Amber</option>
                          <option value="Eggplant">Eggplant</option>
                          <option value="Fawn">Fawn</option>
                          <option value="Goldenrod">Goldenrod</option>
                          <option value="Heathered Sand">Heathered Sand</option>
                          <option value="Heathered Slate">Heathered Slate</option>
                          <option value="Heathered Walnut">Heathered Walnut</option>
                          <option value="Honey">Honey</option>
                          <option value="Hibiscus">Hibiscus</option>
                          <option value="White Marigold">White Marigold</option>
                          <option value="Symphony Blue">Symphony Blue</option>
                          <option value="Pastel Pink">Pastel Pink</option>
                          <option value="Cobalt">Cobalt</option>
                          <option value="Copper Fox">Copper Fox</option>
                          <option value="Agave">Agave</option>
                          <option value="Blackout">Blackout</option>
                          <option value="Light Grey">Light Grey</option>
                          <option value="Oat">Oat</option>
                          <option value="Stone">Stone</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa3394b-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">select paper type</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                        <select
                          data-name="Field 3"
                          name="field-3"
                          id="field"
                          className="form-select-field w-select"
                        >
                          <option value="Choose One">Choose One</option>
                          <option value="Museum Etching">Museum Etching</option>
                          <option value="Canson Rag">Canson Rag</option>
                          <option value="Platine">Platine</option>
                          <option value="Ultra Smooth">Ultra Smooth</option>
                        </select>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa3395d-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Four-Flap Color Option</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          data-name="Field 3"
                          name="field-3"
                          id="field"
                          className="form-select-field w-select"
                        >
                          <option value="Choose One">Choose One</option>
                          <option value="sage">Sage</option>
                          <option value="Smoke">Smoke</option>
                          <option value="Walnut">Walnut</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa3396f-a480b94b"
                  className="input-container"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">Debossing or Foil Stamping </div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="size"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="Blind Debossing">Blind Debossing</option>
                          <option value="Gold Foil Stamping">Gold Foil Stamping</option>
                          <option value="Silver Foil Stamping">Silver Foil Stamping</option>
                          <option value="None">None</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33981-a480b94b"
                  className="input-container"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">
                        Debossing / Foil Stamping text |<span className="green"> top line</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <input
                          type="text"
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="field-7"
                          data-name="Field 7"
                          placeholder="Example Text"
                          id="field-7"
                          required=""
                        />
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-cf803ae4-b21a-7592-4d66-d4fc5822967c-a480b94b"
                  className="input-container"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">
                        <span className="grey">(OPTIONAL)</span> Debossing / Foil Stamping text |
                        <span className="green"> Bottom line</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <input
                          type="text"
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="field-7"
                          data-name="Field 7"
                          placeholder="Example Text"
                          id="field-7"
                          required=""
                        />
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-folios" className="wrapper-dynamic-folios">
              <div className="w-layout-grid grid-dynamic _1fr">
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33995-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Double Folio Orientation</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          data-name="Field 3"
                          name="field-3"
                          id="field"
                          className="form-select-field w-select"
                        >
                          <option value="Choose One">Choose One</option>
                          <option value="horizontal">Horizontal</option>
                          <option value="vertical">Vertical</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa339a7-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Double Folio Fabric </div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="size"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="Willowbrook">Willowbrook</option>
                          <option value="Bronze">Bronze</option>
                          <option value="Cadet Grey">Cadet Grey</option>
                          <option value="Bisque">Bisque</option>
                          <option value="Cape Cod Sand">Cape Cod Sand</option>
                          <option value="Spice">Spice</option>
                          <option value="Dark Amber">Dark Amber</option>
                          <option value="Eggplant">Eggplant</option>
                          <option value="Fawn">Fawn</option>
                          <option value="Goldenrod">Goldenrod</option>
                          <option value="Heathered Sand">Heathered Sand</option>
                          <option value="Heathered Slate">Heathered Slate</option>
                          <option value="Heathered Walnut">Heathered Walnut</option>
                          <option value="Honey">Honey</option>
                          <option value="Hibiscus">Hibiscus</option>
                          <option value="White Marigold">White Marigold</option>
                          <option value="Symphony Blue">Symphony Blue</option>
                          <option value="Pastel Pink">Pastel Pink</option>
                          <option value="Cobalt">Cobalt</option>
                          <option value="Copper Fox">Copper Fox</option>
                          <option value="Agave">Agave</option>
                          <option value="Blackout">Blackout</option>
                          <option value="Light Grey">Light Grey</option>
                          <option value="Oat">Oat</option>
                          <option value="Stone">Stone</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa339b9-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Choose paper type</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          data-name="Field 3"
                          name="field-3"
                          id="field"
                          className="form-select-field w-select"
                        >
                          <option value="Choose One">Choose One</option>
                          <option value="Museum Etching">Museum Etching</option>
                          <option value="Canson Rag">Canson Rag</option>
                          <option value="Platine">Platine</option>
                          <option value="Ultra Smooth">Ultra Smooth</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa339cb-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Slipcase Option</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          data-name="Field 3"
                          name="field-3"
                          id="field"
                          className="form-select-field w-select"
                        >
                          <option value="Choose One">Choose One</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa339dd-a480b94b"
                  className="input-container quantity _2"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">Slipcase Fabric</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="size"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="Willowbrook">Willowbrook</option>
                          <option value="Bronze">Bronze</option>
                          <option value="Cadet Grey">Cadet Grey</option>
                          <option value="Bisque">Bisque</option>
                          <option value="Cape Cod Sand">Cape Cod Sand</option>
                          <option value="Spice">Spice</option>
                          <option value="Dark Amber">Dark Amber</option>
                          <option value="Eggplant">Eggplant</option>
                          <option value="Fawn">Fawn</option>
                          <option value="Goldenrod">Goldenrod</option>
                          <option value="Heathered Sand">Heathered Sand</option>
                          <option value="Heathered Slate">Heathered Slate</option>
                          <option value="Heathered Walnut">Heathered Walnut</option>
                          <option value="Honey">Honey</option>
                          <option value="Hibiscus">Hibiscus</option>
                          <option value="White Marigold">White Marigold</option>
                          <option value="Symphony Blue">Symphony Blue</option>
                          <option value="Pastel Pink">Pastel Pink</option>
                          <option value="Cobalt">Cobalt</option>
                          <option value="Copper Fox">Copper Fox</option>
                          <option value="Agave">Agave</option>
                          <option value="Blackout">Blackout</option>
                          <option value="Light Grey">Light Grey</option>
                          <option value="Oat">Oat</option>
                          <option value="Stone">Stone</option>
                        </select>
                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa339ee-a480b94b"
                  className="input-container"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">Debossing or Foil Stamping</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="size"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="Blind Debossing">Blind Debossing</option>
                          <option value="Gold Foil Stamping">Gold Foil Stamping</option>
                          <option value="Silver Foil Stamping">Silver Foil Stamping</option>
                          <option value="None">None</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33a01-a480b94b"
                  className="input-container"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">
                        Debossing / Foil Stamping text | <span className="green">top line</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <input
                          type="text"
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="field-7"
                          data-name="Field 7"
                          placeholder="Example Text"
                          id="field-7"
                          required=""
                        />
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_7982e0e8-4a86-3a85-8509-7ef9940ae7f2-a480b94b"
                  className="input-container"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">
                        <span className="grey">(optional)</span> Debossing / Foil Stamping text |{' '}
                        <span className="green">bottom line </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <input
                          type="text"
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="field-7"
                          data-name="Field 7"
                          placeholder="Example Text"
                          id="field-7"
                          required=""
                        />
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-museum" className="wrapper-dynamic-museum">
              <div className="w-layout-grid grid-dynamic _1fr">
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33a15-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Choose Museum Case Size</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          data-name="Field 3"
                          name="field-3"
                          id="field"
                          className="form-select-field w-select"
                        >
                          <option value="Choose One">Choose One</option>
                          <option value="8x10">8x10</option>
                          <option value="11x14">11x14</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33a29-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Select Paper Type</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          data-name="Field 3"
                          name="field-3"
                          id="field"
                          className="form-select-field w-select"
                        >
                          <option value="Choose One">Choose One</option>
                          <option value="Museum Etching">Museum Etching</option>
                          <option value="Canson Rag">Canson Rag</option>
                          <option value="Platine">Platine</option>
                          <option value="Ultra Smooth">Ultra Smooth</option>
                          <option value="Deckled No Border">Deckled No Border</option>
                          <option value="Deckled With Border">Deckled With Border</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33a3c-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Select Cover Fabric</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="size"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="Willowbrook">Willowbrook</option>
                          <option value="Bronze">Bronze</option>
                          <option value="Cadet Grey">Cadet Grey</option>
                          <option value="Bisque">Bisque</option>
                          <option value="Cape Cod Sand">Cape Cod Sand</option>
                          <option value="Spice">Spice</option>
                          <option value="Dark Amber">Dark Amber</option>
                          <option value="Eggplant">Eggplant</option>
                          <option value="Fawn">Fawn</option>
                          <option value="Goldenrod">Goldenrod</option>
                          <option value="Heathered Sand">Heathered Sand</option>
                          <option value="Heathered Slate">Heathered Slate</option>
                          <option value="Heathered Walnut">Heathered Walnut</option>
                          <option value="Honey">Honey</option>
                          <option value="Hibiscus">Hibiscus</option>
                          <option value="White Marigold">White Marigold</option>
                          <option value="Symphony Blue">Symphony Blue</option>
                          <option value="Pastel Pink">Pastel Pink</option>
                          <option value="Cobalt">Cobalt</option>
                          <option value="Copper Fox">Copper Fox</option>
                          <option value="Agave">Agave</option>
                          <option value="Blackout">Blackout</option>
                          <option value="Light Grey">Light Grey</option>
                          <option value="Oat">Oat</option>
                          <option value="Stone">Stone</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33a4f-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Select Tray Fabric</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="size"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="Willowbrook">Willowbrook</option>
                          <option value="Bronze">Bronze</option>
                          <option value="Cadet Grey">Cadet Grey</option>
                          <option value="Bisque">Bisque</option>
                          <option value="Cape Cod Sand">Cape Cod Sand</option>
                          <option value="Spice">Spice</option>
                          <option value="Dark Amber">Dark Amber</option>
                          <option value="Eggplant">Eggplant</option>
                          <option value="Fawn">Fawn</option>
                          <option value="Goldenrod">Goldenrod</option>
                          <option value="Heathered Sand">Heathered Sand</option>
                          <option value="Heathered Slate">Heathered Slate</option>
                          <option value="Heathered Walnut">Heathered Walnut</option>
                          <option value="Honey">Honey</option>
                          <option value="Hibiscus">Hibiscus</option>
                          <option value="White Marigold">White Marigold</option>
                          <option value="Symphony Blue">Symphony Blue</option>
                          <option value="Pastel Pink">Pastel Pink</option>
                          <option value="Cobalt">Cobalt</option>
                          <option value="Copper Fox">Copper Fox</option>
                          <option value="Agave">Agave</option>
                          <option value="Blackout">Blackout</option>
                          <option value="Light Grey">Light Grey</option>
                          <option value="Oat">Oat</option>
                          <option value="Stone">Stone</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33a62-a480b94b"
                  className="input-container quantity _2"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">number of Matted Prints</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="size"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                        </select>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33a71-a480b94b"
                  className="input-container"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">Debossing or Foil Stamping</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="size"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="Blind Debossing">Blind Debossing</option>
                          <option value="Gold Foil Stamping">Gold Foil Stamping</option>
                          <option value="Silver Foil Stamping">Silver Foil Stamping</option>
                          <option value="None">None</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33a84-a480b94b"
                  className="input-container"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">
                        Debossing / Foil Stamping text |<span className="green"> top line</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <input
                          type="text"
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="field-7"
                          data-name="Field 7"
                          placeholder="Example Text"
                          id="field-7"
                          required=""
                        />
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_7f47e100-59bc-275d-8f0a-09197042a363-a480b94b"
                  className="input-container"
                >
                  <div className="label-container">
                    <div className="label-subcontainer">
                      <div className="sub-label-form">
                        <span className="grey">(OPTIONAL)</span> Debossing / Foil Stamping text |
                        <span className="green"> bottom line</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <input
                          type="text"
                          className="text-field-2 w-input"
                          maxLength="256"
                          name="field-7"
                          data-name="Field 7"
                          placeholder="Example Text"
                          id="field-7"
                          required=""
                        />
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-matting" className="wrapper-dynamic-matting">
              <div className="w-layout-grid grid-dynamic">
                <div className="input-container">
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">format</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="4 Ply">4 Ply</option>
                          <option value="8 Ply">8 Ply</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33aa8-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Mat size</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="11x14 ($14.00)">11x14 ($14.00)</option>
                          <option value="11x13 ($14.00)">11x13 ($14.00)</option>
                          <option value="12x15 ($20.00)">12x15 ($20.00)</option>
                          <option value="13x13 ($20.00)">13x13 ($20.00)</option>
                          <option value="14x14 ($20.00)">14x14 ($20.00)</option>
                          <option value="11x15 ($20.00)">11x15 ($20.00)</option>
                          <option value="14x17 ($25.00)">14x17 ($25.00)</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-mounting" className="wrapper-dynamic-mounting">
              <div className="w-layout-grid grid-dynamic">
                <div className="input-container">
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Mount size</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="7x10 mount ($9.00)">7x10 mount ($9.00)</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33ace-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">Mount color</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Black $(0.00)">Black $(0.00)</option>
                          <option value="White $(0.00)">White $(0.00)</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-standard" className="standard-frame">
              <div className="w-layout-grid grid-dynamic _1fr">
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33ae2-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Adelaide">Adelaide</option>
                          <option value="Albany">Albany</option>
                          <option value="Athens">Athens</option>
                          <option value="Aurora">Aurora</option>
                          <option value="Bristol">Bristol</option>
                          <option value="Charleston">Charleston</option>
                          <option value="Charlotte">Charlotte</option>
                          <option value="Chelsea">Chelsea</option>
                          <option value="Columbia">Columbia</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33af4-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame Mat</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="No Mat $(125.00)">No Mat $(125.00)</option>
                          <option value='2" Mat $(125.00)'>2&quot; Mat $(125.00)</option>
                          <option value='4" mat $(125.00)'>4&quot; mat $(125.00)</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33b06-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">acrylic</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="show-premium" className="premium-frame">
              <div className="w-layout-grid grid-dynamic _1fr">
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33b1a-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame wood</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="frame-wood"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="choose-one">Choose One</option>
                          <option value="maple">Maple 3/4&quot; PROFILE</option>
                          <option value='Maple 1.5" PROFILE'>Maple 1.5&quot; PROFILE</option>
                          <option value='Ash 3/4" PROFILE'>Ash 3/4&quot; PROFILE</option>
                          <option value='Ash 1.5" PROFILE'>Ash 1.5&quot; PROFILE</option>
                          <option value='Cherry 3/4" PROFILE'>Cherry 3/4&quot; PROFILE</option>
                          <option value='Cherry 1.5" PROFILE'>Cherry 1.5&quot; PROFILE</option>
                          <option value='Walnut 3/4" PROFILE'>Walnut 3/4&quot; PROFILE</option>
                          <option value='Walnut 1.5" PROFILE'>Walnut 1.5&quot; PROFILE</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33b2c-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame Mat</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="No Mat $(125.00)">No Mat $(125.00)</option>
                          <option value='2" Mat $(125.00)'>2&quot; Mat $(125.00)</option>
                          <option value='4" mat $(125.00)'>4&quot; mat $(125.00)</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33b3e-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">acrylic</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Standard Acrylic + $(0.00)">
                            Standard Acrylic + $(0.00)
                          </option>
                          <option value="Museum Acrylic + $(75.00)">
                            Museum Acrylic + $(75.00)
                          </option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33b50-a480b94b"
                  className="input-container paper-type"
                >
                  <div className="label-container">
                    <div className="div-block-150">
                      <div className="sub-label-form">frame stain</div>
                    </div>
                  </div>
                  <div className="form-block-input w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="form-input select-field"
                    >
                      <div className="select-field-wrapper">
                        <select
                          id="field-3"
                          name="field-3"
                          data-name="Field 3"
                          className="form-select-field w-select"
                        >
                          <option value="Rising White">Rising White</option>
                          <option value="Whitewash">Whitewash</option>
                          <option value="Satin Black">Satin Black</option>
                          <option value="Dark Grey">Dark Grey</option>
                        </select>

                        <div className="div-block-63">
                          <img
                            src="images/chevron-down-12.svg"
                            loading="lazy"
                            alt=""
                            className="img-icon"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="line-horizontal" />
            <div data-ix="fade-up-1" className="accordion js-accordion">
              <div className="accordion__item js-accordion-item">
                <div className="accordion-header js-accordion-header">
                  <div className="accordion-item-2">
                    <div className="icon small x2 right-margin w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M128,23.99805a104,104,0,1,0,104,104A104.12041,104.12041,0,0,0,128,23.99805Zm40,112H136v32a8,8,0,1,1-16,0v-32H88a8,8,0,0,1,0-16h32v-32a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
                      </svg>
                    </div>
                    <div>Add Notes</div>
                  </div>
                </div>
                <div data-ix="hide-on-load" className="accordion-body js-accordion-body">
                  <div className="accordion-item-content">
                    <div className="input-container add-notes">
                      <div className="label-container">
                        <div className="div-block-150">
                          <div className="sub-label-form">Editing notes for:</div>
                          <div data-hover="false" data-delay="0" className="w-dropdown">
                            <div className="dropdown-toggle-3 w-dropdown-toggle">
                              <div className="sub-label-form all-photos">current photo:</div>
                              <div className="icon-3 w-icon-dropdown-toggle" />
                            </div>
                            <nav className="dropdown-list-3 w-dropdown-list">
                              <div className="sub-label-form unselected">all photos</div>
                            </nav>
                          </div>
                          <div className="icon mini margin-left opacity70 w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="form-block-input w-form">
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                          className="input-wrapper"
                        >
                          <textarea
                            placeholder="Example Text"
                            maxLength="5000"
                            id="field-6"
                            name="field-6"
                            data-name="field"
                            className="text-field-2 area w-input"
                          />
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-controls-product-selection-sidebar">
          <div className="controls-block-grid">
            <div id="w-node-ef53a1b5-32fb-b097-4006-9c6a244116ab-5b83c88b">Subtotal:</div>
            <div className="text-block-32">$ 142.00</div>
          </div>
          <a href="/product-selection" className="container-cart-button w-inline-block">
            <div className="div-block-186">
              <div className="icon right-margin w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                  <rect width="256" height="256" fill="none" />
                  <path d="M223.87109,65.43115l-12.15722,66.86231A23.98711,23.98711,0,0,1,188.10156,152H72.13135l4.36377,24H184a24.01161,24.01161,0,1,1-22.624,16H102.624a24.00309,24.00309,0,1,1-40.85986-7.57666L34.05078,32H16a8,8,0,0,1,0-16H34.05078A15.9918,15.9918,0,0,1,49.793,29.13818L54.67676,56H216a8.00076,8.00076,0,0,1,7.87109,9.43115Z" />
                </svg>
              </div>
              <div>Add To Cart</div>
            </div>
            <div className="div-block-187">
              <div className="div-block-188">
                <div>3</div>
              </div>
            </div>
          </a>
          <a href="/product-selection" className="button-2 w-inline-block">
            <div className="icon right-margin w-embed">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                <rect width="256" height="256" fill="none" />
                <path d="M247.31055,124.75061c-.35157-.79-8.81934-19.57617-27.65332-38.41113C194.57324,61.25256,162.87793,47.99182,128,47.99182S61.42676,61.25256,36.34277,86.33948c-18.834,18.835-27.30175,37.62109-27.65332,38.41113a8.00282,8.00282,0,0,0,0,6.49805c.35157.791,8.82032,19.57226,27.6543,38.40429C61.42773,194.734,93.12207,207.99182,128,207.99182S194.57227,194.734,219.65625,169.653c18.834-18.832,27.30273-37.61328,27.6543-38.40429A8.00282,8.00282,0,0,0,247.31055,124.75061ZM128,92.00061a36,36,0,1,1-36,36A36.04061,36.04061,0,0,1,128,92.00061Z" />
              </svg>
            </div>
            <div>Review Order</div>
          </a>
        </div>
      </div>
      <div id="show-albums" className="product-selection-picture-wrapper">
        <div id="show-album" className="photo-album-container grid show">
          <div className="div-block-324">
            <div className="div-block-325">
              <div>
                <div className="sub-heading-container-2 both-edges no-margin">
                  <div className="icon right-margin w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M128,124V92a20,20,0,0,0-40,0v56"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <path
                        d="M168,124V92a20,20,0,0,0-40,0v32"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <path
                        d="M88,148V116H68a20,20,0,0,0-20,20v16a80,80,0,0,0,160,0V108a20,20,0,0,0-40,0v16"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <h2 className="heading-title-2-2-copy">Drag to reorder your album</h2>
                </div>
              </div>
              <div className="div-block-326">
                <div className="w-layout-grid grid-23 main _2 _100 grey">
                  <div className="green">
                    <div className="icon small x2 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M236.74414,187.96585l-87.96875-152a24.012,24.012,0,0,0-41.54687,0v.00782L19.25977,187.96585a23.9982,23.9982,0,0,0,20.76562,36.02344H215.97852a23.9982,23.9982,0,0,0,20.76562-36.02344Zm-116.75-83.96875a8,8,0,1,1,16,0v40a8,8,0,1,1-16,0ZM128,192a12,12,0,1,1,12-12A12.00059,12.00059,0,0,1,128,192Z" />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <span>08</span>
                    <span className="green">/40</span> selected
                  </div>
                </div>
                <a
                  href="/product-selection-album"
                  id="proceed-slideshow"
                  data-w-id="95f9ce64-2aff-654a-b841-4969aaa33c57"
                  className="button-2 slideshow-pop w-inline-block"
                >
                  <div className="icon small right-margin w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path d="M253.20605,128a15.9352,15.9352,0,0,1-7.34765,13.459l-89.20606,57.34668A16.00057,16.00057,0,0,1,132,185.34668V142.65381L44.65234,198.80566A16.00057,16.00057,0,0,1,20,185.34668V70.65332a16.00057,16.00057,0,0,1,24.65234-13.459L132,113.34619V70.65332a16.00057,16.00057,0,0,1,24.65234-13.459L245.8584,114.541A15.9352,15.9352,0,0,1,253.20605,128Z" />
                    </svg>
                  </div>
                  <div>Proceed to Slideshow</div>
                </a>
              </div>
            </div>
            <div className="div-block-327">
              <div id="left" className="dragula-container">
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="2555090a-eacf-4936-6c18-e3300be8ef04"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="191362e8-f5af-af5d-d10c-95239c8c52e6"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="49797f24-33c2-e580-d9f0-a12b0b0c9939"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="689d9c9b-e56d-2c47-478b-32dc91c4a69b"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="0f210ed2-aef5-136a-12df-bb8f78d984f1"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="2021a071-3ad1-d44b-648e-2b0edb811070"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="885275d6-0176-34b5-5358-6c61fe053b46"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="f8abd0c9-7f8b-5c63-4e92-35fffe33510a"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="cbcca442-90fc-50be-d159-67d7095ee4c8"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="b27fd573-886f-68d7-d9f8-8c9bee497db8"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="8fe8bf02-223e-ee6c-8d67-9f1cb85e946c"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="282284a0-8529-7372-945c-71cb1f58e964"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="bb40a069-01ce-bddb-e71d-bcea06d89e28"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="46c6a00a-ae5d-8800-2870-27327e1ee221"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="372f3557-f738-cb0f-6f4c-7a2b96cfe80e"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="b19271c8-bdea-62e0-8d33-1b1decfecc51"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="8f50c67a-5dea-0050-7ffa-4d9bcfca7761"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="f258b512-db5c-07ac-4bd8-853c43d0b7cf"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="87b3dae1-9a33-28ab-18c0-8ddffa39cc46"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="7214cad8-03f4-c3c4-5664-6dae6779070a"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="c92f2dcf-e294-4004-c994-435307aa097c"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="4c06ede4-f3e1-368d-4c6c-a8a92788a2a9"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="142ef591-183e-591f-3391-f853ea4de569"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="d3766386-2605-eefc-dfcb-1b120da2d1fe"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="b1da713d-f61f-ed6f-dc2f-92377bb2dc63"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="4c74a14b-c0ec-4cce-ca43-4260c3726536"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="279f0024-d4d7-9580-2411-c6a317e359fe"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="27692bf8-de6a-8356-80f1-2e9b8f38020b"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="13b87f4b-5012-8c72-168e-06ab312c8444"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="a7843e0e-c6f1-54bd-8cd7-d787c5ed05dc"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="d4305ef3-71d8-5e8c-88bf-6d27a1268706"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="a7feb082-8f16-2e18-75de-24dacd9d6de1"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="c8ef248d-e6c6-6eac-3043-239e569ac36a"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="508a927a-af61-a0be-1509-57516cdb1797"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="f9b836a4-de89-d311-f03a-2476d49e7d8b"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="eb463c66-0943-2176-1c81-cd5100595fd7"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="723159bf-0955-f4ed-4a3b-d71bc3a3ddc0"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="53af500a-96e0-a34c-f008-633b99cbeb91"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="20ec9087-6cee-c880-7612-5658779af9bf"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="4ad230a3-c377-90d0-7c4a-a1ada4f50843"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-card__one">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-5-768x610.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 92px, (max-width: 1919px) 90px, 120px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-5-768x610-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-5-768x610.jpg 768w"
                    alt=""
                    className="img-dragable"
                  />
                  <div className="ghost-div-container">
                    <div className="w-layout-grid top-buttons-image-element">
                      <div
                        data-w-id="b06ff1d3-05ae-f1bc-28ce-963f6c66b478"
                        className="delete-symbol"
                      >
                        <div className="icon small x2 w-embed">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentcolor"
                            viewBox="0 0 256 256"
                          >
                            <rect width="256" height="256" fill="none"></rect>
                            <path d="M224,56a8.00008,8.00008,0,0,1-8,8h-8V208a16.01833,16.01833,0,0,1-16,16H64a16.01833,16.01833,0,0,1-16-16V64H40a8,8,0,0,1,0-16H216A8.00008,8.00008,0,0,1,224,56ZM88.00391,32h80a8,8,0,0,0,0-16h-80a8,8,0,0,0,0,16Z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        data-hover="false"
                        data-delay="0"
                        id="paper-type-show-options"
                        className="dropdown w-dropdown"
                      >
                        <div className="select-paper-options w-dropdown-toggle">
                          <div className="icon small w-embed">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentcolor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle cx="128" cy="64" r="16"></circle>
                              <circle cx="128" cy="128" r="16"></circle>
                              <circle cx="128" cy="192" r="16"></circle>
                            </svg>
                          </div>
                        </div>
                        <nav className="dropdown-list album-options w-dropdown-list">
                          <div className="input-container paper-type">
                            <div className="label-container">
                              <div className="div-block-150">
                                <div className="sub-label-form">Select Paper Type</div>
                              </div>
                            </div>
                            <div className="form-block-input w-form">
                              <form
                                id="email-form"
                                name="email-form"
                                data-name="Email Form"
                                method="get"
                                className="form-input select-field"
                              >
                                <div className="select-field-wrapper">
                                  <select
                                    data-name="Field 3"
                                    name="field-3"
                                    id="field"
                                    className="form-select-field w-select"
                                  >
                                    <option value="Choose One">Choose One</option>
                                    <option value="Museum Etching">Museum Etching</option>
                                    <option value="Canson Rag">Canson Rag</option>
                                    <option value="Platine">Platine</option>
                                    <option value="Ultra Smooth">Ultra Smooth</option>
                                  </select>
                                  <div className="div-block-63">
                                    <img
                                      src="images/chevron-down-12.svg"
                                      loading="lazy"
                                      alt=""
                                      className="img-icon"
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                              </div>
                              <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                              </div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="stroke" className="wrapper-dynamic-stroke">
                <div className="w-layout-grid dragula-container-copy">
                  <div
                    data-tippy-content="Grouped Pictures (2)"
                    id="w-node-abb48915-8921-1799-14b7-2817332fdec4-a480b94b"
                    className="stroke first-group"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-controls-product-selection no-margin white">
            <div className="bottom-controls-product-selection-grid padding-top-5px">
              <div className="picture-element-product-selection-2 selected">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/71vtBVOn2L.jpg"
                    loading="lazy"
                    sizes="90px"
                    srcSet="images/71vtBVOn2L-p-500.jpeg 500w, images/71vtBVOn2L-p-1080.jpeg 1080w, images/71vtBVOn2L.jpg 1200w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca1-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca5-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                >
                  <div className="cart-symbol-2">
                    <div className="icon small x2 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M96,216a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,96,216Zm88-16a16,16,0,1,0,16,16A16.00016,16.00016,0,0,0,184,200ZM228.10059,67.18262A7.99866,7.99866,0,0,0,221.71387,64H48.32031L40.207,35.604A16.06984,16.06984,0,0,0,24.82227,24H8A8,8,0,0,0,8,40H24.82227l9.73046,34.05469c.02637.09863.05372.19677.084.29443l26.35547,92.24365A24.10087,24.10087,0,0,0,84.06836,184h95.86328a24.101,24.101,0,0,0,23.07617-17.40674l26.39844-92.39551A8.00023,8.00023,0,0,0,228.10059,67.18262Z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="picture-element-product-selection-2">
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca9-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                >
                  <div className="cart-symbol-2">
                    <div className="icon small x2 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M96,216a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,96,216Zm88-16a16,16,0,1,0,16,16A16.00016,16.00016,0,0,0,184,200ZM228.10059,67.18262A7.99866,7.99866,0,0,0,221.71387,64H48.32031L40.207,35.604A16.06984,16.06984,0,0,0,24.82227,24H8A8,8,0,0,0,8,40H24.82227l9.73046,34.05469c.02637.09863.05372.19677.084.29443l26.35547,92.24365A24.10087,24.10087,0,0,0,84.06836,184h95.86328a24.101,24.101,0,0,0,23.07617-17.40674l26.39844-92.39551A8.00023,8.00023,0,0,0,228.10059,67.18262Z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/89e889e6df64e44fe71ccd3de433b378.jpeg"
                    loading="lazy"
                    sizes="90px"
                    srcSet="images/89e889e6df64e44fe71ccd3de433b378-p-500.jpeg 500w, images/89e889e6df64e44fe71ccd3de433b378-p-800.jpeg 800w, images/89e889e6df64e44fe71ccd3de433b378-p-1080.jpeg 1080w, images/89e889e6df64e44fe71ccd3de433b378.jpeg 1470w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cae-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg"
                    loading="lazy"
                    sizes="90px"
                    srcSet="images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-800.jpeg 800w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-1080.jpeg 1080w, images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg 1514w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cb5-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cb9-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/00-Stephen-Shore.jpg"
                    loading="lazy"
                    sizes="90px"
                    srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cbd-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cc1-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cc5-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cc9-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33ccd-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cd1-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cd5-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cd9-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cdd-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33ce1-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33ce5-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33ce9-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33ced-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cf1-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cf5-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cf9-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33cfd-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33d01-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33d05-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33d09-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33d0d-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33d11-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33d15-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33d19-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
              <div className="picture-element-product-selection-2">
                <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                  <img
                    src="images/stephen_shore_27.jpg"
                    loading="lazy"
                    alt=""
                    className="image-thumbnail"
                  />{' '}
                </a>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33d1d-a480b94b"
                  className="info-container-mini"
                >
                  <div className="icon smaller right-margin supermini w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                  <div>file-name.jpg</div>
                </div>
                <div
                  id="w-node-_95f9ce64-2aff-654a-b841-4969aaa33d21-a480b94b"
                  className="w-layout-grid top-buttons-image-element"
                />
              </div>
            </div>
            <div className="side-controls-product-selection white-gradient">
              <div className="w-layout-grid grid-44">
                <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                  <div className="div-block-169-copy-2 w-dropdown-toggle">
                    <div className="icon small w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline
                          points="144 167.994 184 207.994 223.999 168"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="184"
                          y1="111.99347"
                          x2="184"
                          y2="207.99347"
                          fill="none"
                          stroke="#currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="48"
                          y1="127.99353"
                          x2="119.99902"
                          y2="127.99353"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="48"
                          y1="63.99353"
                          x2="183.99902"
                          y2="63.99353"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <line
                          x1="48"
                          y1="191.99353"
                          x2="104"
                          y2="191.99353"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                  </div>
                  <nav className="dropdown-list production w-dropdown-list">
                    <div className="form-block-filter w-form">
                      <form
                        id="email-form-3"
                        name="email-form-3"
                        data-name="Email Form 3"
                        method="get"
                        className="form-3"
                      >
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input" />
                          <input
                            type="radio"
                            data-name="Radio 4"
                            id="radio-4"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-4">
                            Ascending (A-Z)
                          </span>
                        </label>
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input" />
                          <input
                            type="radio"
                            data-name="Radio 4"
                            id="radio-4"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-4">
                            Descending (Z-A)
                          </span>
                        </label>
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input" />
                          <input
                            type="radio"
                            data-name="Radio 5"
                            id="radio-5"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-5">
                            Newest to Oldest
                          </span>
                        </label>
                        <label className="radio-button-field w-radio">
                          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input" />
                          <input
                            type="radio"
                            data-name="Radio 6"
                            id="radio-6"
                            name="radio"
                            value="Radio"
                            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                          />
                          <span className="w-form-label" htmlFor="radio-6">
                            Oldest to Newest
                          </span>
                        </label>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="rotate-button-2">
                  <div className="icon small w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <path
                        d="M43.99951,143.27273V52a8,8,0,0,1,8-8h152a8,8,0,0,1,8,8V204a8,8,0,0,1-8,8h-68.3641"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                      <polyline
                        points="128 152 64 216 32 184"
                        fill="none"
                        stroke="currentcolor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="24"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="slideshow-section" className="print-section-only-wrapper slideshow-show">
          <div className="product-selection-picture-container-flex">
            <div className="product-selection-main-picture-container-copy prints">
              <div className="div-block-334 griddy">
                <div className="controls-block-grid no-margin">
                  <a
                    href="/product-selection-album"
                    id="back-sequence"
                    data-w-id="01f939b8-e576-c51b-18ca-532e814c4b86"
                    className="button-2-2 secondary w-inline-block"
                  >
                    <div className="icon right-margin w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M10.1416,114.541,99.34766,57.19434A16.00057,16.00057,0,0,1,124,70.65332v42.69287l87.34766-56.15185A16.00057,16.00057,0,0,1,236,70.65332V185.34668a15.99914,15.99914,0,0,1-24.65234,13.459L124,142.65381v42.69287a15.99914,15.99914,0,0,1-24.65234,13.459L10.1416,141.459a16.0005,16.0005,0,0,1,0-26.918Z" />
                      </svg>
                    </div>
                    <div>Back to Sequence</div>
                  </a>
                </div>
                <div
                  id="w-node-_6a1f80a1-2a98-e912-ef0a-10398c2bf8d5-a480b94b"
                  className="w-layout-grid grid-23 main _2 _100 grey centered"
                >
                  <div className="green-2">
                    <div className="icon small x2 w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M236.74414,187.96585l-87.96875-152a24.012,24.012,0,0,0-41.54687,0v.00782L19.25977,187.96585a23.9982,23.9982,0,0,0,20.76562,36.02344H215.97852a23.9982,23.9982,0,0,0,20.76562-36.02344Zm-116.75-83.96875a8,8,0,1,1,16,0v40a8,8,0,1,1-16,0ZM128,192a12,12,0,1,1,12-12A12.00059,12.00059,0,0,1,128,192Z" />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <span className="green-2">06/06</span> pictures done
                  </div>
                </div>
                <div
                  id="w-node-cce36bd5-f70d-4d7e-d718-3b8bbbacd453-a480b94b"
                  className="controls-block-grid no-margin"
                >
                  <div data-tippy-content="Reset All" className="div-block-169-copy-3 ghost tippy">
                    <div className="icon small w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M216.001,207.833H130.34375l34.72949-34.72949.0166-.01465.01465-.0166,56.55371-56.55274a24.02962,24.02962,0,0,0,0-33.94141L176.40332,37.32324a24.0007,24.0007,0,0,0-33.94141,0L85.90283,93.88232l-.01025.00928-.00928.01026L29.32422,150.46094a24.00066,24.00066,0,0,0,0,33.9414l37.08887,37.08789a8.00232,8.00232,0,0,0,5.65722,2.34278H216.001a8,8,0,0,0,0-16ZM153.77637,48.6377a7.99708,7.99708,0,0,1,11.3125,0l45.25488,45.25488a8.00888,8.00888,0,0,1,0,11.31347l-50.91113,50.91114L102.86475,99.54932Z" />
                      </svg>
                    </div>
                  </div>
                  <a href="/product-selection-album" className="button-2-2 w-inline-block">
                    <div className="icon right-margin w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path d="M128,23.99805a104,104,0,1,0,104,104A104.12041,104.12041,0,0,0,128,23.99805Zm40,112H136v32a8,8,0,1,1-16,0v-32H88a8,8,0,0,1,0-16h32v-32a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
                      </svg>
                    </div>
                    <div>Save Changes</div>
                  </a>
                </div>
              </div>
              <div className="slider-wrap prints">
                <div
                  data-delay="4000"
                  data-animation="slide"
                  className="slider-2 w-slider"
                  data-autoplay="false"
                  data-easing="ease-out-quint"
                  data-hide-arrows="false"
                  data-disable-swipe="false"
                  data-w-id="01f939b8-e576-c51b-18ca-532e814c4b8d"
                  data-autoplay-limit="0"
                  data-nav-spacing="6"
                  data-duration="500"
                  data-infinite="true"
                >
                  <div className="slider-mask w-slider-mask">
                    <div className="slide-2 slide-one w-slide">
                      <div className="div-block-345">
                        <img
                          src="images/71vtBVOn2L.jpg"
                          loading="lazy"
                          sizes="100vw"
                          srcSet="images/71vtBVOn2L-p-500.jpeg 500w, images/71vtBVOn2L-p-1080.jpeg 1080w, images/71vtBVOn2L.jpg 1200w"
                          alt=""
                          className="image-91"
                        />
                      </div>
                    </div>
                    <div className="slide-2 slide-one w-slide">
                      <div className="div-block-345">
                        <img
                          src="images/89e889e6df64e44fe71ccd3de433b378.jpeg"
                          loading="lazy"
                          sizes="100vw"
                          srcSet="images/89e889e6df64e44fe71ccd3de433b378-p-500.jpeg 500w, images/89e889e6df64e44fe71ccd3de433b378-p-800.jpeg 800w, images/89e889e6df64e44fe71ccd3de433b378-p-1080.jpeg 1080w, images/89e889e6df64e44fe71ccd3de433b378.jpeg 1470w"
                          alt=""
                          className="image-91"
                        />
                      </div>
                    </div>
                    <div className="slide-2 slide-one w-slide">
                      <div className="div-block-345">
                        <img
                          src="images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg"
                          loading="lazy"
                          sizes="100vw"
                          srcSet="images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-800.jpeg 800w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-1080.jpeg 1080w, images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg 1514w"
                          alt=""
                          className="image-91"
                        />
                      </div>
                    </div>
                    <div className="slide-2 slide-one w-slide">
                      <div className="div-block-345">
                        <img
                          src="images/00-Stephen-Shore.jpg"
                          loading="lazy"
                          sizes="100vw"
                          srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                          alt=""
                          className="image-91"
                        />
                      </div>
                    </div>
                    <div className="slide-2 slide-one w-slide">
                      <div className="div-block-345">
                        <img
                          src="images/stephen_shore_27.jpg"
                          loading="lazy"
                          alt=""
                          className="image-91"
                        />
                      </div>
                    </div>
                    <div className="slide-2 slide-one w-slide">
                      <div className="div-block-345">
                        <img
                          src="images/stephen_shore_27.jpg"
                          loading="lazy"
                          alt=""
                          className="image-91"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-id="01f939b8-e576-c51b-18ca-532e814c4bb2"
                    className="slider-arrow left w-slider-arrow-left"
                  >
                    <div className="slider-arrow-icon w-icon-slider-left" />
                  </div>
                  <div
                    data-w-id="01f939b8-e576-c51b-18ca-532e814c4bb4"
                    className="slider-arrow right w-slider-arrow-right"
                  >
                    <div className="slider-arrow-icon w-icon-slider-right" />
                  </div>
                  <div className="slide-nav-4 w-slider-nav w-round w-num" />
                </div>
                <div className="w-layout-grid grid-89">
                  <div
                    data-tippy-content="Rotate Image"
                    className="rotate-button-2 margin-top tippy"
                  >
                    <img
                      src="images/rotating-instagram-tool-symbol.svg"
                      loading="lazy"
                      alt=""
                      className="icon"
                    />
                  </div>
                  <div
                    id="w-node-d5550fcb-e1e3-3b3a-4c71-8673257e3c87-a480b94b"
                    className="input-container paper-type"
                  >
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            data-name="Field 3"
                            name="field-3"
                            id="field"
                            data-w-id="d5550fcb-e1e3-3b3a-4c71-8673257e3c90"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose Paper Type</option>
                            <option value="Museum Etching">Museum Etching</option>
                            <option value="Canson Rag">Canson Rag</option>
                            <option value="Ultra Smooth">Ultra Smooth</option>
                            <option value="Platine">Platine</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_28e36939-17af-ed7e-a3a4-5ee8dada5876-a480b94b"
                    className="input-container paper-type"
                  >
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            data-name="Field 3"
                            name="field-3"
                            id="field"
                            data-w-id="28e36939-17af-ed7e-a3a4-5ee8dada587f"
                            className="form-select-field w-select"
                          >
                            <option value="Choose One">Choose Print Size</option>
                            <option value="4x6">4x6</option>
                            <option value="5x5">5x5</option>
                            <option value="5x7">5x7</option>
                            <option value="7x7">7x7</option>
                          </select>
                          <div className="div-block-63">
                            <img
                              src="images/chevron-down-12.svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-controls-product-selection">
              <div className="bottom-controls-product-selection-grid">
                <div
                  data-tippy-content="Currently Selected"
                  className="picture-element-product-selection-2 drag-drop tippy"
                >
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/71vtBVOn2L.jpg"
                      loading="lazy"
                      sizes="100vw"
                      srcSet="images/71vtBVOn2L-p-500.jpeg 500w, images/71vtBVOn2L-p-1080.jpeg 1080w, images/71vtBVOn2L.jpg 1200w"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4bc3-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4bc7-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  >
                    <div className="check-symbol-2">
                      <div className="icon small x2 w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentcolor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <polyline
                            points="216 72.005 104 184 48 128.005"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-tippy-content="Currently Selected"
                  className="picture-element-product-selection-2 selected drag-drop"
                >
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/89e889e6df64e44fe71ccd3de433b378.jpeg"
                      loading="lazy"
                      sizes="100vw"
                      srcSet="images/89e889e6df64e44fe71ccd3de433b378-p-500.jpeg 500w, images/89e889e6df64e44fe71ccd3de433b378-p-800.jpeg 800w, images/89e889e6df64e44fe71ccd3de433b378-p-1080.jpeg 1080w, images/89e889e6df64e44fe71ccd3de433b378.jpeg 1470w"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4bcd-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4bd1-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  >
                    <div className="check-symbol-2">
                      <div className="icon small x2 w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentcolor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <polyline
                            points="216 72.005 104 184 48 128.005"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-tippy-content="Currently Selected"
                  className="picture-element-product-selection-2 drag-drop"
                >
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg"
                      loading="lazy"
                      sizes="100vw"
                      srcSet="images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-500.jpeg 500w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-800.jpeg 800w, images/Stephen-Shore-Uncommon-Places-16-1514x1200-p-1080.jpeg 1080w, images/Stephen-Shore-Uncommon-Places-16-1514x1200.jpg 1514w"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4bd7-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4bdb-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  >
                    <div className="check-symbol-2">
                      <div className="icon small x2 w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentcolor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <polyline
                            points="216 72.005 104 184 48 128.005"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-tippy-content="Currently Selected"
                  className="picture-element-product-selection-2 drag-drop"
                >
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/00-Stephen-Shore.jpg"
                      loading="lazy"
                      sizes="100vw"
                      srcSet="images/00-Stephen-Shore-p-500.jpeg 500w, images/00-Stephen-Shore.jpg 1024w"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4be1-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4be5-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  >
                    <div className="check-symbol-2">
                      <div className="icon small x2 w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentcolor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <polyline
                            points="216 72.005 104 184 48 128.005"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-tippy-content="Currently Selected"
                  className="picture-element-product-selection-2 drag-drop"
                >
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/stephen_shore_27.jpg"
                      loading="lazy"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4beb-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4bef-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  >
                    <div className="check-symbol-2">
                      <div className="icon small x2 w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentcolor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <polyline
                            points="216 72.005 104 184 48 128.005"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-tippy-content="Currently Selected"
                  className="picture-element-product-selection-2 drag-drop"
                >
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/stephen_shore_27.jpg"
                      loading="lazy"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4bf5-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4bf9-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  >
                    <div className="check-symbol-2">
                      <div className="icon small x2 w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentcolor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <polyline
                            points="216 72.005 104 184 48 128.005"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="picture-element-product-selection-2 drag-drop">
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/stephen_shore_27.jpg"
                      loading="lazy"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4bff-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c03-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  />
                </div>
                <div className="picture-element-product-selection-2 drag-drop">
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/stephen_shore_27.jpg"
                      loading="lazy"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c07-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c0b-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  />
                </div>
                <div className="picture-element-product-selection-2 drag-drop">
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/stephen_shore_27.jpg"
                      loading="lazy"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c0f-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c13-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  />
                </div>
                <div className="picture-element-product-selection-2 drag-drop">
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/stephen_shore_27.jpg"
                      loading="lazy"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c17-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c1b-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  />
                </div>
                <div className="picture-element-product-selection-2 drag-drop">
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/stephen_shore_27.jpg"
                      loading="lazy"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c1f-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c23-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  />
                </div>
                <div className="picture-element-product-selection-2 drag-drop">
                  <a href="/product-selection-album" className="lightbox-link w-inline-block w-lightbox">
                    <img
                      src="images/stephen_shore_27.jpg"
                      loading="lazy"
                      alt=""
                      className="image-thumbnail"
                    />{' '}
                  </a>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c27-a480b94b"
                    className="info-container-mini"
                  >
                    <div className="icon smaller right-margin supermini w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M95.99414,175.99512,191.799,83.799a28,28,0,0,0-39.598-39.598L54.05887,142.05887a48,48,0,0,0,67.88226,67.88226l82.053-81.946"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                    <div>file-name.jpg</div>
                  </div>
                  <div
                    id="w-node-_01f939b8-e576-c51b-18ca-532e814c4c2b-a480b94b"
                    className="w-layout-grid top-buttons-image-element"
                  />
                </div>
              </div>
              <div className="side-controls-product-selection">
                <div className="w-layout-grid grid-44">
                  <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                    <div className="div-block-169-copy-2 w-dropdown-toggle">
                      <div className="icon small w-embed">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentcolor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <polyline
                            points="144 167.994 184 207.994 223.999 168"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                          <line
                            x1="184"
                            y1="111.99347"
                            x2="184"
                            y2="207.99347"
                            fill="none"
                            stroke="#currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                          <line
                            x1="48"
                            y1="127.99353"
                            x2="119.99902"
                            y2="127.99353"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                          <line
                            x1="48"
                            y1="63.99353"
                            x2="183.99902"
                            y2="63.99353"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                          <line
                            x1="48"
                            y1="191.99353"
                            x2="104"
                            y2="191.99353"
                            fill="none"
                            stroke="currentcolor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="24"
                          />
                        </svg>
                      </div>
                    </div>
                    <nav className="dropdown-list production w-dropdown-list">
                      <div className="form-block-filter w-form">
                        <form
                          id="email-form-3"
                          name="email-form-3"
                          data-name="Email Form 3"
                          method="get"
                          className="form-3"
                        >
                          <label className="radio-button-field w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input" />
                            <input
                              type="radio"
                              data-name="Radio 7"
                              id="radio-7"
                              name="radio"
                              value="Radio"
                              style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                            />
                            <span className="w-form-label" htmlFor="radio-7">
                              Ascending (A-Z)
                            </span>
                          </label>
                          <label className="radio-button-field w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input" />
                            <input
                              type="radio"
                              data-name="Radio 7"
                              id="radio-7"
                              name="radio"
                              value="Radio"
                              style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                            />
                            <span className="w-form-label" htmlFor="radio-7">
                              Descending (Z-A)
                            </span>
                          </label>
                          <label className="radio-button-field w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input" />
                            <input
                              type="radio"
                              data-name="Radio 7"
                              id="radio-7"
                              name="radio"
                              value="Radio"
                              style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                            />
                            <span className="w-form-label" htmlFor="radio-7">
                              Newest to Oldest
                            </span>
                          </label>
                          <label className="radio-button-field w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button-2 w-radio-input" />
                            <input
                              type="radio"
                              data-name="Radio 8"
                              id="radio-8"
                              name="radio"
                              value="Radio"
                              style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                            />
                            <span className="w-form-label" htmlFor="radio-8">
                              Oldest to Newest
                            </span>
                          </label>
                        </form>
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div className="rotate-button-2">
                    <div className="icon small w-embed">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentcolor"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <path
                          d="M43.99951,143.27273V52a8,8,0,0,1,8-8h152a8,8,0,0,1,8,8V204a8,8,0,0,1-8,8h-68.3641"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                        <polyline
                          points="128 152 64 216 32 184"
                          fill="none"
                          stroke="currentcolor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="24"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSelectionAlbum
