import axios from 'axios'
import { getAuthToken } from 'lib/utils'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    token: `${getAuthToken()}`,
  },
})

export default client
