import './styles.css';

const Design = () => (
  <div className="main-wrapper scroll">
    <div className="w-layout-grid navbar-wrapper">
      <div className="w-layout-grid top-grid">
        <div id="w-node-d0cb4847-2066-53d3-8308-c482596abaf8-c3bda992">
          <h3 className="heading-3">Create a Product Template</h3>
        </div>
        <nav id="w-node-d0cb4847-2066-53d3-8308-c482596abafb-c3bda992" className="breadcrumb">
          <a href="/" className="breadcrumb-link">
            Products
          </a>
          <div className="breadcrumb-divider"></div>
          <a href="/" className="breadcrumb-link">
            Home &amp;Living
          </a>
          <div className="breadcrumb-divider"></div>
          <a href="product.html" className="breadcrumb-link">
            Shopify
          </a>
        </nav>
        <div id="w-node-fc2ca85d-8024-a2bd-237a-a0dbbc8671d3-c3bda992" className="div-block-71">
          <div className="div-block-65">
            <div className="text-block-13">$15.95 - $18.00</div>
            <div>Excl. taxes and other charges</div>
          </div>
          <a href="/" className="_2nd-button main w-inline-block">
            <div className="text-block-31">Continue</div>
            <div className="icon-3 small left-margin w-embed">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" viewBox="0 0 256 256">
                <rect width="256" height="256" fill="none" />
                <line
                  x1="40"
                  y1="128"
                  x2="216"
                  y2="128"
                  fill="none"
                  stroke="currentcolor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                />
                <polyline
                  points="144 56 216 128 144 200"
                  fill="none"
                  stroke="currentcolor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="24"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div className="w-layout-grid grid-design">
      <div className="navigator-design">
        <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
          <div className="tabs-menu w-tab-menu" role="tablist">
            <a
              data-w-tab="Tab 1"
              className="tab w-inline-block w-tab-link w--current"
              id="w-tabs-0-data-w-tab-0"
              href="#w-tabs-0-data-w-pane-0"
              role="tab"
              aria-controls="w-tabs-0-data-w-pane-0"
              aria-selected="true"
            >
              <div>Product</div>
            </a>
            <a
              data-w-tab="Tab 2"
              className="tab w-inline-block w-tab-link"
              tabIndex="-1"
              id="w-tabs-0-data-w-tab-1"
              href="#w-tabs-0-data-w-pane-1"
              role="tab"
              aria-controls="w-tabs-0-data-w-pane-1"
              aria-selected="false"
            >
              <div>Design</div>
            </a>
          </div>
          <div className="tabs-content w-tab-content">
            <div
              data-w-tab="Tab 1"
              className="pane scroll w-tab-pane w--tab-active"
              id="w-tabs-0-data-w-pane-0"
              role="tabpanel"
              aria-labelledby="w-tabs-0-data-w-tab-0"
            >
              <div className="div-block-152">
                <div className="product-content-div">
                  <div className="product-name designer">
                    Personalized Enhanced Matte Paper Poster
                  </div>
                  <div className="dropdown-wrap dropdown-wrap__inline">
                    <div
                      data-delay="90"
                      data-hover="false"
                      data-w-id="bd7deded-1331-0d55-1087-7c4906c50a26"
                      className="dropdown-2 dropdown-inline w-dropdown"
                    >
                      <div
                        className="dropdown-toggle-3 dropdown-toggle__inline align-left w-dropdown-toggle"
                        id="w-dropdown-toggle-1"
                        aria-controls="w-dropdown-list-1"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        tabIndex="0"
                      >
                        <div className="rating-div margin-top">
                          <img
                            src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d959ebda9ba_mark-as-favorite-star%20(1).svg"
                            loading="lazy"
                            id="w-node-bd7deded-1331-0d55-1087-7c4906c50a29-c3bda992"
                            alt=""
                            className="rating-image"
                          />
                          <img
                            src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d959ebda9ba_mark-as-favorite-star%20(1).svg"
                            loading="lazy"
                            id="w-node-bd7deded-1331-0d55-1087-7c4906c50a2a-c3bda992"
                            alt=""
                            className="rating-image"
                          />
                          <img
                            src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d959ebda9ba_mark-as-favorite-star%20(1).svg"
                            loading="lazy"
                            id="w-node-bd7deded-1331-0d55-1087-7c4906c50a2b-c3bda992"
                            alt=""
                            className="rating-image"
                          />
                          <img
                            src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d94f2bda9bb_half-filled-rating-star%20(1).svg"
                            loading="lazy"
                            id="w-node-bd7deded-1331-0d55-1087-7c4906c50a2c-c3bda992"
                            alt=""
                            className="rating-image"
                          />
                          <img
                            src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d02e6bda9b6_bookmark-button%20(1).svg"
                            loading="lazy"
                            id="w-node-bd7deded-1331-0d55-1087-7c4906c50a2d-c3bda992"
                            alt=""
                            className="rating-image"
                          />
                        </div>
                        <div className="div-block-39">
                          <div
                            className="dropdown-toggle__icon dropdown-toggle__icon-inline"
                            style={{
                              transform:
                                'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                              transformStyle: 'preserve-3d',
                            }}
                          />
                          <div>200 Reviews</div>
                        </div>
                      </div>
                      <nav
                        style={{ height: 0, display: 'none' }}
                        className="dropdown-list-3 w-dropdown-list"
                        id="w-dropdown-list-1"
                        aria-labelledby="w-dropdown-toggle-1"
                      >
                        <div className="dropdown-item-2"></div>
                        <div className="dropdown-item-2"></div>
                        <div className="dropdown-item-2"></div>
                        <div className="div-block-42">
                          <div className="w-layout-grid grid-6">
                            <div>5 Stars</div>
                            <div
                              id="w-node-bd7deded-1331-0d55-1087-7c4906c50a3a-c3bda992"
                              className="div-block-43"
                            >
                              <div className="div-block-44 _83"></div>
                            </div>
                            <div id="w-node-bd7deded-1331-0d55-1087-7c4906c50a3c-c3bda992">83%</div>
                          </div>
                          <div className="w-layout-grid grid-6">
                            <div>4 Stars</div>
                            <div
                              id="w-node-bd7deded-1331-0d55-1087-7c4906c50a41-c3bda992"
                              className="div-block-43"
                            >
                              <div className="div-block-44 _11"></div>
                            </div>
                            <div id="w-node-bd7deded-1331-0d55-1087-7c4906c50a43-c3bda992">11%</div>
                          </div>
                          <div className="w-layout-grid grid-6">
                            <div>3 Stars</div>
                            <div
                              id="w-node-bd7deded-1331-0d55-1087-7c4906c50a48-c3bda992"
                              className="div-block-43"
                            >
                              <div className="div-block-44 _4"></div>
                            </div>
                            <div id="w-node-bd7deded-1331-0d55-1087-7c4906c50a4a-c3bda992">4%</div>
                          </div>
                          <div className="w-layout-grid grid-6">
                            <div className="text-block-11">2 Stars</div>
                            <div
                              id="w-node-bd7deded-1331-0d55-1087-7c4906c50a4f-c3bda992"
                              className="div-block-43"
                            >
                              <div className="div-block-44 _2"></div>
                            </div>
                            <div id="w-node-bd7deded-1331-0d55-1087-7c4906c50a51-c3bda992">2%</div>
                          </div>
                          <div className="w-layout-grid grid-6">
                            <div>1 Stars</div>
                            <div
                              id="w-node-bd7deded-1331-0d55-1087-7c4906c50a56-c3bda992"
                              className="div-block-43"
                            >
                              <div className="div-block-44 _1"></div>
                            </div>
                            <div id="w-node-bd7deded-1331-0d55-1087-7c4906c50a58-c3bda992">1%</div>
                          </div>
                        </div>
                        <a href="/" className="third-button small w-inline-block" tabIndex="0">
                          <div>See All Reviews</div>
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="div-block-50 padding-15 scroll">
                  <div className="input-container paper-type">
                    <div className="label-container">
                      <div className="div-block-150">
                        <div className="sub-label-form">choose paper</div>
                      </div>
                    </div>
                    <div className="form-block-input w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        className="form-input select-field"
                      >
                        <div className="select-field-wrapper">
                          <select
                            id="Choose-Paper-2"
                            name="Choose-Paper-2"
                            data-name="Choose Paper 2"
                            className="form-select-field w-select"
                          >
                            <option value="">Select one...</option>
                            <option value="Canson Rag">Canson Rag</option>
                            <option value="Canvas - Black Wrap">Canvas - Black Wrap</option>
                            <option value="Canvas - Gallery Wrap">Canvas - Gallery Wrap</option>
                            <option value="Canvas - Mirror Wrap">Canvas - Mirror Wrap</option>
                            <option value="Canvas - White Wrap">Canvas - White Wrap</option>
                            <option value="Deckled Paper - no border">
                              Deckled Paper - no border
                            </option>
                            <option value="Deckled Paper - with border">
                              Deckled Paper - with border
                            </option>
                            <option value="Deckled Paper - Circle with border">
                              Deckled Paper - Circle with border
                            </option>
                            <option value="Museum Etching">Museum Etching</option>
                            <option value="Photo Matte">Photo Matte</option>
                            <option value="Platine">Platine</option>
                            <option value="Satin">Satin</option>
                            <option value="Ultra Smooth">Ultra Smooth</option>
                            <option value="Lustre">Lustre</option>
                            <option value="Deckled Paper - Circle no border">
                              Deckled Paper - Circle no border
                            </option>
                          </select>
                          <div className="w-embed"></div>
                          <div className="div-block-151">
                            <img
                              src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/6123a5ff91920b76262462d2_chevron-down%20(12).svg"
                              loading="lazy"
                              alt=""
                              className="img-icon"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-block-50 padding-15">
                  <div className="sub-label-form">Select Sizes</div>
                  <div className="div-block-47"></div>
                  <div className="form-block w-form">
                    <form id="email-form" name="email-form" data-name="Email Form">
                      <label className="w-checkbox checkbox-wrap">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                        <input
                          type="checkbox"
                          id="checkbox-2"
                          name="checkbox-2"
                          data-name="Checkbox 2"
                          style={{
                            opacity: 0,
                            position: 'absolute',
                            zIndex: -1,
                          }}
                        />
                        <span className="checkbox-label w-form-label">Select All&nbsp;Sizes</span>
                      </label>
                      <div className="w-layout-grid grid-9">
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">12x12</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">12x16</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">16x16</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">16x20</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">18x24</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">24x36</span>
                        </label>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div className="div-block-50 padding-15">
                  <div className="sub-label-form">Select Finishing Services</div>
                  <div className="div-block-47"></div>
                  <div className="form-block w-form">
                    <form id="email-form" name="email-form" data-name="Email Form">
                      <div className="w-layout-grid grid-9 _2row">
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">4 ply mat</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">8 ply mat</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">Mount</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">Framing</span>
                        </label>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div className="div-block-50 padding-15">
                  <div className="sub-label-form">Select Finishing Details for Mounts</div>
                  <div className="div-block-47"></div>
                  <div className="form-block w-form">
                    <form id="email-form" name="email-form" data-name="Email Form">
                      <div className="w-layout-grid grid-9 _2line">
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">Black Mount</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">White Mount</span>
                        </label>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div className="div-block-50 padding-15">
                  <div className="sub-label-form">Select Finishing Details for Frames</div>
                  <div className="div-block-47"></div>
                  <div className="form-block w-form">
                    <form id="email-form" name="email-form" data-name="Email Form">
                      <div className="w-layout-grid grid-9 _2row">
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">13/4&quot; Profile</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">1.5&quot; Profile</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">No Mat</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">2&quot; Mat</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">4&quot; Mat</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">Standard Acrylic</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">Musuem Acrylic</span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">
                            Rising White Frame Stain
                          </span>
                        </label>
                        <label className="w-checkbox checkbox-wrap">
                          <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox square"></div>
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            name="checkbox-2"
                            data-name="Checkbox 2"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <span className="checkbox-label w-form-label">
                            Special Dark Frame Stain
                          </span>
                        </label>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div className="div-block-50 padding-15">
                  <div className="sub-label-form">Next Steps</div>
                  <div className="div-block-47"></div>
                  <div className="div-block-60 no-margin">
                    <div className="div-block-61">
                      <div className="div-block-62">
                        <img
                          src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d12f5bda9e8_upload%20(1).svg"
                          loading="lazy"
                          alt=""
                          className="image-81"
                        />
                        <div>Choose File</div>
                      </div>
                    </div>
                    <div className="div-block-61">
                      <div className="div-block-62">
                        <img
                          src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d3a75bda9e1_type.svg"
                          loading="lazy"
                          alt=""
                          className="image-81"
                        />
                        <div>Add Text</div>
                      </div>
                    </div>
                    <div className="div-block-61">
                      <div className="div-block-62">
                        <img
                          src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d2825bda9e3_smile.svg"
                          loading="lazy"
                          alt=""
                          className="image-81"
                        />
                        <div>Add Clipart</div>
                      </div>
                    </div>
                    <div className="div-block-61">
                      <div className="div-block-62">
                        <img
                          src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512da6babda9ec_image%20(5).svg"
                          loading="lazy"
                          alt=""
                          className="image-81"
                        />
                        <div>Premium Images</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-tab="Tab 2"
              className="pane design w-tab-pane"
              id="w-tabs-0-data-w-pane-1"
              role="tabpanel"
              aria-labelledby="w-tabs-0-data-w-tab-1"
            >
              <div>
                <div className="div-block-60">
                  <div className="div-block-61">
                    <div className="div-block-62">
                      <img
                        src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d12f5bda9e8_upload%20(1).svg"
                        loading="lazy"
                        alt=""
                        className="image-81"
                      />
                      <div>Choose File</div>
                    </div>
                  </div>
                  <div className="div-block-61">
                    <div className="div-block-62">
                      <img
                        src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d3a75bda9e1_type.svg"
                        loading="lazy"
                        alt=""
                        className="image-81"
                      />
                      <div>Add Text</div>
                    </div>
                  </div>
                  <div className="div-block-61">
                    <div className="div-block-62">
                      <img
                        src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512d2825bda9e3_smile.svg"
                        loading="lazy"
                        alt=""
                        className="image-81"
                      />
                      <div>Add Clipart</div>
                    </div>
                  </div>
                  <div className="div-block-61">
                    <div className="div-block-62">
                      <img
                        src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512da6babda9ec_image%20(5).svg"
                        loading="lazy"
                        alt=""
                        className="image-81"
                      />
                      <div>Premium Images</div>
                    </div>
                  </div>
                </div>
                <div className="div-block-50 padding-15">
                  <div className="sub-label-form">Print Area Background Color</div>
                  <div className="form-block w-form">
                    <form id="email-form" name="email-form" data-name="Email Form">
                      <div data-hover="true" data-delay="0" className="dropdown-3 w-dropdown">
                        <div
                          className="dropdown-toggle-4 w-dropdown-toggle"
                          id="w-dropdown-toggle-2"
                          aria-controls="w-dropdown-list-2"
                          aria-haspopup="menu"
                          aria-expanded="false"
                          role="button"
                          tabIndex="0"
                        >
                          <div className="w-icon-dropdown-toggle" aria-hidden="true"></div>
                          <div>None</div>
                        </div>
                        <nav
                          className="dropdown-list-4 w-dropdown-list"
                          id="w-dropdown-list-2"
                          aria-labelledby="w-dropdown-toggle-2"
                        >
                          <div>
                            <div className="w-layout-grid grid-8 drop">
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 ffcad0"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 f26622"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 e9bdd5"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 bc5500"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 fffff"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 d071a6"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 e5e43b"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 e8e8e8"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 cd368c"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 f8c193"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 bababa"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 c52e89"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 bf854b"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _696969"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 fff2b3"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _603913"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _7c1223"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 ffe94d"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 e7ffd7"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 c70e28"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 ffc929"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 a1c75b"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 ed1d24"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 ffb18a"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _74b87e"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 f37981"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 f5873f"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _74a952"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _006838"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 bfd8c7"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _038b97"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _00505a"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 ebffff"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 a2e5f4"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _44b3d4"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _3499b5"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _6ea5d9"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _0f3d84"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _0f1e3c"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _46276a"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 _8a6aa7"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 b095c2"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                              <label className="w-checkbox checkbox-field">
                                <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 d4b106"></div>
                                <input
                                  type="checkbox"
                                  id="checkbox-3"
                                  name="checkbox-3"
                                  data-name="Checkbox 3"
                                  style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    zIndex: -1,
                                  }}
                                />
                                <span className="checkbox-label-2 w-form-label">Checkbox 3</span>
                              </label>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="design-wrapper">
        <div className="div-block-105">
          <div className="design-frame">
            <div className="div-block-63">
              <div className="div-block-62-copy">
                <img
                  src="https://assets.website-files.com/611a5bdd4f512d2073bda98f/611a5bdd4f512dbb5fbda9e7_image%20(6).svg"
                  loading="lazy"
                  alt=""
                  className="image-81-copy"
                />
                <div className="text-block-14">Drop your design here</div>
              </div>
            </div>
          </div>
          <div className="div-block-68">
            <div className="w-layout-grid grid-10">
              <div className="div-block-61-copy">
                <div className="div-block-69"></div>
                <div>12x12</div>
              </div>
              <div className="div-block-61-copy">
                <div className="div-block-69 _12x16"></div>
                <div>12x16</div>
              </div>
              <div className="div-block-61-copy">
                <div className="div-block-69 _16x16"></div>
                <div>16x16</div>
              </div>
              <div className="div-block-61-copy">
                <div className="div-block-69 _16x20"></div>
                <div>16x20</div>
              </div>
              <div className="div-block-61-copy">
                <div className="div-block-69 _18x24"></div>
                <div>18x24</div>
              </div>
              <div
                id="w-node-bd7deded-1331-0d55-1087-7c4906c50c0b-c3bda992"
                className="div-block-61-copy"
              >
                <div className="div-block-69 _24x36"></div>
                <div>24x36</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Design
