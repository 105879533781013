import { useRef } from 'react'
import cn from 'classnames'
import { useOutsideClickHandler, useToggle } from 'hooks'

import { ReactComponent as RestartIcon } from 'images/icons/restart.svg'
import { ReactComponent as FilterIcon } from 'images/icons/filter.svg'
import { LAB_ORDER_NOTES } from 'lib/const'
import InputCheckbox from './InputCheckbox'

const NotesDropdown = ({
  onChange,
  onReset,
  isActiveUrgent,
  isActiveAdminNotes,
  isActiveOrderNote,
}) => {
  const ref = useRef()
  const [isOpen, toggleState, setToggleState] = useToggle(false)

  useOutsideClickHandler(ref, () => setToggleState(false))

  return (
    <div
      className="w-layout-grid grid-filters single"
      onClick={toggleState}
      onKeyPress={toggleState}
      ref={ref}
      role="button"
    >
      <div
        id="w-node-_8afe4b18-289a-31ff-3e3f-c4f5718c11c8-2283c889"
        className="w-dropdown"
        role="button"
      >
        <div
          className={cn('filter-select-field radius w-dropdown-toggle', {
            'w--open': isOpen,
          })}
        >
          <div className="icon small left-margin w-embed">
            <FilterIcon />
          </div>
        </div>
        <nav
          className={cn('dropdown-list filter w-dropdown-list', {
            'w--open': isOpen,
          })}
        >
          <div className="container-info-filter specific-dates">
            <div className="form-block-5 w-form">
              <form id="email-form-5" name="email-form-5" data-name="Email Form 5">
                <InputCheckbox
                  urgency
                  key={LAB_ORDER_NOTES.urgent}
                  label="Urgent"
                  value={LAB_ORDER_NOTES.urgent}
                  type={LAB_ORDER_NOTES.urgent}
                  checked={isActiveUrgent}
                  onClick={onChange}
                >
                  <div className="icon small x2 w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentcolor"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none" />
                      <g>
                        <path d="M221.048,175.93066C215.09778,165.7168,208.004,146.335,208.004,112v-7.0957c0-44.28516-35.61621-80.57813-79.39453-80.90235-.20508-.001-.40722-.002-.61133-.002A79.99928,79.99928,0,0,0,48.004,104v8c0,34.33105-7.09668,53.71387-13.0498,63.92676A15.999,15.999,0,0,0,48.76478,200h158.4707a15.99861,15.99861,0,0,0,13.8125-24.06934Z" />
                        <path d="M159.92884,216h-64a8,8,0,1,0,0,16h64a8,8,0,1,0,0-16Z" />
                      </g>
                    </svg>
                  </div>
                </InputCheckbox>
              </form>
            </div>
          </div>

          <div className="form-block-filter w-form">
            <form id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-3">
              <InputCheckbox
                key={LAB_ORDER_NOTES.adminNote}
                label="Admin Notes"
                value={LAB_ORDER_NOTES.adminNote}
                type={LAB_ORDER_NOTES.adminNote}
                checked={isActiveAdminNotes}
                onClick={onChange}
              />
              <InputCheckbox
                key={LAB_ORDER_NOTES.orderNote}
                label="Order Notes"
                value={LAB_ORDER_NOTES.orderNote}
                type={LAB_ORDER_NOTES.orderNote}
                checked={isActiveOrderNote}
                onClick={onChange}
              />
            </form>
          </div>
          <div className="container-info-filter bg" onClick={onReset} role="button">
            <div className="icon small right-margin x2 w-embed">
              <RestartIcon />
            </div>
            <div>Reset All</div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default NotesDropdown
