import { ReactComponent as ChevronLeftIcon } from 'images/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/icons/chevron-right.svg'
import { ReactComponent as ForwardIcon } from 'images/icons/forward.svg'
import { NavLink } from 'react-router-dom'

const LabOrderDetailsNav = ({ orders, currentOrder }) => {
  const currentOrderIndex = orders?.findIndex((order) => order.attributes.guid === currentOrder)

  return (
    <div className="container-topbar-orders">
      <div className="grid-topbar-orders">
        <div className="sub-grid-topbar-orders">
          <div className="form-block-6 w-form">
            <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-5">
              <div className="w-layout-grid grid-24-copy-copy">
                {currentOrderIndex > 0 && (
                  <NavLink
                    to={`/lab-orders/${orders[currentOrderIndex - 1].attributes.guid}`}
                    className="link-block-5-copy w-inline-block"
                  >
                    <div className="icon w-embed">
                      <ChevronLeftIcon />
                    </div>
                  </NavLink>
                )}
                {currentOrderIndex < orders.length - 1 ? (
                  <NavLink
                    to={`/lab-orders/${orders[currentOrderIndex + 1].attributes.guid}`}
                    className="link-block-5-copy w-inline-block"
                  >
                    <div className="icon w-embed">
                      <ChevronRightIcon />
                    </div>
                  </NavLink>
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-layout-grid grid-button-topbar-orders">
            <NavLink to="/" className="_2nd-button secondary w-inline-block">
              <div className="icon right-margin w-embed">
                <ForwardIcon />
              </div>
              <div>Continue Order</div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LabOrderDetailsNav
